import {
  NEWUSER_SUBMIT,
  NEWUSER_SUBMIT_FAIL,
  NEWUSER_SUBMIT_SUCCESS,
} from "../actions/types";

const INITIAL_STATE = {
  submitting: false,
  errors: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NEWUSER_SUBMIT:
      return {
        ...state,
        submitting: true,
        errors: {},
      };
    case NEWUSER_SUBMIT_FAIL:
      return {
        ...state,
        submitting: false,
        errors: action.payload,
      };
    case NEWUSER_SUBMIT_SUCCESS:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
