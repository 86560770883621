import React, { Fragment } from "react";
import AdminBizForm from "./AdminBizForm";
import { connect } from "react-redux";

const AdminNewBiz = (props) => {
  return (
    <Fragment>
      <AdminBizForm />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});

export default connect(mapStateToProps)(AdminNewBiz);
