import React from "react";
import { withRouter } from "react-router-dom";
import backSplash from "../../assets/img/searchSplash.png";

const Donate = (props) => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#333",
        height: "100vh",
      }}
    >
      <link rel="canonical" href="https://blacklivesbiz.com/donate" />
      <div style={{ ...styles.div }}>
        <h1 className={"font-primary"} style={{ ...styles.title }}>
          Donate
        </h1>
      </div>
      <p className={"font-primary"} style={{ ...styles.msgBody }}>
        "Thank you for your interest in donating to Black Lives Biz. Please
        email us at support@blacklivesbiz.com and we will send you our donation
        link."
      </p>
      <p className={"font-primary"} style={{ ...styles.msgAuthor }}>
        - The Black Lives Biz Team
      </p>
    </div>
  );
};

const styles = {
  title: {
    fontSize: "3rem",
    alignSelf: "center",
    margin: "0.85em 0",
    fontFamily: "'Exo 2', sans-serif",
    fontWeight: "900",
    color: "#f1f1f1",
    textShadow: "1px 1px black",
    textAlign: "center",
    maxWidth: "90vw",
  },
  msgBody: {
    fontSize: "1.5rem",
    alignSelf: "center",
    margin: "0.85em 0",
    fontFamily: "'Exo 2', sans-serif",
    fontWeight: "900",
    color: "#f1f1f1",
    textShadow: "1px 1px black",
    textAlign: "center",
    maxWidth: "90vw",
  },
  msgAuthor: {
    fontSize: ".9rem",
    alignSelf: "center",
    margin: "0.85em 0",
    fontFamily: "'Exo 2', sans-serif",
    fontWeight: "900",
    color: "#f1f1f1",
    textShadow: "1px 1px black",
    textAlign: "center",
    maxWidth: "90vw",
  },
  div: {
    backgroundImage: `url(${backSplash})`,
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "6rem 0 4rem 0",
    zIndex: 0,
    maxHeight: "20vh",
  },
};

export default withRouter(Donate);
