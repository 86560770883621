import axios from "axios";
import { CAT_FETCH, CAT_FETCH_FAIL, CAT_FETCH_SUCCESS } from "./types";
import config from "../config";

export const categoriesFetch = () => async (dispatch) => {
  dispatch({ type: CAT_FETCH });
  try {
    const url = `${config.apiRoot}/api/cat/icons`;
    const res = await axios.get(url);
    dispatch({
      type: CAT_FETCH_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: CAT_FETCH_FAIL,
      payload: error.response ? error.response.data : error.response,
    });
  }
};
