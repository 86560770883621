import {
  ADMIN_STARTED_ADD,
  ADMIN_FINISHED_ADD,
  ADMIN_CANCELED_ADD,
} from "../actions/types";

const INITIAL_STATE = {
  counters: {},
  addingNewBiz: false,
  newBizId: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADMIN_STARTED_ADD:
      return {
        ...state,
        addingNewBiz: true,
        newBizId: action.payload,
      };
    case ADMIN_FINISHED_ADD:
    case ADMIN_CANCELED_ADD:
      return {
        ...state,
        addingNewBiz: false,
        newBizId: null,
      };
    default:
      return state;
  }
};
