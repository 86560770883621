import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Button from "../common/Button";

const FormErrors = (props) => {
  const renderErrors = () => {
    return Object.values(props.newBiz.errors).map((error, index) => {
      return <li key={index}>{error}</li>;
    });
  };
  console.log("errors:", props.newBiz.errors);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          margin: "1rem 1rem 0rem 1rem",
          maxWidth: "800px",
          width: "100%",
          fontSize: "1rem",
        }}
      >
        <p>Your submission had errors:</p>
        <ul>{renderErrors()}</ul>
        <p>
          Please go back and correct your submission. If you feel these errors
          are incorrect please contact us at support@blacklivesbiz.com
        </p>
      </div>
      <Button
        style={styles.button}
        onClick={() => {
          props.history.goBack();
          window.scrollTo({ top: 0, behavior: "auto" });
        }}
      >
        Go Back
      </Button>
    </div>
  );
};

const styles = {
  button: {
    width: "auto",
    height: 42,
    paddingRight: 10,
    paddingLeft: 10,
    margin: "0px",
    borderRadius: "5px",
    fontSize: 18,
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
  },
};

const mapStateToProps = (state) => ({
  newBiz: state.newBiz,
});

export default connect(mapStateToProps)(withRouter(FormErrors));
