import { LOGIN_SET_PROFILE, ERROR_SET } from "./types";
import axios from "axios";
import config from "../config";

export const addFavorite = (token, id, history) => async (dispatch) => {
  const url = `${config.apiRoot}/api/profiles/addFavorite/${id}`;
  try {
    const res = await axios.put(url, {}, { headers: { "x-blb-token": token } });
    dispatch({ type: LOGIN_SET_PROFILE, payload: res.data });
  } catch (error) {
    console.error(error.message);
    const details = error.response
      ? error.response.data
        ? error.response.data.message
        : error.message
      : error.message;
    dispatch({ type: ERROR_SET, payload: { text: details, level: 3 } });
    history.push("/alert/info?message=custom");
  }
};

export const removeFavorite = (token, id, history) => async (dispatch) => {
  const url = `${config.apiRoot}/api/profiles/removeFavorite/${id}`;
  try {
    const res = await axios.delete(url, { headers: { "x-blb-token": token } });
    dispatch({ type: LOGIN_SET_PROFILE, payload: res.data });
  } catch (error) {
    console.error(error.message);
    const details = error.response
      ? error.response.data
        ? error.response.data.message
        : error.message
      : error.message;
    dispatch({ type: ERROR_SET, payload: { text: details, level: 3 } });
    history.push("/alert/info?message=custom");
  }
};

export const claimBusiness = (token, id, history) => async (dispatch) => {
  const url = `${config.apiRoot}/api/biz/claim/${id}`;
  try {
    const res = await axios.put(url, {}, { headers: { "x-blb-token": token } });
    dispatch({ type: LOGIN_SET_PROFILE, payload: res.data });
    dispatch({
      type: ERROR_SET,
      payload: {
        text:
          "Claim submitted we will review the claim and validate ownership within 2 business days.",
        level: 1,
      },
    });
    history.push("/alert/info?message=custom");
  } catch (error) {
    console.error(error.message);
    const details = error.response
      ? error.response.data
        ? error.response.data.message
        : error.message
      : error.message;
    dispatch({ type: ERROR_SET, payload: { text: details, level: 3 } });
    history.push("/alert/info?message=custom");
  }
};

export const setProfile = (profile) => (dispatch) => {
  dispatch({ type: LOGIN_SET_PROFILE, payload: profile });
};
