import React from "react";

import { NavLink } from "react-router-dom";

const MobileAdminNavBar = (props) => {
  const renderStandard = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "80%",
          borderBottom: "1px solid black",
        }}
      >
        <NavLink
          className={"nav-link font-primary"}
          style={{ ...styles.standard, ...styles.inactive }}
          activeStyle={styles.active}
          to="/fakeAdmin/review"
        >
          Review{" "}
          {props.analytics.pendingCount > 0 ? (
            <span
              style={{
                backgroundColor: "firebrick",
                borderRadius: "50%",
                padding: "0.25rem",
              }}
            >
              {props.analytics.pendingCount}
            </span>
          ) : null}
        </NavLink>
        <NavLink
          className={"nav-link font-primary"}
          style={{ ...styles.standard, ...styles.inactive }}
          activeStyle={styles.active}
          to="/fakeAdmin/claim"
        >
          Claims{" "}
          {props.analytics.claimsCount > 0 ? (
            <span
              style={{
                backgroundColor: "firebrick",
                borderRadius: "50%",
                padding: "0.25rem",
              }}
            >
              {props.analytics.claimsCount}
            </span>
          ) : null}
        </NavLink>
        <NavLink
          className={"nav-link font-primary"}
          style={{ ...styles.standard, ...styles.inactive }}
          activeStyle={styles.active}
          to="/fakeAdmin/reviewChanges"
        >
          Changes{" "}
          {props.analytics.changesCount > 0 ? (
            <span
              style={{
                backgroundColor: "firebrick",
                borderRadius: "50%",
                padding: "0.25rem",
              }}
            >
              {props.analytics.changesCount}
            </span>
          ) : null}
        </NavLink>
        <NavLink
          className={"nav-link font-primary"}
          style={{ ...styles.standard, ...styles.inactive }}
          active={styles.active}
          to="/fakeAdmin/newBiz"
        >
          New Biz
        </NavLink>
      </div>
    );
  };

  const renderExclusive = () => {
    switch (props.auth.adminMeta) {
      case "riartist":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "80%",
              borderBottom: "1px solid black",
            }}
          >
            <NavLink
              className={"nav-link font-primary"}
              style={{ ...styles.standard, ...styles.inactive }}
              active={styles.active}
              to="/fakeAdmin/newBiz"
            >
              New Artist
            </NavLink>
          </div>
        );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "0.5rem ",
      }}
    >
      {props.auth.adminType === "EXCLUSIVE"
        ? renderExclusive()
        : renderStandard()}
      <div style={{ flex: 1, width: "100vw" }}>{props.renderMain()}</div>
    </div>
  );
};

const styles = {
  standard: {
    backgroundColor: "#4286f7",
    textDecoration: "none",
    fontFamily: "'Exo 2', sans-serif",
    fontSize: "1rem",
    color: "#f1f1f1",
    fontWeight: 600,
    width: "100rem",
    textShadow: "1px 1px black",
    borderRadius: "30px 30px 0px 0px",
    transition: "all ease-in-out .5s",
    paddingRight: 0,
    flex: 1,
    padding: "0.5rem 0px 0.5rem 0px",
    textAlign: "center",
  },
  active: {
    backgroundColor: "#4286f7",
  },
  inactive: {
    backgroundColor: "gray",
  },
  desktopStandard: {
    textDecoration: "none",
    padding: "0.7rem",
    backgroundColor: "#4286f7",
    borderRadius: "10px",
    width: "60%",
    textAlign: "center",
    color: "white",
    fontSize: "1rem",
    fontWeight: "bold",
    margin: "0.5rem",
    minWidth: 150,
  },
};

export default MobileAdminNavBar;
