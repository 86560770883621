import {
  LOGIN_CHANGE_FORM,
  LOGIN_SUBMIT,
  LOGIN_SUBMIT_SUCCESS,
  LOGIN_SUBMIT_FAILED,
  LOGIN_LOGOUT,
  LOGIN_SET_PROFILE,
} from "../actions/types";

const INITIAL_STATE = {
  query: {
    email: "",
    password: "",
  },
  token: null,
  profile: null,
  guestId: null,
  isAdmin: false,
  adminType: null,
  adminMeta: null,
  adminLevel: 0,
  submitting: false,
  history: [],
  errors: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_CHANGE_FORM:
      return {
        ...state,
        query: {
          ...state.query,
          [action.payload.field]: action.payload.value,
        },
      };
    case LOGIN_SUBMIT:
      return {
        ...state,
        submitting: true,
        errors: {},
      };
    case LOGIN_SUBMIT_SUCCESS:
      return {
        ...INITIAL_STATE,
        token: action.payload.token,
        isAdmin: action.payload.isAdmin,
        adminType: action.payload.adminType,
        adminMeta: action.payload.adminMeta,
        adminLevel: action.payload.adminLevel,
        profile: action.payload.profile,
      };
    case LOGIN_SUBMIT_FAILED:
      return {
        ...state,
        submitting: false,
        errors: action.payload,
      };
    case LOGIN_SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case LOGIN_LOGOUT:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
