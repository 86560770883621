import React from "react";
import { VictoryLine, VictoryChart, VictoryTheme, VictoryAxis } from "victory";
import BorderCard from "../../common/BorderCard";

const NewBusiness = (props) => {
  return (
    <BorderCard
      style={{
        margin: "0.5rem auto",
        padding: "1rem",
        width: "350px",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p>New Biz Submissions</p>
      <VictoryChart theme={VictoryTheme.material} domainPadding={{ y: 15 }}>
        <VictoryAxis
          label="Date"
          style={{ axisLabel: { padding: 35 } }}
          // tickFormat={(t) => moment(t).format("MM/DD")}
        />
        <VictoryAxis
          dependentAxis
          style={{ axisLabel: { padding: 35 } }}
          label="Count"
        />
        <VictoryLine data={props.data} x="date" y="count" />
      </VictoryChart>
    </BorderCard>
  );
};

export default NewBusiness;
