import {
  NEW_BIZ_UPDATE,
  NEW_BIZ_SUBMIT,
  NEW_BIZ_SUBMIT_FAILED,
  NEW_BIZ_SUBMIT_SUCCESS,
  NEW_BIZ_PHOTO_UPLOADED,
  NEW_BIZ_VALIDATE_SUCCESS,
  NEW_BIZ_VALIDATE_UPDATE,
  ADMIN_FINISHED_ADD,
  NEW_BIZ_CODE_LINK,
} from "../actions/types";

const INITIAL_STATE = {
  query: {
    name: "",
    poster_email: "",
    state: "",
    city: "",
    zipcode: "",
    street_num: "",
    street_name: "",
    category: "",
    registered: "",
    business_type: "",
    about: "",
    short: "",
    years: "",
    business_web: "",
    location: "",
    owner_first_name: "",
    owner_last_name: "",
    owner_ethn: "",
    owner_email: "",
    owner_phone: "",
    owner_linkedin: "",
    twitter: "",
    instagram: "",
    facebook: "",
    open_hour: "",
    close_hour: "",
    personalUrl: "",
    requestedLogo: "",
  },
  id: null,
  code: "",
  photo: "",
  st: "",
  validated: false,
  submitting: false,
  errors: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NEW_BIZ_UPDATE:
      return {
        ...state,
        query: {
          ...state.query,
          [action.payload.field]: action.payload.value,
        },
      };
    case NEW_BIZ_SUBMIT:
      return {
        ...state,
        submitting: true,
        errors: {},
      };
    case NEW_BIZ_SUBMIT_FAILED:
      return {
        ...state,
        submitting: false,
        errors: action.payload,
      };
    case NEW_BIZ_SUBMIT_SUCCESS:
      const st = state.query.state;
      return { ...INITIAL_STATE, id: action.payload, st: st };
    case NEW_BIZ_CODE_LINK:
      return {
        ...INITIAL_STATE,
        id: action.payload.id,
        code: action.payload.code,
      };
    case ADMIN_FINISHED_ADD:
      return { ...state, id: action.payload };
    case NEW_BIZ_VALIDATE_UPDATE:
      // console.log(action.payload.value);
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case NEW_BIZ_VALIDATE_SUCCESS:
      return {
        ...state,
        validated: true,
        submitting: false,
      };
    case NEW_BIZ_PHOTO_UPLOADED:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
