import React, { useState, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import isEmpty from "../../utils/isEmpty";

const MenuItem = (props) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      style={
        hover
          ? { ...styles.menuItem, ...styles.menuItemColor }
          : styles.menuItem
      }
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={props.onClick}
    >
      <p className="font-primary">{props.text}</p>
    </div>
  );
};

const BizMenu = (props) => {
  const [isOpen, toggleOpen] = useState(false);

  let iconStyle = { ...styles.container };
  iconStyle = !isOpen ? { ...iconStyle, ...styles.active } : iconStyle;

  const handleFavorite = () => {
    toggleOpen(false);
    if (!props.loggedIn) {
      return props.history.push("/login?message=1");
    }
    if (props.profile && props.profile.favorites.includes(props.bizId)) {
      props.removeFavorite(props.loggedIn, props.bizId, props.history);
      return;
    }
    props.addFavorite(props.loggedIn, props.bizId, props.history);
  };

  const handleAddInfo = () => {
    toggleOpen(false);
    if (!props.loggedIn) {
      return props.history.push("/login?message=1");
    }
    if (isEmpty(props.profile)) {
      props.logout(null);
      return props.history.push("/login?message=2");
    }
    if (props.profile && !props.profile.validated) {
      return props.history.push("/alert/info?message=0");
    }
    return props.history.push({
      pathname: "/changeBiz",
      state: { bizId: props.bizId },
    });
  };

  const handleClaim = () => {
    toggleOpen(false);
    if (!props.loggedIn) {
      return props.history.push("/login?message=1");
    }
    if (isEmpty(props.profile)) {
      props.logout(null);
      return props.history.push("/login?message=2");
    }
    if (props.profile && !props.profile.validated) {
      return props.history.push("/alert/info?message=0");
    }
    return props.claimBusiness(props.loggedIn, props.bizId, props.history);
  };

  const handleFlag = () => {
    if (!props.loggedIn) {
      return props.history.push("/login?message=1");
    }
    if (isEmpty(props.profile)) {
      props.logout(null);
      return props.history.push("/login?message=2");
    }
    if (props.profile && !props.profile.validated) {
      return props.history.push("/alert/info?message=0");
    }
    toggleOpen(false);
  };

  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "8px",
          right: "8px",
          border: "1px solid #fff",
          borderRadius: "50%",
          overflow: "hidden",
        }}
        onClick={() => toggleOpen(!isOpen)}
      >
        <div
          style={{
            width: 20,
            height: 20,
            border: "2px solid #4286f7",
            borderRadius: "50%",
            overflow: "hidden",
          }}
        >
          <span style={{ ...iconStyle, ...styles.container2 }}>
            <FontAwesomeIcon
              icon={["fas", "times"]}
              aria-hidden="true"
              // color="#f1f1f1"
            />
          </span>
          <span style={iconStyle}>
            <FontAwesomeIcon
              icon={["fas", "bars"]}
              aria-hidden="true"
              // color="#4286f7"
            />
          </span>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          top: "35px",
          right: "12px",
          // width: 60,
          // border: "2px solid #4286f7",
          overflow: "hidden",
        }}
      >
        <span
          style={
            !isOpen
              ? {
                  ...styles.menuContainer,
                  ...styles.menuActive,
                }
              : styles.menuContainer
          }
        >
          <div style={{ height: "0.2em" }} aria-hidden="true"></div>
          <MenuItem
            text={
              props.loggedIn &&
              props.profile &&
              props.profile.favorites.includes(props.bizId)
                ? "Unfavorite"
                : "Favorite"
            }
            onClick={handleFavorite}
          />
          <div style={{ height: "0.2em" }} aria-hidden="true"></div>
          {props.isOwned ? null : (
            <Fragment>
              <MenuItem text="Add Biz Info" onClick={handleAddInfo} />
              {props.loggedIn &&
              props.profile &&
              props.profile.pendingClaims.includes(props.bizId) ? null : (
                <Fragment>
                  <div style={{ height: "0.2em" }} aria-hidden="true" />
                  <MenuItem text="Claim Biz" onClick={handleClaim} />
                </Fragment>
              )}
            </Fragment>
          )}
          <div style={{ height: "0.2em" }} aria-hidden="true"></div>
          {/* <MenuItem text="Flag Biz" onClick={handleFlag} />
          <div style={{ height: "0.2em" }} aria-hidden="true"></div> */}
        </span>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "block",
    width: "100%",
    height: "100%",
    textAlign: "center",
    lineHeight: "22px",
    fontSize: "12px",
    color: "#4286f7",
    backgroundColor: "#fff",
    transition: "0.5s",
  },
  container2: {
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
  },
  active: {
    transform: "translateY(-100%)",
  },
  menuContainer: {
    display: "block",
    width: "100%",
    textAlign: "right",
    fontSize: "12px",
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
    borderRadius: "10px",
    // opacity: 0,
    // visibility: "hidden",
    transition: "0.5s",
  },
  menuActive: {
    transform: "translateY(-102%)",
  },
  menuItem: {
    padding: "0 0.8em",
    cursor: "pointer",
  },
  menuItemColor: {
    backgroundColor: "#250ae3",
  },
};

export default withRouter(BizMenu);
