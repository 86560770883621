import {
  SUPPORTER_FETCH,
  SUPPORTER_FETCH_FAIL,
  SUPPORTER_FETCH_SUCCESS,
  SUPPORTER_CHANGE_FORM,
  SUPPORTER_SUBMIT,
  SUPPORTER_SUBMIT_FAIL,
  SUPPORTER_SUBMIT_SUCCESS,
} from "./types";

import axios from "axios";
import config from "../config";

export const supporterUpdate = (field, value) => async (dispatch) => {
  dispatch({
    type: SUPPORTER_CHANGE_FORM,
    payload: { field, value },
  });
};

export const supporterSubmit = (query, history) => async (dispatch) => {
  dispatch({
    type: SUPPORTER_SUBMIT,
    payload: { loading: true },
  });
  try {
    const data = {
      firstname: query.firstname,
      lastname: query.lastname,
      zipcode: query.zipcode,
      email: query.email,
      state: query.state,
      city: query.city,
    };
    const url = `${config.apiRoot}/api/supporters`;
    const res = await axios.post(url, data);
    console.log(res.data);
    dispatch({
      type: SUPPORTER_SUBMIT_SUCCESS,
    });
    history.push("/");
  } catch (error) {
    console.log(error.response.data);
    let errors = {};
    if (error.response && error.response.data.errors) {
      console.log(error.response.data);
      error.response.data.errors.forEach((err) => {
        switch (err.param) {
          default:
            errors[err.param] = err.msg;
        }
      });
    } else {
      errors = error.response ? error.response.data : error;
    }

    dispatch({
      type: SUPPORTER_SUBMIT_FAIL,
      payload: errors,
    });
    history.push("/supporters/errors");
  }
};

export const supportersFetch = () => async (dispatch) => {
  dispatch({
    type: SUPPORTER_FETCH,
    payload: { loading: true },
  });
  try {
    const url = `${config.apiRoot}/api/supporters/all`;
    const res = await axios.get(url);
    console.log(res);
    dispatch({
      type: SUPPORTER_FETCH_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: SUPPORTER_FETCH_FAIL,
      payload: error.response ? error.response.data : error.response,
    });
  }
};
