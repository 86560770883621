import React, { Fragment } from "react";

const Foreward = (props) => {
  return (
    <Fragment>
      <img
        src="https://storage.googleapis.com/photos-blb/riblackartists.png"
        width="100%"
        height="auto"
      />
      <img
        src="https://storage.googleapis.com/photos-blb/riartist_blackartistgreeting.png"
        width="100%"
        height="auto"
      />
    </Fragment>
  );
};

export default Foreward;
