import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  bizsFetch,
  bizLoadMore,
  searchLoadMore,
  addFavorite,
  removeFavorite,
  claimBusiness,
  logout,
} from "../../actions";
import BizCard from "./BizCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfiniteScroll from "react-infinite-scroller";

const Bizs = (props) => {
  const { location, bizs, bizsFetch } = props;
  useEffect(() => {
    if (location === "home" && bizs.loading) {
      bizsFetch();
    }
  }, [bizs.loading, bizsFetch, location]);

  const renderNewBizs = () => {
    return props.location === "home" && props.bizs.list.new.length > 0
      ? props.bizs.list.new.map((biz) => (
          <BizCard
            biz={biz}
            width={325}
            ratioWidth={16}
            ratioHeight={9}
            key={biz._id}
            style={{ backgroundColor: "#EEE" }}
            cardFlag={`New Biz!${
              props.auth.profile &&
              props.auth.profile.favorites.includes(biz._id)
                ? " & Favorite"
                : ""
            }`}
            loggedIn={props.auth.token}
            profile={props.auth.profile}
            addFavorite={props.addFavorite}
            removeFavorite={props.removeFavorite}
            claimBusiness={props.claimBusiness}
            logout={props.logout}
          />
        ))
      : null;
  };

  const renderBizs = () => {
    return props.location === "home"
      ? props.bizs.list.existing.map((biz) => (
          <BizCard
            biz={biz}
            width={325}
            ratioWidth={16}
            ratioHeight={9}
            key={biz._id}
            style={{ backgroundColor: "#EEE" }}
            loggedIn={props.auth.token}
            profile={props.auth.profile}
            cardFlag={
              props.auth.profile &&
              props.auth.profile.favorites.includes(biz._id)
                ? "Favorite!"
                : null
            }
            addFavorite={props.addFavorite}
            removeFavorite={props.removeFavorite}
            claimBusiness={props.claimBusiness}
            logout={props.logout}
          />
        ))
      : props.bizss.map((biz) => (
          <BizCard
            biz={biz}
            width={325}
            ratioWidth={16}
            ratioHeight={9}
            key={biz._id}
            style={{ backgroundColor: "#EEE" }}
            loggedIn={props.auth.token}
            profile={props.auth.profile}
            cardFlag={
              props.auth.profile &&
              props.auth.profile.favorites.includes(biz._id)
                ? "Favorite!"
                : null
            }
            addFavorite={props.addFavorite}
            removeFavorite={props.removeFavorite}
            claimBusiness={props.claimBusiness}
            logout={props.logout}
          />
        ));
  };

  const fetchMore = (page) => {
    console.log("test");
    if (props.location === "home") {
      props.bizLoadMore(page);
    } else {
      props.loadMore(page);
    }
  };
  console.log(props.hasMore);

  return (
    <div
      style={{
        display: "flex",
        justifyItems: "center",
        flexDirection: "column",
      }}
    >
      <InfiniteScroll
        page={1}
        loadMore={fetchMore}
        loader={
          <FontAwesomeIcon
            icon={["fas", "spinner"]}
            spin
            style={{ fontSize: 20, margin: "0 auto" }}
            key={"loader"}
          />
        }
        hasMore={props.location === "home" ? props.bizs.hasMore : props.hasMore}
        useWindow={true}
        style={{
          display: "flex",
          flex: "1",
          justifyContent: "space-evenly",
          paddingRight: "10%",
          paddingLeft: "10%",
          alignItems: "center",
          flexDirection: "row",
          alignItems: "flex-start",
          flexWrap: "wrap",
          backgroundColor: "#FFF",
        }}
      >
        {renderNewBizs()}
        {renderBizs()}
      </InfiniteScroll>
    </div>
  );
};

const mapStateToProps = (state) => ({
  search: state.search,
  bizs: state.bizs,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  bizsFetch,
  bizLoadMore,
  searchLoadMore,
  addFavorite,
  removeFavorite,
  claimBusiness,
  logout,
})(Bizs);
