//   Import from other action creator
//   import {adminApprove, } from "./adminMiniActions"
//   dispatch(adminApprove(id))
import axios from "axios";
import { apiRoot } from "../config";
import {
  ADMIN_BIZS_FETCH,
  ADMIN_BIZS_FETCH_SUCCESS,
  ADMIN_BIZS_FETCH_FAILED,
  ADMIN_BIZ_APPROVED,
  ADMIN_BIZ_DENIED,
  ADMIN_BIZ_FLAGGED,
  ADMIN_BIZ_STATUS_CHANGE,
  ADMIN_BIZ_STATUS_FAILED,
  ADMIN_FINISHED_ADD,
  ADMIN_CANCELED_ADD,
  NEW_BIZ_SUBMIT,
  NEW_BIZ_SUBMIT_FAILED,
  NEW_BIZ_SUBMIT_SUCCESS,
  NEW_BIZ_PHOTO_UPLOADED,
} from "../actions/types";
import isEmpty from "../utils/isEmpty";
import bizSanitizer from "../utils/bizSanitizer";

export const adminBizsFetch = (token) => async (dispatch) => {
  dispatch({ type: ADMIN_BIZS_FETCH });
  try {
    const res = await axios.get(
      `${apiRoot}/api/admin/biz/bizApprovals/93a24eea1691a5c37a030ec75ea823bc`,
      {
        headers: { "x-blb-token": token },
      }
    );
    return dispatch({
      type: ADMIN_BIZS_FETCH_SUCCESS,
      payload: { response: res.data },
    });
  } catch (error) {
    console.error(error);
    return dispatch({
      type: ADMIN_BIZS_FETCH_FAILED,
      payload: error.response ? error.response.data : error.response,
    });
  }
};

export const approveBiz = (token, id) => async (dispatch) => {
  dispatch({ type: ADMIN_BIZ_STATUS_CHANGE });
  const url = `${apiRoot}/api/admin/biz/approve/93a24eea1691a5c37a030ec75ea823bc/${id}`;
  try {
    await axios.put(url, {}, { headers: { "x-blb-token": token } });
    dispatch({
      type: ADMIN_BIZ_APPROVED,
      payload: id,
    });
  } catch (error) {
    console.error(error);
    return dispatch({
      type: ADMIN_BIZ_STATUS_FAILED,
      payload: error.response ? error.response.data : error.response,
    });
  }
};

export const denyBiz = (token, id) => async (dispatch) => {
  dispatch({ type: ADMIN_BIZ_STATUS_CHANGE });
  const url = `${apiRoot}/api/admin/biz/deny/93a24eea1691a5c37a030ec75ea823bc/${id}`;
  try {
    await axios.put(url, {}, { headers: { "x-blb-token": token } });
    dispatch({
      type: ADMIN_BIZ_DENIED,
      payload: id,
    });
  } catch (error) {
    console.error(error);
    return dispatch({
      type: ADMIN_BIZ_STATUS_FAILED,
      payload: error.response ? error.response.data : error.response,
    });
  }
};

export const flagBiz = (token, id, reason) => async (dispatch) => {
  dispatch({ type: ADMIN_BIZ_STATUS_CHANGE });
  const url = `${apiRoot}/api/admin/biz/flag/93a24eea1691a5c37a030ec75ea823bc/${id}`;
  const data = { reason };
  try {
    await axios.put(url, data, {
      headers: { "x-blb-token": token },
    });
    dispatch({
      type: ADMIN_BIZ_FLAGGED,
      payload: id,
    });
  } catch (error) {
    console.error(error);
    return dispatch({
      type: ADMIN_BIZ_STATUS_FAILED,
      payload: error.response ? error.response.data : error.response,
    });
  }
};

export const adminUploadBiz = (token, query, file, history) => async (
  dispatch
) => {
  dispatch({ type: NEW_BIZ_SUBMIT });
  const url = `${apiRoot}/api/admin/biz`;

  let { data, errors } = bizSanitizer(query);

  if (!isEmpty(errors)) {
    dispatch({
      type: NEW_BIZ_SUBMIT_FAILED,
      payload: errors,
    });
  }

  try {
    const res = await axios.post(url, data, {
      headers: { "x-blb-token": token },
    });
    dispatch({
      type: ADMIN_FINISHED_ADD,
      payload: res.data.id,
    });
    dispatch(AdminImgUpload(res.data.id, file, history));
  } catch (error) {
    console.error(error);
    errors = {};
    if (error.response && error.response.data.errors) {
      error.response.data.errors.forEach((err) => {
        switch (err.param) {
          case "street_num":
          case "street_name":
            errors.street = err.msg;
            break;
          case "state":
          case "city":
          case "zipcode":
            errors.address = err.msg;
            break;
          case "open_hour":
          case "close_hour":
            errors.hour = err.msg;
            break;
          case "owner_phone":
          case "years":
            errors.phoneYears = err.msg;
            break;
          default:
            errors[err.param] = err.msg;
        }
      });
    } else {
      errors = error.response ? error.response.data : error;
    }

    dispatch({
      type: NEW_BIZ_SUBMIT_FAILED,
      payload: errors,
    });
  }
};

export const AdminCancelBiz = (id, token) => async (dispatch) => {
  const url = `${apiRoot}/api/admin/biz/${id}`;

  try {
    await axios.delete(url, { headers: { "x-blb-token": token } });
    dispatch({
      type: ADMIN_CANCELED_ADD,
    });
  } catch (error) {
    console.error("MAJOR ERROR:", error);
  }
};

export const AdminImgUpload = (id, file, history) => async (dispatch) => {
  dispatch({ type: NEW_BIZ_SUBMIT });
  const url = `${apiRoot}/api/biz/imgUpload/${id}`;
  const data = new FormData();
  data.append("image", file);

  try {
    const res = await axios.put(url, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    dispatch({
      type: NEW_BIZ_SUBMIT_SUCCESS,
      payload: res.data.id,
    });
    dispatch({ type: NEW_BIZ_PHOTO_UPLOADED });
    history.push("/fakeAdmin/review");
  } catch (error) {
    console.error(error);
    dispatch({
      type: NEW_BIZ_SUBMIT_FAILED,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const AdminImgUploadAuth = (token, id, file, history) => async (
  dispatch
) => {
  dispatch({ type: NEW_BIZ_SUBMIT });
  const url = `${apiRoot}/api/admin/biz/imgreupload/93a24eea1691a5c37a030ec75ea823bc/${id}`;
  const data = new FormData();
  data.append("image", file);

  try {
    const res = await axios.put(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-blb-token": token,
      },
      params: {
        overwrite: true,
      },
    });
    dispatch({ type: NEW_BIZ_SUBMIT_SUCCESS, payload: res.data.biz.id });
    history.goBack();
  } catch (error) {
    console.error(error);
    dispatch({
      type: NEW_BIZ_SUBMIT_FAILED,
      payload: error.response ? error.response.data : error,
    });
  }
};
