import React from "react";
// import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./assets/css/index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { hydrate, render } from "react-dom";

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );
const rootElement = document.getElementById("root");
const RenderApp = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
};

const crawlers = ["Googlebot", "APIs-Google", "AdsBot-Google"];

if (crawlers.includes(navigator.userAgent)) {
  hydrate(<RenderApp />, rootElement);
} else {
  render(<RenderApp />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
