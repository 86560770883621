import {
  NEWUSER_SUBMIT,
  NEWUSER_SUBMIT_FAIL,
  NEWUSER_SUBMIT_SUCCESS,
  LOGIN_SUBMIT_SUCCESS,
  LOGIN_SET_PROFILE,
  AUTH_LOGIN_SUCCESS,
} from "./types";

import axios from "axios";
import config from "../config";

export const submitAccount = (query, file, history, token) => async (
  dispatch
) => {
  dispatch({
    type: NEWUSER_SUBMIT,
  });
  if (!token) {
    // register user account first.
    const url = `${config.apiRoot}/api/users`;
    const data = {
      email: query.email,
      password: query.password,
      recaptcha: query.recaptcha,
    };
    if (query.code) data.code = query.code;
    try {
      // console.log("hi");
      const response = await axios.post(url, data);
      // console.log("got");
      dispatch({ type: LOGIN_SUBMIT_SUCCESS, payload: response.data });
      return dispatch(submitProfile(query, file, history, response.data.token));
    } catch (error) {
      console.log(error.message);
      let errors = {};
      if (error.response && error.response.data.errors) {
        error.response.data.errors.forEach((err) => {
          if (err.param === "firstName" || err.param === "lastName")
            return (errors.name = err.msg);
          errors[err.param] = err.msg;
        });
      } else {
        errors.form = error.message;
      }
      dispatch({
        type: NEWUSER_SUBMIT_FAIL,
        payload: errors,
      });
      // return history.push("/login/errors");
    }
  } else {
    return dispatch(submitProfile(query, file, history, token));
  }
};

const submitProfile = (query, file, history, token) => async (dispatch) => {
  console.log(query.code);
  const data = {
    firstName: query.firstName,
    lastName: query.lastName,
    ethnicity: query.ethnicity,
    bio: query.bio,
  };
  if (query.code) {
    data.code = query.code;
  }
  const url = `${config.apiRoot}/api/profiles`;
  try {
    const response = await axios.post(url, data, {
      headers: { "x-blb-token": token },
    });
    dispatch({ type: LOGIN_SET_PROFILE, payload: response.data.profile });
    dispatch({ type: AUTH_LOGIN_SUCCESS, payload: response.data.token });
    if (file) {
      console.log("hi");
      return dispatch(submitPhoto(file, history, response.data.token));
    }
    dispatch({ type: NEWUSER_SUBMIT_SUCCESS });
    return history.push(
      `/login/confirmed?outcome=${response.data.outcome}&bizFound=${response.data.bizFound}`
    );
  } catch (error) {
    console.log(error.message);
    let errors = {};
    if (error.response && error.response.data.errors) {
      error.response.data.errors.forEach((err) => {
        if (err.param === "firstName" || err.param === "lastName")
          return (errors.name = err.msg);
        errors[err.param] = err.msg;
      });
    } else {
      errors.form = error.message;
    }
    dispatch({
      type: NEWUSER_SUBMIT_FAIL,
      payload: errors,
    });
    // return history.push("/login/errors");
  }
};

const submitPhoto = (file, history, token) => async (dispatch) => {
  const data = new FormData();
  data.append("image", file);
  const url = `${config.apiRoot}/api/profiles/avatarUpload`;

  try {
    const response = await axios.put(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-blb-token": token,
      },
    });
    dispatch({ type: LOGIN_SET_PROFILE, payload: response.data });
    dispatch({ type: NEWUSER_SUBMIT_SUCCESS });
    history.push("/login/confirmed");
  } catch (error) {
    console.error(error);
    //failed images should simply be accepted for the moment. Can update it later.
    dispatch({
      type: NEWUSER_SUBMIT_SUCCESS,
    });
    history.push("/login/confirmed");
  }
};
