import React from "react";
import {
  VictoryLine,
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  VictoryGroup,
  VictoryLegend,
} from "victory";
import moment from "moment";
import BorderCard from "../../common/BorderCard";

const SelfActions = (props) => {
  return (
    <BorderCard
      style={{
        margin: "0.5rem auto",
        padding: "1rem",
        width: "350px",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p>Approvals vs Denials</p>
      <VictoryChart theme={VictoryTheme.material} domainPadding={{ y: 15 }}>
        <VictoryLegend
          centerTitle
          orientation="horizontal"
          gutter={20}
          style={{ border: { stroke: "black" } }}
          data={[
            { name: "Approved", symbol: { fill: "forestgreen" } },
            { name: "Denied", symbol: { fill: "firebrick" } },
          ]}
        />
        <VictoryAxis
          label="Date"
          style={{ axisLabel: { padding: 35 } }}
          tickFormat={(t) => moment(t).format("MM/DD")}
        />
        <VictoryAxis
          dependentAxis
          style={{ axisLabel: { padding: 35 } }}
          label="count"
        />
        <VictoryGroup colorScale={["forestgreen", "firebrick"]}>
          <VictoryLine
            data={props.data}
            x="date"
            y="approved"
            // style={{ data: { stroke: "forestgreen" } }}
          />
          <VictoryLine
            data={props.data}
            x="date"
            y="denied"
            // style={{ data: { stroke: "firebrick" } }}
          />
        </VictoryGroup>
      </VictoryChart>
    </BorderCard>
  );
};

export default SelfActions;
