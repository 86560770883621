import {
  ADMIN_BIZS_FETCH,
  ADMIN_BIZS_FETCH_SUCCESS,
  ADMIN_BIZS_FETCH_FAILED,
  ADMIN_BIZ_APPROVED,
  ADMIN_BIZ_DENIED,
  ADMIN_BIZ_FLAGGED,
  ADMIN_BIZ_STATUS_CHANGE,
  ADMIN_BIZ_STATUS_FAILED,
} from "../actions/types";

const INITIAL_STATE = {
  list: [],
  errors: {},
  loading: false,
  loadingId: null,
  dropdown: false,
  updating: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADMIN_BIZS_FETCH:
      return {
        ...state,
        loading: true,
        errors: {},
      };
    case ADMIN_BIZS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.response,
      };
    case ADMIN_BIZS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    case ADMIN_BIZ_STATUS_CHANGE:
      return {
        ...state,
        updating: true,
        errors: {},
      };
    case ADMIN_BIZ_APPROVED:
    case ADMIN_BIZ_DENIED:
    case ADMIN_BIZ_FLAGGED:
      return {
        ...state,
        updating: false,
        list: state.list.filter((biz) => biz._id !== action.payload),
      };
    case ADMIN_BIZ_STATUS_FAILED:
      return {
        ...state,
        updating: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};
