import React from "react";
import backSplash from "../../assets/img/searchSplash.png";
import { Route, Switch, withRouter } from "react-router-dom";
import SupportersForm from "./SupportersForm";
import SupportersErrors from "./SupportersErrors";

const SupportersPage = () => {
  return (
    <div style={{ ...styles.mainDiv }}>
      <div style={{ ...styles.div }}>
        <h1 className={"font-primary"} style={{ ...styles.title }}>
          Black Lives Biz Supporters
        </h1>
      </div>
      <p className={"font-primary"} style={{ ...styles.msgBody }}>
        "Become a supporter of the businesses on Black Lives Biz."
      </p>
      <p className={"font-primary"} style={{ ...styles.msgAuthor }}>
        Enter your information below to receive news updates, business specials
        and deals!
      </p>
      <div style={{ ...styles.formWrapper }}>
        <Switch>
          <Route path={"/supporters"} exact>
            <SupportersForm />
          </Route>
          <Route path={"/supporters/errors"}>
            <SupportersErrors />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

const styles = {
  mainDiv: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#333",
    minHeight: "100vh",
  },
  title: {
    fontSize: "3rem",
    alignSelf: "center",
    margin: "0.85em 0",
    fontFamily: "'Exo 2', sans-serif",
    fontWeight: "900",
    color: "#f1f1f1",
    textShadow: "1px 1px black",
    textAlign: "center",
    maxWidth: "90vw",
  },
  msgBody: {
    fontSize: "2rem",
    alignSelf: "center",
    margin: "0.85em 0",
    fontFamily: "'Exo 2', sans-serif",
    fontWeight: "900",
    color: "#f1f1f1",
    textShadow: "1px 1px black",
    textAlign: "center",
    maxWidth: "90vw",
  },
  msgAuthor: {
    fontSize: "1rem",
    alignSelf: "center",
    margin: "0.85em 0",
    fontFamily: "'Exo 2', sans-serif",
    fontWeight: "900",
    color: "#f1f1f1",
    textShadow: "1px 1px black",
    textAlign: "center",
    maxWidth: "90vw",
  },
  formWrapper: {
    margin: "0 auto",
    marginTop: "3%",
  },
  div: {
    backgroundImage: `url(${backSplash})`,
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "6rem 0 4rem 0",
    zIndex: 0,
    maxHeight: "20vh",
  },
};

export default withRouter(SupportersPage);
// export default connect(mapStateToProps)(SupportersPage);
