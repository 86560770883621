import { CAT_FETCH, CAT_FETCH_FAIL, CAT_FETCH_SUCCESS } from "../actions/types";

const INITIAL_STATE = {
  list: [],
  loading: true,
  errors: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CAT_FETCH:
      return {
        ...state,
        loading: true,
        errors: {},
      };
    case CAT_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        errors: { category: "Categories unavailable. Refresh" },
      };
    case CAT_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    default:
      return state;
  }
};
