import React, { Component } from "react";
import {
  VictoryLine,
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  VictoryLegend,
  VictoryGroup,
} from "victory";
import BorderCard from "../../common/BorderCard";

const mockTeam = [
  {
    name: "Arnell",
    approvals: [
      {
        date: "5/1",
        count: 45,
      },
      {
        date: "5/8",
        count: 40,
      },
      {
        date: "5/15",
        count: 42,
      },
      {
        date: "5/22",
        count: 51,
      },
      {
        date: "5/29",
        count: 43,
      },
      {
        date: "6/5",
        count: 49,
      },
      {
        date: "6/12",
        count: 38,
      },
      {
        date: "6/19",
        count: 52,
      },
      {
        date: "6/26",
        count: 49,
      },
      {
        date: "7/3",
        count: 48,
      },
      {
        date: "7/10",
        count: 55,
      },
    ],
    denials: [
      {
        date: "5/1",
        count: 21,
      },
      {
        date: "5/8",
        count: 32,
      },
      {
        date: "5/15",
        count: 24,
      },
      {
        date: "5/22",
        count: 26,
      },
      {
        date: "5/29",
        count: 13,
      },
      {
        date: "6/5",
        count: 27,
      },
      {
        date: "6/12",
        count: 22,
      },
      {
        date: "6/19",
        count: 28,
      },
      {
        date: "6/26",
        count: 20,
      },
      {
        date: "7/3",
        count: 19,
      },
      {
        date: "7/10",
        count: 24,
      },
    ],
  },
  {
    name: "Cliff",
    approvals: [
      {
        date: "5/1",
        count: 38,
      },
      {
        date: "5/8",
        count: 41,
      },
      {
        date: "5/15",
        count: 35,
      },
      {
        date: "5/22",
        count: 42,
      },
      {
        date: "5/29",
        count: 32,
      },
      {
        date: "6/5",
        count: 48,
      },
      {
        date: "6/12",
        count: 30,
      },
      {
        date: "6/19",
        count: 46,
      },
      {
        date: "6/26",
        count: 42,
      },
      {
        date: "7/3",
        count: 47,
      },
      {
        date: "7/10",
        count: 38,
      },
    ],
    denials: [
      {
        date: "5/1",
        count: 17,
      },
      {
        date: "5/8",
        count: 27,
      },
      {
        date: "5/15",
        count: 25,
      },
      {
        date: "5/22",
        count: 20,
      },
      {
        date: "5/29",
        count: 17,
      },
      {
        date: "6/5",
        count: 21,
      },
      {
        date: "6/12",
        count: 26,
      },
      {
        date: "6/19",
        count: 21,
      },
      {
        date: "6/26",
        count: 23,
      },
      {
        date: "7/3",
        count: 19,
      },
      {
        date: "7/10",
        count: 20,
      },
    ],
  },
  {
    name: "Gabriel",
    approvals: [
      {
        date: "5/1",
        count: 49,
      },
      {
        date: "5/8",
        count: 50,
      },
      {
        date: "5/15",
        count: 42,
      },
      {
        date: "5/22",
        count: 38,
      },
      {
        date: "5/29",
        count: 48,
      },
      {
        date: "6/5",
        count: 52,
      },
      {
        date: "6/12",
        count: 41,
      },
      {
        date: "6/19",
        count: 48,
      },
      {
        date: "6/26",
        count: 45,
      },
      {
        date: "7/3",
        count: 47,
      },
      {
        date: "7/10",
        count: 50,
      },
    ],
    denials: [
      {
        date: "5/1",
        count: 25,
      },
      {
        date: "5/8",
        count: 21,
      },
      {
        date: "5/15",
        count: 29,
      },
      {
        date: "5/22",
        count: 30,
      },
      {
        date: "5/29",
        count: 21,
      },
      {
        date: "6/5",
        count: 26,
      },
      {
        date: "6/12",
        count: 19,
      },
      {
        date: "6/19",
        count: 25,
      },
      {
        date: "6/26",
        count: 20,
      },
      {
        date: "7/3",
        count: 22,
      },
      {
        month: "7/10",
        count: 20,
      },
    ],
  },
];

class TeamStats extends Component {
  state = {
    display: "approvals",
  };

  changeDisplay = (value) => {
    this.setState({
      display: value,
    });
  };

  renderLines = () => {
    return mockTeam.map((member) => {
      return (
        <VictoryLine
          data={member[this.state.display]}
          x="date"
          y="count"
          key={member.name}
          interpolation="linear"
        />
      );
    });
  };

  render() {
    return (
      <BorderCard
        style={{
          margin: "0.5rem auto",
          padding: "1rem",
          width: "350",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "0.5rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>Team Stats</p>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <button
              onClick={() => this.changeDisplay("approvals")}
              style={{
                flex: 1,
                backgroundColor:
                  this.state.display === "approvals" ? "#f1f1f1" : "#a1a1a1",
              }}
            >
              <p>Approvals</p>
            </button>
            <button
              onClick={() => this.changeDisplay("denials")}
              style={{
                flex: 1,
                backgroundColor:
                  this.state.display === "denials" ? "#f1f1f1" : "#a1a1a1",
              }}
            >
              <p>Denials</p>
            </button>
          </div>
        </div>
        <VictoryChart theme={VictoryTheme.material} domainPadding={{ y: 15 }}>
          <VictoryLegend
            centerTitle
            orientation="horizontal"
            gutter={20}
            style={{ border: { stroke: "black" } }}
            data={mockTeam.map(({ name }) => ({ name }))}
            colorScale={
              this.state.display === "approvals" ? "qualitative" : "warm"
            }
          />
          <VictoryAxis
            label="Week of"
            style={{ axisLabel: { padding: 35 } }}
            fixLabelOverlap={true}
          />
          <VictoryAxis
            dependentAxis
            label="count"
            style={{ axisLabel: { padding: 35 } }}
            // domain={[0]}
          />
          <VictoryGroup
            colorScale={
              this.state.display === "approvals" ? "qualitative" : "warm"
            }
          >
            {this.renderLines()}
          </VictoryGroup>
        </VictoryChart>
      </BorderCard>
    );
  }
}

export default TeamStats;
