import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Button from "../common/Button";
import queryString from "query-string";

const UserConfirmed = (props) => {
  const [outcome, setOutcome] = useState("");
  const [bizFound, setBizFound] = useState(false);

  useEffect(() => {
    const queries = queryString.parse(window.location.search);
    if (queries.outcome) {
      setOutcome(queries.outcome);
    }
    if (queries.bizFound) {
      setBizFound(queries.bizFound === "true" ? true : false);
    }
  }, []);

  const renderOutcome = () => {
    switch (outcome) {
      case "error":
        return (
          <div
            className={"font-primary"}
            style={{
              margin: "1rem 1rem 1rem 1rem",
              maxWidth: "800px",
              width: "100%",
              fontSize: "1rem",
              backgroundColor: "rgba(225, 100, 100, 0.2)",
              padding: "1rem",
              color: "#eee",
            }}
          >
            <p>
              There was an error, please retry or contact support if you did not
              get an error message and this is repeating.
            </p>
          </div>
        );
      case "existing":
        return (
          <div
            className={"font-primary"}
            style={{
              margin: "1rem 1rem 1rem 1rem",
              maxWidth: "800px",
              width: "100%",
              fontSize: "1rem",
              backgroundColor: "rgba(100, 225, 100, 0.2)",
              padding: "1rem",
              color: "#eee",
            }}
          >
            <p>
              Welcome to BlackLivesBiz! You now have full access to all
              BlackLivesBiz's features.
            </p>
          </div>
        );
      case "new":
        return (
          <div
            className={"font-primary"}
            style={{
              margin: "1rem 1rem 1rem 1rem",
              maxWidth: "800px",
              width: "100%",
              fontSize: "1rem",
              backgroundColor: "rgba(100, 225, 100, 0.2)",
              padding: "1rem",
              color: "#eee",
            }}
          >
            Welcome to the Black Lives Biz community. To gain full access to all
            user features you simply need to check your email for a validation
            link. Please add support@blacklivesbiz.com to your contacts or
            business whitelist to ensure you get our emails. If you do not get
            it within 30 minutes feel free to reach out to us.
          </div>
        );
      case "non-validated":
        return (
          <div
            className={"font-primary"}
            style={{
              margin: "1rem 1rem 1rem 1rem",
              maxWidth: "800px",
              width: "100%",
              fontSize: "1rem",
              backgroundColor: "rgba(100, 225, 100, 0.2)",
              padding: "1rem",
              color: "#eee",
            }}
          >
            <p>
              Welcome to the Black Lives Biz community. To gain full access to
              all user features you simply need to check your email for a
              validation link. Please add support@blacklivesbiz.com to your
              contacts or business whitelist to ensure you get our emails. If
              you do not get it within 30 minutes feel free to reach out to us.
            </p>
          </div>
        );
      default:
        return (
          <div
            className={"font-primary"}
            style={{
              margin: "1rem 1rem 1rem 1rem",
              maxWidth: "800px",
              width: "100%",
              fontSize: "1rem",
              backgroundColor: "rgba(100, 225, 100, 0.2)",
              padding: "1rem",
              color: "#eee",
            }}
          >
            <p>
              You arrived to this page incorrectly please return home if you see
              this page repeatedly please contact support.
            </p>
          </div>
        );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {renderOutcome()}
      {bizFound ? (
        <div
          className={"font-primary"}
          style={{
            margin: "1rem 1rem 1rem 1rem",
            maxWidth: "800px",
            width: "100%",
            fontSize: "1rem",
            backgroundColor: "rgba(100, 225, 100, 0.2)",
            padding: "1rem",
            color: "#eee",
          }}
        >
          <p>
            We also found an existing Biz record connected to your email
            account. You have been made the owner of that record.
          </p>
        </div>
      ) : null}
      <Button style={styles.button} onClick={() => props.history.push("/")}>
        Return Home
      </Button>
    </div>
  );
};

const styles = {
  button: {
    width: "auto",
    height: 42,
    paddingRight: 10,
    paddingLeft: 10,
    margin: "0px",
    borderRadius: "5px",
    fontSize: 18,
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
  },
};

export default withRouter(UserConfirmed);
