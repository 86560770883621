import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import backSplash from "../../assets/img/searchSplash.png";
import Button from "../common/Button";
import queryString from "query-string";

const messages = {
  0: {
    text:
      "You do not have a validated account. Please check your email for a validation message from support@blacklivesbiz.com. However, if you are unable to find an email it may have been blocked by your email provider. Please contact support@blacklivesbiz.com so we can help you validate your account manually.",
    level: 2,
  },
  1: {
    text: "Coming Soon!",
    level: 1,
  },
  claimError: {
    text:
      "There was a server error with claiming the business. Please try again or contact support so we can assist you.",
    level: 3,
  },
  serverError: {
    text:
      "An unknown server error occured. Please try again or contact support",
    level: 3,
  },
};

const colors = {
  0: "transparent",
  1: "rgba(100, 204, 75, 0.4)",
  2: "rgba(255, 204, 75, 0.4)",
  3: "rgba(255, 100, 75, 0.4)",
};

const headers = {
  0: "Loading",
  1: "Success",
  2: "Warning",
  3: "Alert",
};

const Message = (props) => {
  const [message, setMessage] = useState({
    text: "",
    level: 0,
  });

  useEffect(() => {
    const queries = queryString.parse(window.location.search);
    if (queries.message) {
      if (queries.message === "custom") {
        return setMessage(props.error);
      }
      if (!messages[queries.message]) {
        return setMessage({ text: "Invalid message code", level: 3 });
      }
      setMessage(messages[queries.message]);
    }
  }, []);

  console.log(message);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#333",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${backSplash})`,
          backgroundSize: "cover",
          position: "relative",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "6rem 0 4rem 0",
          zIndex: 0,
          maxHeight: "20vh",
        }}
      >
        <h2
          style={{
            fontSize: "3rem",
            alignSelf: "center",
            margin: "0.85em 0",
            fontFamily: "'Exo 2', sans-serif",
            fontWeight: "900",
            color: "#f1f1f1",
            textShadow: "1px 1px black",
            textAlign: "center",
            maxWidth: "90vw",
          }}
        >
          {headers[message.level]}
        </h2>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="font-primary"
          style={{
            margin: "1rem 1rem 0rem 1rem",
            maxWidth: "800px",
            width: "100%",
            fontSize: "1rem",
            color: "#f1f1f1",
            backgroundColor: colors[message.level],
            // opacity: 0.3,
            padding: "0.5rem",
            textAlign: "center",
          }}
        >
          <p style={{ opacity: 1 }}>{message.text}</p>
        </div>
        <Button style={styles.button} onClick={() => props.history.push("/")}>
          Return Home
        </Button>
      </div>
    </div>
  );
};

const styles = {
  button: {
    width: "auto",
    height: 42,
    paddingRight: 10,
    paddingLeft: 10,
    margin: "1em",
    borderRadius: "5px",
    fontSize: 18,
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
  },
};

const mapStateToProps = (state) => ({
  error: state.error,
});

export default connect(mapStateToProps)(withRouter(Message));
