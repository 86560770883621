import isEmpty from "./isEmpty";

export default (query) => {
  const data = {};
  const errors = {};

  Object.keys(query).forEach((key) => {
    if (isEmpty(query[key])) return;
    if (typeof query[key] === "number") {
      data[key] = query[key];
      return;
    }
    let temp = query[key].trim();
    switch (key) {
      case "street_num":
      case "years":
        data[key] = parseInt(temp);
        break;
      case "twitter":
        temp = temp.toLowerCase();
        if (temp.indexOf("@") === 0) {
          temp = `https://www.twitter.com/${temp.substring(1)}`;
        } else if (temp.indexOf("twitter.com") !== -1) {
          temp =
            temp.indexOf("http://") > -1 || temp.indexOf("https://") > -1
              ? temp
              : `http://${temp}`;
        } else {
          temp = `https://twitter.com/${temp}`;
        }
        data.twitter = temp;
        break;
      case "instagram":
        temp = temp.toLowerCase();
        if (temp.indexOf("instagram.com") !== -1) {
          temp =
            temp.indexOf("http://") === -1 && temp.indexOf("https://") === -1
              ? `http://${temp}`
              : temp;
        } else {
          temp = `https://instagram.com/${temp}`;
        }
        break;
      case "facebook":
      case "owner_linkedin":
      case "business_web":
        temp = temp.toLowerCase();
        data[key] =
          temp.indexOf("http://") === -1 && temp.indexOf("https://")
            ? `http://${temp}`
            : temp;
        break;
      case "personalUrl":
        temp = temp
          .toLowerCase()
          .replace(/http|https|www|.com|^\w|_|\.|\\|\/|blacklivesbiz/g, "");
        if (!temp.match(/^[0-9a-z]+$/)) {
          errors.personalUrl = "Alphanumeric characters only A-Z and 0-9";
        }
        data[key] = temp;
        break;
      case "owner_phone":
        data[key] = temp
          .replace(/-/g, "")
          .replace(/\(/g, "")
          .replace(/\)/g, "")
          .replace(/ /g, "")
          .replace(/\./g, "");
        break;
      case "registered":
      case "requestedLogo":
        data[key] = temp === "T";
        break;
      default:
        if (!isEmpty(temp)) data[key] = temp;
    }
  });

  return { data, errors };
};
