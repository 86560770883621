import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";

const CategoriesContext = React.createContext([]);

const CategoriesProvider = (props) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const _fetchCategories = async () => {
      const url = `${config.apiRoot}/api/cat/icons`;
      try {
        const response = await axios.get(url);
        setCategories(response.data);
      } catch (error) {
        console.error(
          error.response ? error.response.data.message : error.message
        );
        alert("Unable to load category list, refresh or contact support.");
      }
    };
    _fetchCategories();
  }, [setCategories]);

  return (
    <CategoriesContext.Provider value={categories}>
      {props.children}
    </CategoriesContext.Provider>
  );
};

export { CategoriesContext, CategoriesProvider };
