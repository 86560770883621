import React, { Component } from "react";
import BorderCard from "../common/BorderCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BizMenu from "./BizMenu";

const timeConverter = (time) => {
  const hour = time % 12 === 0 ? 12 : (time % 12) + 1;
  const text = time >= 12 ? "PM" : "AM";
  return `${hour} ${text}`;
};

class BizCard extends Component {
  state = {
    hover: false,
  };

  onMouseEnter = () => {
    this.setState({
      hover: true,
    });
  };

  onMouseLeave = () => {
    this.setState({
      hover: false,
    });
  };

  render() {
    const { width, biz } = this.props;
    let height = (width / this.props.ratioWidth) * this.props.ratioHeight;
    var d = new Date();
    var hour = d.getHours();
    return (
      <BorderCard
        hoverable
        hover={this.state.hover}
        style={{
          width: width,
          padding: 0,
          marginTop: 10,
          borderColor: "#d4d4d4",
          height: "auto",
          ...this.props.style,
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            position: "relative",
          }}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <BizMenu
            loggedIn={this.props.loggedIn}
            profile={this.props.profile}
            addFavorite={this.props.addFavorite}
            removeFavorite={this.props.removeFavorite}
            bizId={biz._id}
            isOwned={biz.isOwned}
            claimBusiness={this.props.claimBusiness}
            logout={this.props.logout}
          />
          {this.props.cardFlag ? ( // update conditional when db contains data for biz.home_featured
            <div className={"badge-featured"}>{this.props.cardFlag}</div>
          ) : null}
          {navigator.userAgent === "ReactSnap" ? (
            <img
              width={width}
              height={height}
              src={`https://storage.googleapis.com/photos-blb/as_BLB${biz.state}.png`}
              style={{ objectFit: "cover" }}
              alt={biz.name}
            />
          ) : (
            <img
              width={width}
              height={height}
              src={
                biz.photos.length === 0
                  ? `https://storage.googleapis.com/photos-blb/as_BLB${biz.state}.png`
                  : biz.stdPhotos
                  ? biz.stdPhotos[0]
                  : biz.photos[0]
              }
              style={{ objectFit: "cover" }}
              alt={biz.name}
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "8px 15px 8px 15px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <div style={{ width: "70%" }}>
                  <p
                    className={"font-primary"}
                    style={{ flex: 1, textAlign: "left", fontWeight: 700 }}
                  >
                    {biz.name}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {biz.registered ? (
                  <FontAwesomeIcon
                    icon={["fas", "check-circle"]}
                    style={{
                      fontSize: 14,
                    }}
                    color="rgb(34, 84, 61)"
                  />
                ) : null}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 1 }}>
                <p
                  style={{
                    cursor: "default",
                    marginBottom: "0.2em",
                    marginTop: "0.25em",
                  }}
                >
                  {biz.business_type}
                </p>
              </div>
            </div>
            <div
              style={{
                height: 1,
                backgroundColor: "#d9d9d9",
                width: "100%",
              }}
            />
            {biz.about && this.state.hover ? (
              <div style={{ height: 125 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    {biz.short ? (
                      <p>{biz.short}</p>
                    ) : (
                      <p>
                        {/* {biz.about.indexOf("\n")} testing needed on this */}
                        {biz.about.substring(0, 211)}
                        {biz.about.length > 212 ? "..." : null}
                      </p>
                    )}
                  </div>

                  <div style={styles.socialIconDiv}>
                    <p
                      className={"font-size-12 badge badge-default"}
                      style={{ flex: 1, margin: 0 }}
                    >
                      {biz.business_phone
                        ? `(${biz.business_phone.substring(
                            0,
                            3
                          )}) ${biz.business_phone.substring(
                            3,
                            6
                          )}-${biz.business_phone.substring(6)}`
                        : null}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {biz.business_web ? (
                        <a
                          href={biz.business_web}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={["fas", "globe"]}
                            color="#d9d9d9"
                            style={styles.socialIcon}
                          />
                        </a>
                      ) : null}
                      {biz.owner_linkedin ? (
                        <a
                          href={biz.owner_linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={["fab", "linkedin"]}
                            color="#d9d9d9"
                            style={styles.socialIcon}
                          />
                        </a>
                      ) : null}
                      {biz.twitter ? (
                        <a
                          href={biz.twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={["fab", "twitter"]}
                            color="#d9d9d9"
                            style={styles.socialIcon}
                          />
                        </a>
                      ) : null}
                      {biz.facebook ? (
                        <a
                          href={biz.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={["fab", "facebook"]}
                            color="#d9d9d9"
                            style={styles.socialIcon}
                          />
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: 125,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p style={{ flex: 1 }}>{biz.category.join(", ")}</p>
                <p className={"font-size-12 badge badge-default"}>
                  {biz.business_phone
                    ? `(${biz.business_phone.substring(
                        0,
                        3
                      )}) ${biz.business_phone.substring(
                        3,
                        6
                      )}-${biz.business_phone.substring(6)}`
                    : null}
                </p>
              </div>
            )}
            <div
              style={{
                height: 1,
                backgroundColor: "#d9d9d9",
                width: "100%",
              }}
            />
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 8,
                }}
              >
                {this.state.hover ? (
                  <p
                    className={
                      biz.open_hour <= hour && hour <= biz.close_hour
                        ? "badge badge-success"
                        : "badge badge-danger"
                    }
                  >{`${timeConverter(biz.open_hour)} - ${timeConverter(
                    biz.close_hour
                  )}`}</p>
                ) : biz.open_hour <= hour && hour <= biz.close_hour ? (
                  <p className={"badge badge-success"}>Open</p>
                ) : (
                  <p className={"badge badge-danger"}>Closed</p>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 3,
              }}
            >
              <div style={{ flex: 1, flexDirection: "column" }}>
                <p
                  className={"font-size-12 badge badge-default"}
                  style={{ margin: "3px 2px" }}
                >
                  {biz.street_name}
                  <br />
                  {biz.city}, {biz.state} {biz.zipcode}
                </p>
              </div>
            </div>
          </div>
          {biz.hasSpecial ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: 10,
                // backgroundColor: "rgba(66, 134, 247, 0.5)",
                backgroundColor: "#faebbe",
                // color: "white",
              }}
            >
              <p
                className={"font-size-11"}
                style={{ fontWeight: "bold", margin: "0, 4px" }}
              >
                {biz.specialText}
              </p>
            </div>
          ) : null}
        </div>
        <div className={"animated-card-border"} />
      </BorderCard>
    );
  }
}

const styles = {
  socialIconDiv: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "90%",
    transform: "translate(0%,400%)",
  },
  socialIcon: {
    fontSize: 20,
    padding: 2,
    color: "#4286f7",
  },
  bizTypeDiv: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    textAlign: "right",
    width: "80%",
    transform: "translate(0%,-100%)",
  },
  phoneNumber: {
    display: "flex",
    flex: 1,
  },
};

export default BizCard;
