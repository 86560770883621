import React, { Component } from "react";
import Logo from "../common/Logo";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../actions";

class Navbar extends Component {
  state = {
    display: false,
  };
  onLogout = () => {
    this.props.logout(this.props.history);
  };

  events = {
    onMouseEnterNavBtn: (e) => {
      const style = e.target.style;
      style.opacity = "70%";
    },
    onMouseLeaveNavBtn: (e) => {
      const style = e.target.style;
      style.opacity = "100%";
    },
    handleClick: (e) => {
      // console.log(e.target);
      this.setState({
        display: !this.state.display,
      });
    },
    onMouseEnterNavLink: (e) => {
      const style = e.target.style;
      style.transform = "scale(.95, .95)";
      style.color = "64a7ff";
    },
    onMouseLeaveNavLink: (e) => {
      const style = e.target.style;
      style.transform = "scale(1, 1)";
      style.color = "ffffff";
    },
    onMouseEnterNavBar: (e) => {
      // const style = e.target.style;
    },
    onMouseLeaveNavBar: (e) => {
      // const style = e.target.style;
    },
  };

  render() {
    const {
      navbarOn,
      loginLink,
      adminLink,
      logoImageLink,
      donateLink,
      supportLink,
    } = this.props;
    // DEBUG:
    //   console.log("Navbar On:", navbarOn);
    return navbarOn ? (
      <nav
        style={{
          ...this.props.style,
          background: "transparent",
          transition: "background ease-in-out 1s",
        }}
      >
        <div style={{ ...styles.navBarWrapper }}>
          <div style={{ flex: 1 }}>
            <NavLink style={{ ...styles.logoNavLink }} to="/">
              <Logo link={logoImageLink} height={"5em"} />
            </NavLink>
          </div>
          <div //NavButtonDiv -- navBarMain
            style={{ ...styles.navBarMain }}
            onMouseEnter={this.events.onMouseEnterNavBar}
            onMouseLeave={this.events.onMouseLeaveNavBar}
          >
            <NavLink //Home
              className={"nav-link font-primary"}
              to="/"
              style={{ ...styles.navLink }}
              onMouseEnter={this.events.onMouseEnterNavLink}
              onMouseLeave={this.events.onMouseLeaveNavLink}
            >
              Home
            </NavLink>
            {this.props.auth.isAdmin ? null : (
              <NavLink //Add Biz
                className={"nav-link font-primary"}
                to="/newBiz"
                style={{ ...styles.navLink }}
                onMouseEnter={this.events.onMouseEnterNavLink}
                onMouseLeave={this.events.onMouseLeaveNavLink}
              >
                Add Biz
              </NavLink>
            )}
            <NavLink //Team
              className={"nav-link font-primary"}
              to={loginLink}
              style={{ ...styles.navLink }}
              onMouseEnter={this.events.onMouseEnterNavLink}
              onMouseLeave={this.events.onMouseLeaveNavLink}
            >
              Team
            </NavLink>
            {this.props.auth.token ? (
              <NavLink //Login
                className={"nav-link font-primary"}
                to={"/"}
                onClick={this.onLogout}
                style={{ ...styles.navLink }}
                onMouseEnter={this.events.onMouseEnterNavLink}
                onMouseLeave={this.events.onMouseLeaveNavLink}
              >
                Logout
              </NavLink>
            ) : (
              <NavLink //Login
                className={"nav-link font-primary"}
                to={loginLink}
                style={{ ...styles.navLink }}
                onMouseEnter={this.events.onMouseEnterNavLink}
                onMouseLeave={this.events.onMouseLeaveNavLink}
              >
                Login
              </NavLink>
            )}
            <NavLink //News
              className={"nav-link font-primary"}
              to={loginLink}
              style={styles.navLink}
              onMouseEnter={this.events.onMouseEnterNavLink}
              onMouseLeave={this.events.onMouseLeaveNavLink}
            >
              News
            </NavLink>
            <NavLink //Artist
              className={"nav-link font-primary"}
              to="/blackArtists/ri"
              style={{ ...styles.navLink }}
              onMouseEnter={this.events.onMouseEnterNavLink}
              onMouseLeave={this.events.onMouseLeaveNavLink}
            >
              Artists
            </NavLink>
            <NavLink //Donate
              className={"nav-link font-primary"}
              to={donateLink}
              style={styles.navLink}
              onMouseEnter={this.events.onMouseEnterNavLink}
              onMouseLeave={this.events.onMouseLeaveNavLink}
            >
              Donate
            </NavLink>
            <NavLink //Supporters
              className={"nav-link font-primary"}
              to={supportLink}
              style={styles.navLink}
              onMouseEnter={this.events.onMouseEnterNavLink}
              onMouseLeave={this.events.onMouseLeaveNavLink}
            >
              Supporters
            </NavLink>
            {this.props.auth.isAdmin ? (
              <NavLink //Admin
                className={"nav-link font-primary"}
                to={adminLink}
                style={styles.navLink}
                onMouseEnter={this.events.onMouseEnterNavLink}
                onMouseLeave={this.events.onMouseLeaveNavLink}
              >
                Admin
              </NavLink>
            ) : null}
          </div>
        </div>
      </nav>
    ) : null;
  }
}

const styles = {
  navLink: {
    background: "transparent",
    // margin: ".2em",
    textDecoration: "none",
    fontFamily: "'Exo 2', sans-serif",
    fontSize: "1.35em",
    color: "#f1f1f1",
    fontWeight: 600,
    textShadow: "1px 1px black",
    textAlign: "center",
    borderRadius: "20px",
    whiteSpace: "nowrap",
    transition: "all ease-in-out .5s",
  },
  navBtnLine: {
    backgroundColor: "white",
    height: ".2rem",
    width: "100%",
    borderRadius: 20,
    transition: "all ease-in-out 1s",
  },
  logoNavLink: {
    zIndex: "1",
    float: "left",
    flex: "9",
    margin: "0 3vw 0 10vw",
    boxShadow: "inset rgba(40, 40, 40, 0.4) 0px 0px 20px 12px",
    pointerEvents: "auto",
  },
  navBarMain: {
    flex: 1,
    // padding: "1em 2em 1em 1em",
    marginTop: "0em",
    display: "flex",
    flexDirection: "row",
    transition: "ease-in-out .7s",
    borderRadius: "70px 0px 0px 70px",
    background: "transparent",
    userSelect: "none",
    // overflow: "auto",
    // scrollbarWidth: "thin"
  },
  navBarWrapper: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#202020a2",
    padding: "1.2em 0em",
  },
};

const mapStateToProps = ({ navbar, auth }) => ({ navbar, auth });

export default connect(mapStateToProps, { logout })(Navbar);
