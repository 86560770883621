import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";

const StatesContext = React.createContext([{}, () => {}]);
const CitiesContext = React.createContext([]);

const StatesProvider = (props) => {
  const [states, setStates] = useState({ list: [], selected: "" });
  const [cities, setCities] = useState({ counted: [], names: [], state: "" });

  useEffect(() => {
    const _fetchStates = async () => {
      const url = `${config.apiRoot}/api/state`;
      try {
        console.log("fetchStates");
        const response = await axios.get(url);
        setStates({ list: response.data, selected: "" });
      } catch (error) {
        console.error(
          error.response ? error.response.data.message : error.message
        );
        alert("Unable to load states, unable to search by city and state.");
      }
    };
    _fetchStates();
  }, [setStates]);

  useEffect(() => {
    const _fetchCities = async () => {
      const url = `${config.apiRoot}/api/state/${states.selected}`;
      try {
        const response = await axios.get(url);
        setCities({
          state: states.selected,
          ...response.data,
        });
      } catch (error) {
        console.error(
          error.response ? error.response.data.message : error.message
        );
        alert("Unable to load cities, unable to search by city.");
      }
    };

    if (states.selected !== cities.state) {
      // new state selected update cities.
      _fetchCities();
    }
  }, [states]);

  return (
    <StatesContext.Provider value={[states, setStates]}>
      <CitiesContext.Provider value={cities}>
        {props.children}
      </CitiesContext.Provider>
    </StatesContext.Provider>
  );
};

export { StatesContext, CitiesContext, StatesProvider };
