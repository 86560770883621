import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { getFormData, fetchCities } from "../../../actions";
import { withRouter, Route, Switch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewBAForm from "./NewBAForm";
import config from "../../../config";

const roseWeaver = "https://storage.googleapis.com/photos-blb/RoseWeaver.jpg";

const NewBlackArtist = (props) => {
  const [query, setQuery] = useState({
    state: "",
    city: "",
    category: [""],
    about: "",
    subCategories: "",
    business_web: "",
    owner_first_name: "",
    owner_last_name: "",
    exclusiveMeta: {
      proName: "",
      subCategories: [],
      customLinks: [],
    },
    proName: "",
    owner_email: "",
    owner_phone: "",
    owner_linkedin: "",
    twitter: "",
    instagram: "",
    facebook: "",
    file: "",
    owner_ethn: "Black (African Decent)",
    business_type: "Self-Employed",
    location: "Physical",
    open_hour: 8,
    close_hour: 17,
  });
  const [submitting, setSubmitting] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [errors, setErrors] = useState({});
  const [categories, setCategories] = useState([]);

  const updateForm = (field, value) => {
    setQuery({ ...query, [field]: value });
  };

  const updateExclusive = (field, value) => {
    setQuery({
      ...query,
      exclusiveMeta: { ...query.exclusiveMeta, [field]: value },
    });
  };

  useEffect(() => {
    const _fetchFormData = async () => {
      try {
        setErrors({});
        await props.getFormData();
        updateForm("state", "RI");
        await props.fetchCities("RI");
        const res = await axios.post(`${config.apiRoot}/api/cat/child`, {
          parent: "Arts & Entertainment",
        });
        setCategories(res.data);
        setFetching(false);
      } catch (error) {
        const errors = error.response
          ? error.response.data
            ? error.response.data
            : { form: error.message }
          : { form: error.message };
        console.log(errors);
        setErrors(errors);
        setFetching(false);
      }
    };
    setFetching(true);
    _fetchFormData();
  }, []);

  const _submitForm = async () => {
    try {
      const data = {
        ...query,
        exclusiveKey: "riartist",
        name: `${query.owner_first_name} ${query.owner_last_name}`,
      };

      data.exclusiveMeta.subCategories = data.exclusiveMeta.subCategories.map(
        (item) => item.trim()
      );

      setErrors({});
      setSubmitting(true);
      console.log(props.auth.token);
      const res = await axios.post(
        `${config.apiRoot}/api/admin/biz/exclusiveBiz`,
        data,
        {
          headers: { "x-blb-token": props.auth.token },
        }
      );
      if (query.file !== "") {
        _uploadImg(res.data);
        return;
      }
      setSubmitting(false);
      props.history.push("/fakeAdmin/blackArtists/newBizSubmitted");
      return;
    } catch (error) {
      const errors = error.response
        ? error.response.data
          ? error.response.data
          : { form: error.message }
        : { form: error.message };
      console.log(errors);
      setErrors(errors);
      setSubmitting(false);
    }
  };

  const _uploadImg = async (newBiz) => {
    try {
      const data = new FormData();
      data.append("image", query.file);
      await axios.put(
        `${config.apiRoot}/api/admin/biz/artistImgUpload/${newBiz._id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-blb-token": props.auth.token,
          },
        }
      );
      setSubmitting(false);
      props.history.push("/fakeAdmin/blackArtists/newBizSubmitted");
      return;
    } catch (error) {
      const errors = error.response
        ? error.response.data
          ? error.response.data
          : { form: error.message }
        : { form: error.message };
      console.log(errors);
      setErrors(errors);
      setSubmitting(false);
    }
  };

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <div
        style={{
          backgroundImage: `url(${roseWeaver})`,
          backgroundSize: "cover",
          position: "relative",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "6rem 0 4rem 0",
          zIndex: 0,
        }}
      >
        <h1
          style={{
            fontSize: "3rem",
            alignSelf: "center",
            margin: "0.85em 0",
            fontFamily: "'Exo 2', sans-serif",
            fontWeight: "900",
            color: "#f1f1f1",
            textShadow: "1px 1px black",
            textAlign: "center",
          }}
        >
          Add New Black Artist
        </h1>
      </div>
      <Switch>
        <Route path={"/fakeAdmin/blackArtists/newBiz"} exact>
          {fetching ? (
            <FontAwesomeIcon
              icon={["fas", "spinner"]}
              spin
              style={{ fontSize: 20, margin: "0 auto" }}
            />
          ) : (
            <NewBAForm
              errors={errors}
              query={query}
              onSubmit={_submitForm}
              updateForm={updateForm}
              updateExclusive={updateExclusive}
              submitting={submitting}
              fetchCities={props.fetchCities}
              st={props.st}
              cat={categories}
              adminForm
            />
          )}
        </Route>
        <Route path="/fakeAdmin/blackArtists/newBizSubmitted" exact>
          <h1>Success</h1>
        </Route>
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  st: state.st,
  cat: state.categories,
});

export default connect(mapStateToProps, { getFormData, fetchCities })(
  withRouter(NewBlackArtist)
);
