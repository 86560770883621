import {
  CHANGE_FORM,
  SUBMIT_FORM,
  BIZ_SUBMIT_FAILED,
  BIZ_SUBMIT_SUCCESS,
} from "../actions/types";

const INITIAL_STATE = {
  query: {
    id: 0,
    name: "",
    state: "",
    city: "",
    zipcode: "",
    street_num: "",
    street_name: "",
    category: "",
    registered: "",
    business_type: "",
    business_web: "",
    location: "",
    owner_first_name: "",
    owner_last_name: "",
    owner_email: "",
    owner_phone: "",
    owner_linkedin: "",
    open_hour: "",
    close_hour: "",
    recaptcha: null,
  },
  results: [],
  loading: false,
  errors: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_FORM:
      return {
        ...state,
        query: {
          [action.payload.field]: action.payload.value,
        },
      };
    case SUBMIT_FORM:
      return {
        ...state,
        loading: true,
        errors: {},
      };
    case BIZ_SUBMIT_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case BIZ_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        results: action.payload,
      };
    default:
      return state;
  }
};
