import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Submitter = (props) => {
  const [isHover, setHover] = useState(false);

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  return (
    <FontAwesomeIcon
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      color="#4286f7"
      onClick={props.submitHandler}
      icon={("fas", "check-circle")}
      style={{
        ...styles.actionButton,
        opacity: isHover ? 1 : 0.5,
      }}
    />
  );
};

const styles = {
  actionButton: {
    height: 50,
    display: "flex",
    position: "fixed",
    width: 50,
    bottom: 30,
    right: 30,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
    cursor: "pointer",
    animation: "fadeIn 0.3s",
    transition: "opacity 0.4s",
    // fontSize: "2rem",
  },
};

export default Submitter;
