import React from "react";
import BorderCard from "../../common/BorderCard";

const AdminBizChange = (props) => {
  const handleSelect = () => {
    props.onClick(biz);
  };

  const { biz } = props;
  return (
    <BorderCard
      className="font-primary"
      style={{
        margin: "0.5rem auto",
        padding: "1rem",
        width: "300px",
      }}
      onClick={handleSelect}
    >
      <h1 style={{ fontSize: "1.3rem", fontWeight: "bold" }}>{biz.name}</h1>
      <p>
        {biz.street_name}, {biz.state} {biz.zipcode}
      </p>
      <p>Biz has {biz.changeRequests.length} pendng change requests</p>
    </BorderCard>
  );
};

export default AdminBizChange;
