import React, {useState, useContext, createContext, useEffect} from 'react'
import { isMobile } from "react-device-detect";
import {useWindowWidth} from '@react-hook/window-size/throttled'
const IsThinContext = createContext()

export const useIsThinContext = () => {
  return useContext(IsThinContext)
}

const deviceIsMobile = isMobile;

const thinLimit = 850

export const IsThinContextProvider = ({children}) => {

  const width = useWindowWidth({fps: 3})

  const [isThin, setDim] = useState(deviceIsMobile || window.innerWidth < thinLimit)

  useEffect( () => {
    if (!deviceIsMobile) {
      setDim(width < thinLimit);
    }
  },[width])
  

  return (
    <IsThinContext.Provider value={isThin}>
      {children}
    </IsThinContext.Provider>
  )
}
