import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import { submitAccount } from "../../actions";
import { withRouter, Redirect, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import ReCAPTCHA from "react-google-recaptcha";
import queryString from "query-string";
import Button from "../common/Button";
import Form from "../common/Form";
import Input from "../common/Input";
import blankProfile from "../../assets/img/blankProfile.png";
import isEmpty from "../../utils/isEmpty";

const ethnicities = [
  "Black (African Decent)",
  "White (European Decent)",
  "Native American or Alaskan Native",
  "Hispanic or Latino",
  "Middle Eastern",
  "Asian",
  "Mixed",
  "Other",
];

const RegisterForm = (props) => {
  const [query, setQuery] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    file: null,
    ethnicity: "",
    bio: "",
    recaptcha: "",
    code: null
  });
  const [file, setFile] = useState(null);
  const [fillProfile, setFillProfile] = useState(false);
  const fileInput = createRef;
  const recaptchaRef = createRef;
  const { errors } = props.newUser;
  const { auth } = props;

  useEffect(() => {
    const _fetchPrebuiltInfo = async (data) => {
      try {
        const res = await axios.post(
          `${config.apiRoot}/api/users/preBuiltInfo`,
          data
        );
        setQuery({ ...query, ...res.data, code: data.code });
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message 
          ? error.message 
          : "An Unknown Error Occured Trying To Fetch Preloaded Data";

        if (!message) {
          console.log(error.response);
        }
        console.log("ERROR", message);
        alert("Error: "+ message);
      }
    };
    const queries = queryString.parse(window.location.search);
    if (queries.code && queries.email) {
      console.log("qa", queries.code);
      // setQuery({ ...query, code: queries.code, email: queries.email });
      _fetchPrebuiltInfo({ email: queries.email, code: queries.code });
    } else if (queries.email) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setQuery({ ...query, email: queries.email });
      if (auth.token) setFillProfile(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleChange = (e) => {
    setQuery({
      ...query,
      [e.target.id]: e.target.value,
    });
  };

  const handlePhoto = (e) => {
    setFile(e.target.files[0]);
  };

  const onCaptcha = (value) => {
    setQuery({
      ...query,
      recaptcha: value,
    });
  };

  const handleSubmit = () => {
    // console.log(file);
    props.submitAccount(query, file, props.history, props.auth.token);
  };

  const renderOptions = (list) => {
    return list.map((item) => (
      <option value={item} key={item}>
        {item}
      </option>
    ));
  };

  const recommendedInput = { ...styles.input, ...styles.recommended };
  const recommendedSelect = { ...styles.selectInput, ...styles.recommended };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.auth.token && props.auth.profile ? <Redirect to="/" /> : null}
      {props.auth.token &&
      !props.auth.profile &&
      isEmpty(props.newUser.errors) ? (
        <div
          className="font-primary"
          style={{
            margin: "1rem 1rem 0rem 1rem",
            maxWidth: "800px",
            width: "100%",
            fontSize: "1rem",
            backgroundColor: "rgba(255, 204, 75, 0.4)",
            // opacity: 0.3,
            color: "#f1f1f1",
            padding: "0.5rem",
            textAlign: "center",
          }}
        >
          <p style={{ opacity: 1 }}>
            You do not have a profile yet which limits what you can do. Finish
            your profile to gain full user features.
          </p>
        </div>
      ) : null}
      <div
        className={"font-primary"}
        style={{
          margin: "1rem 1rem 0rem 1rem",
          maxWidth: "800px",
          width: "100%",
          fontSize: "1rem",
          color: "#eee",
        }}
      >
        <p>
          Thank you for registering for BlackLivesBiz! Registered visitors can
          do more with BLB and the Bizs listed, such as:
        </p>
        <ul>
          <li>Saving your favorite Bizs for later!</li>
          <li>
            Find a biz with missing info? Help out BLB and the Biz by submitting
            the missing information (Pending Approval)
          </li>
          <li>
            Claim your own Biz so you may manage it and add details.
            (Verification required)
          </li>
          <li>More features to come!</li>
        </ul>
        <p style={{ color: "#4286f7" }}>
          Fields in BLUE are HIGHLY recommended.
        </p>
      </div>

      <Form
        onSubmit={handleSubmit}
        style={{
          maxWidth: "800px",
        }}
      >
        <div style={styles.inlineFields}>
          <Input
            style={
              fillProfile
                ? { ...styles.input, color: "grey", backgroundColor: "#ddd" }
                : {
                    ...recommendedInput,
                    border: errors.password
                      ? "1px solid red"
                      : recommendedInput.border,
                  }
            }
            id="email"
            type="email"
            placeholder="Email"
            onChange={handleChange}
            value={query.email}
            disabled={fillProfile}
          />
        </div>
        {errors.email ? <p style={styles.error}>{errors.email}</p> : null}
        <div style={styles.inlineFields}>
          <Input
            style={{
              ...recommendedInput,
              border: errors.password
                ? "1px solid red"
                : recommendedInput.border,
              display: fillProfile ? "none" : "block",
            }}
            id="password"
            type="password"
            placeholder="Password"
            onChange={handleChange}
            value={query.password}
            disabled={fillProfile}
          />
        </div>
        {errors.password ? <p style={styles.error}>{errors.password}</p> : null}
        <div style={styles.inlineFields}>
          <Input
            style={{
              ...recommendedInput,
              border: errors.name ? "1px solid red" : recommendedInput.border,
            }}
            id="firstName"
            placeholder="First Name"
            onChange={handleChange}
            value={query.firstName}
          />
          <Input
            style={{
              ...recommendedInput,
              border: errors.name ? "1px solid red" : recommendedInput.border,
            }}
            id="lastName"
            placeholder="Last Name"
            onChange={handleChange}
            value={query.lastName}
          />
        </div>
        {errors.name ? <p style={styles.error}>{errors.name}</p> : null}
        <div style={styles.inlineFields}>
          <select
            style={{
              ...recommendedSelect,
              border: errors.ethnicity
                ? "1px solid red"
                : recommendedInput.border,
              color: query.ethnicity === "" ? "gray" : "black",
            }}
            id="ethnicity"
            onChange={handleChange}
            value={query.ethnicity}
          >
            <option value="" disabled>
              Ethnicity
            </option>
            {renderOptions(ethnicities)}
          </select>
        </div>
        {errors.ethnicity ? (
          <p style={styles.error}>{errors.ethnicity}</p>
        ) : null}
        <div style={styles.inlineFields}>
          <textarea
            style={styles.textArea}
            placeholder="Short description of yourself or what brings you to our community"
            onChange={handleChange}
            value={query.bio}
            id="bio"
          />
        </div>
        <div style={{ ...styles.inlineFields, alignItems: "center" }}>
          <div style={{ flexDirection: "column" }}>
            <img
              alt="avatar preview"
              width={150}
              height={150}
              src={file ? URL.createObjectURL(file) : blankProfile}
              style={{ objectFit: "cover", border: "1px solid black" }}
            />
          </div>
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <p
              className={"font-primary"}
              style={{
                fontSize: "1.25rem",
                color: "white",
                paddingLeft: "0.5rem",
              }}
            >
              Choose a profile image
            </p>
            <input
              style={{
                ...styles.input2,
                ...styles.input,
                ...recommendedInput,
                flex: 0,
              }}
              type="file"
              id="photo"
              accept="image/png, image/jpeg"
              ref={fileInput}
              onChange={handlePhoto}
            />
          </div>
        </div>
        {errors.recaptcha ? (
          <p style={styles.error}>{errors.recaptcha}</p>
        ) : null}
        <div style={styles.inlineFields}>
          <ReCAPTCHA
            style={{ margin: "1em 0 0.5em 0" }}
            ref={recaptchaRef}
            sitekey="6Lf_k74ZAAAAAEOGZFfU0AJNG69OJKVpK6XBoa6I"
            onChange={onCaptcha}
          />
        </div>
        <Button
          style={{ ...styles.button, margin: "1.5rem 0 0 0" }}
          disabled={props.newUser.submitting}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

const styles = {
  inlineFields: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minWidth: 400,
    borderRadius: 5,
    overflow: "hidden",
    flexWrap: "wrap",
  },
  li: {
    margin: "0.5em 0",
  },
  input: {
    border: "1px solid #d9d9d9",
    paddingRight: 5,
    paddingLeft: 10,
    width: "100%",
    minWidth: 250,
    margin: 0,
    height: 30,
    borderRadius: 5,
    flex: 1,
    fontFamily:
      '"Exo 2", -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  },
  input2: {
    color: "#000",
    backgroundColor: "#eee",
    fontSize: 18,
    padding: 5,
  },
  selectInput: {
    color: "#000",
    backgroundColor: "#eee",
    fontSize: 18,
    // lineHeight: 23,
    border: "1px solid #d9d9d9",
    padding: "5px 5px 5px 10px",
    width: "100%",
    minWidth: 250,
    height: 42,
    margin: 0,
    borderRadius: "5px",
    flex: 1,
  },
  recommended: {
    border: "1px solid #4286f7",
    backgroundColor: "#e6eaf0",
  },
  textArea: {
    border: "1px solid #d9d9d9",
    padding: "5px 5px 5px 10px",
    fontFamily:
      '"Exo 2", -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    // paddingRight: 5,
    // paddingLeft: 10,
    width: "100%",
    minWidth: 250,
    margin: 0,
    minHeight: 30,
    borderRadius: 5,
    flex: 1,
    backgroundColor: "#eee",
    fontSize: 18,
  },
  button: {
    width: "auto",
    height: 42,
    paddingRight: 20,
    paddingLeft: 20,
    margin: "0px",
    borderRadius: "5px",
    fontSize: 18,
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
  },
  error: {
    color: "red",
    fontWeight: "bold",
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 3,
    paddingLeft: 5,
  },
};

const mapStateToProps = (state) => ({
  newUser: state.newUser,
  auth: state.auth,
});

export default connect(mapStateToProps, { submitAccount })(
  withRouter(RegisterForm)
);
