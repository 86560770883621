import React, { Component } from "react";
import Bizs from "../Biz/Bizs";
import homeSplash from "../../assets/img/homesplash.jpg";
import SearchForm from "../Search/SearchForm";
import { connect } from "react-redux";
import { defaultLocation, bizsFetch, setLocation } from "../../actions";

class Home extends Component {
  componentDidMount = () => {
    if ("geolocation" in navigator) {
      const defaultLocation = localStorage.getItem("defaultLocation");
      console.log(defaultLocation);
      if (!defaultLocation) {
        console.log("get location");
        this._getLocation();
      } else {
        this.props.setLocation(JSON.parse(defaultLocation));
      }
    }
    // this.props.bizsFetch();
  };

  _getLocation = async () => {
    //add system to detect if location was already pulled and use that.
    await navigator.geolocation.getCurrentPosition((position) => {
      console.log(position.coords);
      this.props.defaultLocation(
        position.coords.latitude,
        position.coords.longitude
      );
    });
  };

  render() {
    return (
      <div>
        <link rel="canonical" href="https://blacklivesbiz.com" />
        <div
          style={{
            backgroundImage: `url(${homeSplash})`,
            backgroundSize: "cover",
            height: "65vh",
            display: "flex",
            position: "relative",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 0,
          }}
        >
          <div
            style={{
              height: "65vh",
              width: "100%",
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgb(51, 51, 51)",
              opacity: 0.5,
              zIndex: -1,
              position: "absolute",
            }}
          />
          <h1
            style={{
              fontSize: "3rem",
              alignSelf: "center",
              margin: "0.85em 0 0.4em 0",
              fontFamily: "'Exo 2', sans-serif",
              fontWeight: "900",
              color: "#f1f1f1",
              textShadow: "1px 1px black",
              textAlign: "center",
            }}
          >
            Welcome to Black Lives Biz
          </h1>
          <h2
            style={{
              fontSize: "2.3rem",
              alignSelf: "center",
              margin: "0 0 0.85em 0",
              fontFamily: "'Exo 2', sans-serif",
              fontWeight: "900",
              color: "#f1f1f1",
              textShadow: "1px 1px black",
              textAlign: "center",
            }}
          >
            Find local black-owned businesses in your city and state!
          </h2>
          <SearchForm />
        </div>
        <div
          style={{
            display: "flex",
            justifyItems: "center",
            flexDirection: "column",
          }}
        >
          <h2
            style={{
              fontSize: "2.75rem",
              alignSelf: "center",
              margin: "0.85em 0",
              fontFamily: "'Exo 2', sans-serif",
              fontWeight: "900",
              color: "#4286f7",
              textShadow: "1px 10px 20px rgba(91, 91, 91, 0.7)",
            }}
          >
            Explore
          </h2>
        </div>
        <Bizs
          location="home"
          // newBizs={this.props.bizs.list.new}
          // bizs={this.props.bizs.list.existing}
          // loading={this.props.bizs.loading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bizs: state.bizs,
});

export default connect(mapStateToProps, {
  defaultLocation,
  bizsFetch,
  setLocation,
})(Home);
