// Search form
import React, { Component, useState, useContext } from "react";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config";
import isEmpty from "../../utils/isEmpty";
import { useHistory, useLocation } from "react-router-dom";
import Form from "../common/Form";
import Input from "../common/Input";
import Button from "../common/Button";
import Spinner from "../common/Spinner";
import { StatesContext, CitiesContext } from "../Providers/StatesProvider";
import { CategoriesContext } from "../Providers/CategoriesProvider";
import { searchSubmit } from "../../actions";

const SearchForm = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [query, setQuery] = useState({
    state: "",
    city: "",
    name: "",
    category: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [states, setStates] = useContext(StatesContext);
  const cities = useContext(CitiesContext);
  const categories = useContext(CategoriesContext);

  const searchError = (message) => {
    setSubmitting(false);
    setErrors(message);
  };
  const handleSubmit = () => {
    setSubmitting(true);
    setErrors({});
    const { city, state, category, name } = query;
    if (isEmpty(state)) {
      return searchError({ search: "State is required" });
    }
    if (!(!isEmpty(city) || !isEmpty(category) || !isEmpty(name))) {
      return searchError("Must include one other search option");
    }
    _submitSearch();
  };

  const _submitSearch = async () => {
    let url = `${config.apiRoot}/api/biz/search/query`;
    try {
      const res = await axios.get(url, { params: query });
      if (location.pathname === "/search") {
        props.update({ ...res.data, query });
        setSubmitting(false);
      } else {
        setSubmitting(false);
        history.push({
          pathname: "/search",
          state: { list: res.data.bizs, query, hasMore: res.data.hasMore },
        });
      }
    } catch (error) {
      searchError({
        search: error.response ? error.response.data : error.response,
      });
    }
  };

  const handleChange = (e) => {
    setQuery({ ...query, [e.target.id]: e.target.value });
    // props.searchUpdate(e.target.id, e.target.value);
  };

  const renderStates = () => {
    return states.list.map((st) => (
      <option value={st.abbr} key={st.abbr}>
        {st.abbr}
      </option>
    ));
  };

  const handleStateChange = (e) => {
    setQuery({ ...query, [e.target.id]: e.target.value });
    // props.searchUpdate(e.target.id, e.target.value);
    setStates({ ...states, selected: e.target.value });
  };

  const renderSortedCities = () => {
    return cities === null
      ? null
      : cities.counted.slice(0, 20).map((city) => (
          <option value={city.name} key={city._id}>
            {city.name} ({city.count})
          </option>
        ));
  };

  const renderCities = () => {
    return cities === null
      ? null
      : cities.names.map((city) => (
          <option value={city} key={city}>
            {city}
          </option>
        ));
  };

  const renderCategories = () => {
    return categories.map((cat) => (
      <option value={cat.name} key={cat._id}>
        {cat.name}
      </option>
    ));
  };

  return (
    <Form
      onSubmit={handleSubmit}
      style={{
        marginRight: "0",
        marginLeft: "0",
        width: "100%",
        maxWidth: 700,
        padding: "0.5rem",
        backgroundColor: "rgba(250, 250, 250, 0.5)",
        borderRadius: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          // marginRight: "25%",
          // marginLeft: "25%",
          minWidth: 300,
          marginBottom: "0.5rem",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Input
            style={{
              ...styles.input,
              borderRadius: 5,
              flex: 1,

              maxWidth: 700,
            }}
            id="name"
            placeholder="Business Name"
            onChange={handleChange}
            value={query.name}
          />
        </div>
      </div>
      <div //div contains select inputs and search button
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          // marginLeft: "20%",
          // marginRight: "20%",
          // minWidth: "400px",
          borderRadius: 5,
          overflow: "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <div style={{ width: "20%" }}></div> */}
        <select
          style={{
            ...styles.selectInput,
            color: query.state === "" ? "gray" : "black",
            minWidth: 100,
            maxWidth: 300,
          }}
          id="state"
          onChange={handleStateChange}
          value={query.state}
        >
          <option value="" disabled>
            State
          </option>
          {renderStates()}
        </select>
        <select
          style={{
            ...styles.selectInput,
            color: query.city === "" ? "gray" : "black",
          }}
          id="city"
          onChange={handleChange}
          value={query.city}
        >
          <option value="">
            {cities === null ? "<<<" : `${query.state} Cities`}
          </option>
          <optgroup label="Top 25 Cities">{renderSortedCities()}</optgroup>
          <optgroup label="Alphabetical">{renderCities()}</optgroup>
        </select>
        <select
          style={{
            ...styles.selectInput,
            color: query.category === "" ? "gray" : "black",
          }}
          id="category"
          onChange={handleChange}
          value={query.category}
        >
          <option value="">Category</option>
          {renderCategories()}
        </select>
        <Button style={styles.button} disabled={submitting}>
          {submitting ? <Spinner /> : "Search"}
        </Button>
        {/* <div style={{ width: "18%" }}></div> */}
      </div>
      {errors.search ? <p style={styles.error}>{errors.search}</p> : null}
    </Form>
  );
};

const styles = {
  input: {
    border: "1px solid #d9d9d9",
    paddingRight: 5,
    paddingLeft: 10,
    width: "100%",
    margin: 0,
    height: 20,
    fontSize: 15,
    fontFamily:
      '"Exo 2", -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif ',
  },
  selectInput: {
    color: "#000",
    backgroundColor: "#eee",
    fontSize: 15,
    wordBreak: "break-word",
    // lineHeight: 23,
    border: "1px solid #d9d9d9",
    padding: "5px 5px 5px 10px",
    width: "100%",
    height: 32,
    margin: "0",
    borderRadius: "5px",
    flex: 1,
    cursor: "pointer",
    minWidth: 250,
  },
  button: {
    width: "auto",
    height: 32,
    paddingRight: 10,
    paddingLeft: 10,
    borderRadius: "5px",
    fontSize: 15,
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
    border: "none",
    cursor: "pointer",
    margin: 0,
  },
  error: {
    color: "firebrick",
    fontWeight: "bold",
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 3,
    paddingLeft: 5,
  },
};

export default SearchForm;
