import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { setError } from "../../../actions";
import { withRouter } from "react-router-dom";
import ChangeOption from "./ChangeOption";
import Submitter from "./Submitter";
import isEmpty from "../../../utils/isEmpty";
import axios from "axios";
import config from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const keys = [
  "state",
  "city",
  "zipcode",
  "street_name",
  "category",
  "about",
  "years",
  "business_web",
  "owner_first_name",
  "owner_last_name",
  "owner_phone",
  "twitter",
  "instagram",
  "facebook",
  "open_hour",
  "close_hour",
  "photo",
];

const Change = (props) => {
  const [biz, setBiz] = useState({});
  const [selections, setSelections] = useState({
    state: "",
    city: "",
    zipcode: "",
    street_name: "",
    category: "",
    about: "",
    years: "",
    business_web: "",
    owner_first_name: "",
    owner_last_name: "",
    owner_phone: "",
    twitter: "",
    instagram: "",
    facebook: "",
    open_hour: "",
    close_hour: "",
    photo: "",
  });
  const [selectionIds, setSelectionIds] = useState({
    state: null,
    city: null,
    zipcode: null,
    street_name: null,
    category: null,
    about: null,
    years: null,
    business_web: null,
    owner_first_name: null,
    owner_last_name: null,
    owner_phone: null,
    twitter: null,
    instagram: null,
    facebook: null,
    open_hour: null,
    close_hour: null,
    photo: null,
  });
  const [changeRequests, setChangeRequests] = useState([]);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    // onMount fetch biz
    setFetching(true);
    _asyncFetchBiz();
  }, []);

  const _asyncFetchBiz = async () => {
    const bizId = props.location.state.id;
    const url = `${config.apiRoot}/api/admin/biz/changes/${bizId}`;
    try {
      const res = await axios.get(url, {
        headers: { "x-blb-token": props.auth.token },
      });
      console.log(res.data);
      setBiz({ ...res.data });
      setFetching(false);
    } catch (error) {
      props.setError("Failed to fetch biz", 3, props.history);
    }
  };

  useEffect(() => {
    console.log("running");
    if (isEmpty(biz)) return;
    console.log(biz);
    const cr = [];
    for (let i = 0; i < biz.changeRequests.length; i++) {
      cr.push({
        _id: biz.changeRequests[i]._id,
        profileId: biz.changeRequests[i].profileId,
        changes: JSON.parse(biz.changeRequests[i].changes),
      });
    }
    setChangeRequests([...cr]);
  }, [biz]);

  const handleSubmit = async () => {
    const updates = {};
    const profiles = {};
    console.log(changeRequests);
    const crs = changeRequests.map((cr) => cr._id);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (!isEmpty(selections[key])) {
        updates[key] = selections[key];
        if (profiles[selectionIds[key]]) {
          profiles[selectionIds[key]] += 1;
        } else {
          profiles[selectionIds[key]] = 1;
        }
      }
    }
    const url = `${config.apiRoot}/api/admin/biz/acceptChanges/${biz._id}`;
    try {
      const _ = await axios.put(
        url,
        { updates, profiles, crs },
        { headers: { "x-blb-token": props.auth.token } }
      );
      props.history.push("/fakeAdmin/reviewChanges");
    } catch (error) {
      props.setError(`Failed to update biz ${error.message}`, 3, props.history);
    }
  };

  const changeSelection = (field, value, id) => {
    setSelections({ ...selections, [field]: value });
    setSelectionIds({ ...selectionIds, [field]: id });
  };

  const renderHeaders = () => {
    return changeRequests.map((change) => {
      console.log(change);
      return (
        <div style={styles.cell} key={`header${change.name}`}>
          <p>{change.profileId.name}</p>
          <p>score: {change.profileId.reporterScore}</p>
        </div>
      );
    });
  };

  const renderRow = (key) => {
    return changeRequests.map((cr) =>
      isEmpty(cr.changes[key]) ? (
        <ChangeOption
          isEmpty
          displayValue="No change here"
          key={`${cr.profileId._id}${key}`}
        />
      ) : (
        <ChangeOption
          field={key}
          displayValue={cr.changes[key]}
          value={cr.changes[key]}
          formValue={selections[key]}
          requesterId={cr.profileId._id}
          onSelect={changeSelection}
          key={`${cr.profileId._id}${key}`}
        />
      )
    );
  };

  const renderRows = () => {
    return keys.map((key) => (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
        key={`row${key}`}
      >
        <div style={{ ...styles.cell, width: "15em" }}>
          <p className="font-primary">{key}</p>
        </div>
        <ChangeOption
          field={key}
          displayValue={biz[key] ? biz[key] : "No default value"}
          value=""
          formValue={selections[key]}
          onSelect={changeSelection}
          requesterId={null}
        />
        {renderRow(key)}
      </div>
    ));
  };

  const renderTable = () => {
    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
          }}
        >
          <div style={{ ...styles.cell, width: "15em" }}></div>
          <div style={styles.cell}>
            <p>{biz.name}</p>
            <p>Original Data</p>
          </div>
          {renderHeaders()}
        </div>
        {renderRows()}
      </Fragment>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "columnt",
      }}
    >
      <div
        className="font-primary"
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1",
          padding: "1.5rem",
          // paddingRight: "1.5rem",
          // paddingLeft: "1.5rem",
          backgroundColor: "#fff",
          overflowX: "scroll",
        }}
      >
        {fetching ? (
          <FontAwesomeIcon
            icon={["fas", "spinner"]}
            spin
            style={{ fontSize: 20, margin: "0 auto" }}
          />
        ) : (
          renderTable()
        )}
        <Submitter submitHandler={handleSubmit} />
      </div>
    </div>
  );
};

const styles = {
  cell: {
    border: "1px solid black",
    padding: "0.5rem",
    width: "25em",
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setError })(withRouter(Change));
