import React, { useEffect, useState, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AdminImgUploadAuth } from "../../actions";
import Form from "../common/Form";
import Button from "../common/Button";
import queryString from "query-string";
import goodLogoEx from "../../assets/img/blbLogoLandscape.png";
import badLogoEx from "../../assets/img/blbLogoSquare.png";
import badLogoExCrop from "../../assets/img/blbLogoSquareCroped.png";

const AdminPhoto = (props) => {
  const [display, setDisplay] = useState(false);
  const fileInput = createRef;
  const [file, setFile] = useState(null);
  const [id, setId] = useState(null);

  const photoChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    const queries = queryString.parse(window.location.search);
    if (queries.id) {
      setId(queries.id);
    }
  }, []);

  const handleSubmit = () => {
    props.AdminImgUploadAuth(props.auth.token, id, file, props.history);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className={"font-primary"}
        style={{
          margin: "1rem 1rem 0rem 1rem",
          maxWidth: "800px",
          width: "100%",
          fontSize: "1rem",
        }}
      >
        <p>Select a photo with the following requirements</p>
        <ul>
          <li>in landscape orientation</li>
          <li>with a 16:9 resolution ratio</li>
          <li>at least 500 pixels wide</li>
        </ul>
        <p>
          As an admin you should always upload a photo that best will represent
          the business on the site.
        </p>
        <button
          onClick={() => setDisplay(!display)}
          style={{ ...styles.exampleDisBtn }}
        >
          {display ? "Hide Examples" : "Show Examples"}
        </button>
        {display ? (
          <div>
            <h3>Good Logo Aspect Ratio</h3>
            <img alt="good example" src={goodLogoEx} style={{ height: 100 }} />
            <h3>Bad Logo Aspect Ratio</h3>
            <img
              alt="bad example"
              src={badLogoEx}
              style={{ height: 100, marginRight: 100 }}
            />
            <h3>Bad Logo (What A Viewer Will See)</h3>
            <img
              alt="bad example cropped"
              src={badLogoExCrop}
              style={{ height: 100, marginRight: 100 }}
            />
          </div>
        ) : null}
      </div>
      <Form
        onSubmit={handleSubmit}
        style={{
          margin: "1.3rem 1rem 2rem 1rem",
          maxWidth: "800px",
          width: "100%",
        }}
      >
        <div style={styles.inlineFields}>
          <input
            style={{ ...styles.input2, ...styles.input }}
            type="file"
            id="photo"
            accept="image/png, image/jpeg"
            ref={fileInput}
            onChange={photoChange}
            required
          />
        </div>
        <Button>Submit</Button>
      </Form>
    </div>
  );
};

const styles = {
  inlineFields: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minWidth: 400,
    borderRadius: 5,
    overflow: "hidden",
    flexWrap: "wrap",
  },
  input: {
    border: "1px solid #d9d9d9",
    paddingRight: 5,
    paddingLeft: 10,
    width: "100%",
    minWidth: 250,
    margin: 0,
    height: 30,
    borderRadius: 5,
    flex: 1,
  },
  input2: {
    color: "#000",
    backgroundColor: "#eee",
    fontSize: 18,
    padding: 5,
  },
  button: {
    width: "auto",
    height: 42,
    paddingRight: 10,
    paddingLeft: 10,
    margin: "0px",
    borderRadius: "5px",
    fontSize: 18,
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
  },
  exampleDisBtn: {
    width: "auto",
    height: 42,
    paddingRight: 10,
    paddingLeft: 10,
    margin: "0px",
    borderRadius: "5px",
    border: "none",
    fontSize: 18,
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
  },
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { AdminImgUploadAuth })(
  withRouter(AdminPhoto)
);
