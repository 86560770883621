import React, { Component } from "react";
import ReactModal from "react-modal";
import AdminBizMini from "./AdminBizMini";
import { connect } from "react-redux";
import { adminBizsFetch, approveBiz, denyBiz, flagBiz } from "../../actions";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../common/Button";
import isEmpty from "../../utils/isEmpty";
import flagReasons from "../../utils/flagReasons";

ReactModal.setAppElement("#root");

class AdminBizs extends Component {
  state = {
    showModal: false,
    id: null,
    flagReason: "",
    error: null,
  };

  componentDidMount() {
    this.props.adminBizsFetch(this.props.token);
  }

  handleApproval = (id) => {
    this.props.approveBiz(this.props.token, id);
  };

  handleDeny = (id) => {
    this.props.denyBiz(this.props.token, id);
  };

  handleFlag = (id) => {
    this.setState({
      showModal: true,
      id,
    });
  };

  handleOpenModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleOkModal = () => {
    if (this.state.flagReason === "") {
      return this.setState({
        error: "Select a reason",
      });
    }
    this.props.flagBiz(this.props.token, this.state.id, this.state.flagReason);
    this.setState({
      showModal: false,
      id: null,
      flagReason: "",
      error: null,
    });
  };

  renderAdminBizs = () =>
    this.props.adminBizs.list.map((biz) => (
      <AdminBizMini
        biz={biz}
        adminApprove={this.handleApproval}
        adminDeny={this.handleDeny}
        flag={this.handleFlag}
        key={biz._id}
      />
    ));

  renderReasons = () =>
    flagReasons.map((reason) => (
      <option value={reason} key={reason}>
        {reason}
      </option>
    ));

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: "1",
            justifyContent: "space-evenly",
            paddingRight: "1.5rem",
            paddingLeft: "1.5rem",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
            backgroundColor: "#FFF",
          }}
        >
          {isEmpty(this.props.token) ? <Redirect to="/" /> : null}
          {this.props.adminBizs.loading ? (
            <FontAwesomeIcon
              icon={["fas", "spinner"]}
              spin
              style={{ fontSize: 20, margin: "0 auto" }}
            />
          ) : (
            this.renderAdminBizs()
          )}
        </div>
        <ReactModal
          isOpen={this.state.showModal}
          contentLabel="Flag Biz Reason"
          style={styles.modal}
        >
          <p
            className={"font-primary"}
            style={{
              fontSize: "1.25rem",
              fontWeight: "bold",
              margin: "0px 0px 1rem 0px",
            }}
          >
            Flagging a Biz
          </p>
          <div>
            <select
              style={{
                ...styles.selectInput,
                color: this.state.flagReason === "" ? "gray" : "black",
              }}
              id="flagReason"
              onChange={(e) =>
                this.setState({ ...this.state, flagReason: e.target.value })
              }
              value={this.state.flagReason}
            >
              <option value="" disabled>
                Reason for flag
              </option>
              {this.renderReasons()}
            </select>
          </div>
          {this.state.error ? (
            <p className={"font-primary"} style={styles.error}>
              {this.state.error}
            </p>
          ) : null}
          <div
            style={{
              width: "70%",
              margin: "1rem auto 0px auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                flex: 1,
                backgroundColor: "forestgreen",
                margin: "0px 0.5rem",
                borderWidth: 0,
              }}
              onClick={this.handleOkModal}
            >
              Submit
            </Button>
            <Button
              style={{
                flex: 1,
                backgroundColor: "firebrick",
                margin: "0px 0.5rem",
                borderWidth: 0,
              }}
              onClick={this.handleCloseModal}
            >
              Cancel
            </Button>
          </div>
        </ReactModal>
      </div>
    );
  }
}

const styles = {
  modal: {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  },
  selectInput: {
    color: "#000",
    backgroundColor: "#eee",
    fontSize: 18,
    // lineHeight: 23,
    border: "1px solid #d9d9d9",
    padding: "5px 5px 5px 10px",
    width: "100%",
    minWidth: 250,
    height: 42,
    margin: 0,
    borderRadius: "5px",
    flex: 1,
  },
  error: {
    color: "red",
    fontWeight: "bold",
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 3,
    paddingLeft: 5,
  },
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  adminBizs: state.adminBizs,
});
export default connect(mapStateToProps, {
  adminBizsFetch,
  approveBiz,
  denyBiz,
  flagBiz,
})(AdminBizs);
