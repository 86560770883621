import {
  STATES_FETCH,
  STATES_FETCH_FAIL,
  STATES_FETCH_SUCCESS,
  CITIES_FETCH,
  CITIES_FETCH_FAIL,
  CITIES_FETCH_SUCCESS,
} from "../actions/types";

const INITIAL_STATE = {
  states: [],
  cities: null,
  loading: false,
  errors: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STATES_FETCH:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case STATES_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case STATES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        states: action.payload,
      };
    case CITIES_FETCH:
      return {
        ...state,
        loading: true,
        cities: null,
        errors: {},
      };
    case CITIES_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        cities: null,
        errors: action.payload,
      };
    case CITIES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        cities: action.payload,
      };
    default:
      return state;
  }
};
