import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setError } from "../../../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Claim from "./Claim";
import { withRouter } from "react-router-dom";
import axios from "axios";
import config from "../../../config";

const ReviewClaims = (props) => {
  const { auth, history, setError } = props;
  const [fetching, setFetching] = useState(true);
  // const [error, setError] = useState(null);
  const [claims, setClaims] = useState([]);

  useEffect(() => {
    const _asyncFetchClaims = async () => {
      try {
        const url = `${config.apiRoot}/api/admin/biz/ownerClaims`;
        const res = await axios.get(url, {
          headers: { "x-blb-token": auth.token },
        });
        console.log("claims", res.data);
        setClaims(res.data);
        setFetching(false);
      } catch (error) {
        console.log(error);
        setError(
          `Failed to load claims from server, ${error.message}`,
          3,
          history
        );
      }
    };
    //fetch claims
    _asyncFetchClaims();
  }, [history, auth, setError]);

  const _approveClaim = async (bizId, ownerId, onError) => {
    try {
      const url = `${config.apiRoot}/api/admin/biz/assignOwner`;
      const res = await axios.put(
        url,
        { bizId, ownerId },
        { headers: { "x-blb-token": props.auth.token } }
      );
      console.log(res.data);
      setClaims(claims.filter((claim) => claim._id !== bizId));
    } catch (error) {
      onError(error.message);
    }
  };

  const _denyClaim = async (bizId, claimantId, submitControl, onError) => {
    try {
      const url = `${config.apiRoot}/api/admin/biz/denyClaim`;
      const res = await axios.put(
        url,
        { bizId, claimantId },
        { headers: { "x-blb-token": props.auth.token } }
      );
      console.log(res.data);
      if (res.data.claimRequestIds.length === 0) {
        setClaims(claims.filter((claim) => claim._id !== bizId));
      } else {
        setClaims(
          claims.map((claim) => (claim._id === bizId ? res.data : claim))
        );
        submitControl(false);
      }
    } catch (error) {
      onError(error.message);
    }
  };

  const renderClaims = () => {
    return claims.map((claim) => (
      <Claim
        biz={claim}
        claimants={claim.claimRequestIds}
        onApproval={_approveClaim}
        onDenial={_denyClaim}
        key={claim._id}
      />
    ));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-evenly",
          paddingRight: "1.5rem",
          paddingLeft: "1.5rem",
          alignItems: "center",
          flexDirection: "row",
          flexWrap: "wrap",
          backgroundColor: "#fff",
        }}
      >
        {fetching ? (
          <FontAwesomeIcon
            icon={["fas", "spinner"]}
            spin
            style={{ fontSize: 20, margin: "0 auto" }}
          />
        ) : (
          renderClaims()
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setError })(withRouter(ReviewClaims));
