import React, { useState, useEffect, Fragment } from "react";
import { useRef } from "react";
import HorizontalLine from "../../common/HorizontalLine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import isEmpty from "../../../utils/isEmpty";
const roseWeaver = "https://storage.googleapis.com/photos-blb/RoseWeaver.jpg";

const BlackArtist = (props) => {
  const {
    artist,
    pageUrl = "",
    baseUrl = "blacklivesbiz.com/exclusives/temp",
    isThin,
    isOwner,
  } = props;
  const [showAbout, setShowAbout] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const listingRef = useRef();
  //create the anchorLink
  const anchorLink = (artist.owner_first_name + artist.owner_last_name)
    .toLowerCase()
    .replace(/ |\.|\’|'|\-|\”|\“/g, "");
  const artistShareLink = baseUrl + "#" + anchorLink;

  useEffect(() => {
    if (pageUrl.includes("#" + anchorLink)) listingRef.current.scrollIntoView(); //{ behavior: 'smooth' } adding the smooth behavior made it so some of the listing were not completely scrolled into veiw (especially ones closer to the bottom)
  }, []);

  const copyArtistLink = () => {
    if (navigator.clipboard != undefined) {
      navigator.clipboard.writeText(artistShareLink);
      setLinkCopied(true);
      ReactTooltip.hide();
      setTimeout(() => {
        setLinkCopied(false);
      }, 5000);
    }
  };

  const styles = {
    artistName: {
      fontSize: isThin ? "2em" : "3em",
    },
    artistLinkDiv: {
      minWidth: isThin ? 0 : 500,
      fontSize: isThin ? 11 : 18,
      justifyContent: isThin ? "center" : "left",
    },
    categoryText: {
      fontSize: isThin ? "1.5em" : "2.5em",
    },
    artistMainContainer: {
      // maxWidth: isThin ? "100%" : 1200
    },
  };

  const ArtistLink = () => {
    return (
      <Fragment>
        <p
          style={{
            fontSize: 15,
            userSelect: "none",
          }}
        >
          <b>{isThin ? "^ " : ""}Share a link to this Artist: </b>
          <span
            onClick={isThin ? copyArtistLink : null}
            style={{ cursor: "pointer" }}
          >
            {!isThin ? null : linkCopied ? "Link Copied" : "Copy Link"}
          </span>
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            ...styles.artistLinkDiv,
          }}
        >
          <p>{artistShareLink}</p>
          <svg
            width="16"
            height="16"
            aria-hidden="true"
            data-for="copy"
            data-tip=""
            style={{
              fontSize: 20,
              cursor: "pointer",
              marginLeft: 10,
              zIndex: linkCopied ? 100 : 99,
            }}
            onClick={copyArtistLink}
          >
            <path
              fillRule="evenodd"
              d="M5.75 1a.75.75 0 00-.75.75v3c0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75v-3a.75.75 0 00-.75-.75h-4.5zm.75 3V2.5h3V4h-3zm-2.874-.467a.75.75 0 00-.752-1.298A1.75 1.75 0 002 3.75v9.5c0 .966.784 1.75 1.75 1.75h8.5A1.75 1.75 0 0014 13.25v-9.5a1.75 1.75 0 00-.874-1.515.75.75 0 10-.752 1.298.25.25 0 01.126.217v9.5a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25v-9.5a.25.25 0 01.126-.217z"
            ></path>
          </svg>
          {!isThin ? (
            <ReactTooltip
              place="bottom"
              effect="solid"
              id="copy"
              getContent={() => {
                return linkCopied ? "Link Copied" : "Copy Link";
              }}
            />
          ) : null}
        </div>
      </Fragment>
    );
  };

  return (
    <div
      style={{
        marginBottom: 80,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "auto",
          maxWidth: 1200,
          marginBottom: 20,
          ...props.style,
          ...styles.artistMainContainer,
        }}
        id={anchorLink}
        ref={listingRef}
      >
        <div
          style={{
            flex: 1,
            padding: 10,
            textAlign: "right",
            fontSize: "1em",
          }}
        >
          <HorizontalLine />
          <h1
            style={{
              textAlign: "right",
              fontWeight: "bold",
              fontSize: "3em",
              margin: 0,
              paddingTop: 5,
              ...styles.artistName,
            }}
          >
            {artist.owner_first_name.toUpperCase()}
          </h1>
          <h1
            style={{
              textAlign: "right",
              fontWeight: "bold",
              fontSize: "3em",
              margin: 0,
              ...styles.artistName,
            }}
          >
            {artist.owner_last_name.toUpperCase()}
          </h1>
          <h2
            style={{
              textAlign: "right",
              fontWeight: "normal",
              // fontSize: "2.5em",
              ...styles.categoryText,
            }}
          >
            {artist.category.join(", ")}
          </h2>
          <div style={{ paddingTop: 6 }}>
            <p>
              <strong>Professional Name:</strong>{" "}
              <span style={{ display: "inline-block" }}>
                {artist.exclusiveMeta.proName}{" "}
              </span>
            </p>
            {!isEmpty(artist.exclusiveMeta.businessName) ? (
              <p>
                <strong>Business:</strong>{" "}
                <span style={{ display: "inline-block" }}>
                  {artist.exclusiveMeta.businessName}{" "}
                </span>
              </p>
            ) : null}
            <p>
              <strong>{artist.exclusiveMeta.subCategories.join(", ")}</strong>
            </p>
            {!isEmpty(artist.exclusiveMeta.artistMessage) ? (
              <p>
                <strong>{artist.exclusiveMeta.artistMessage}</strong>
              </p>
            ) : null}
          </div>
          {artist.owner_email ? (
            <div style={{ paddingTop: 6 }}>
              <p>
                <strong>Contact:</strong>{" "}
                <a href={`mailto:${artist.owner_email}`}>
                  {artist.owner_email}
                </a>
              </p>
              {artist.owner_phone ? <p>{artist.owner_phone}</p> : null}
            </div>
          ) : (
            <div style={{ paddingTop: 6 }}>
              <p>
                <strong>Contact:</strong> {artist.owner_phone}
              </p>
            </div>
          )}
          <div style={{ paddingTop: 6 }}>
            {artist.business_web ? (
              <p>
                <strong>WebSite:</strong>{" "}
                {artist.business_web.includes("http") ? (
                  <a
                    target="_blank"
                    href={artist.business_web}
                    rel="noopener noreferrer"
                  >
                    {" "}
                    {isThin
                      ? artist.business_web
                          .replace("https://", "")
                          .replace("http://", "")
                          .replace("www.", "")
                          .replace(/\/$/, "")
                      : artist.business_web}
                  </a>
                ) : (
                  artist.business_web
                )}
              </p>
            ) : null}
            {artist.facebook ? (
              artist.facebook.includes("http") ? (
                <p>
                  <a
                    target="_blank"
                    href={artist.facebook}
                    rel="noopener noreferrer"
                  >
                    <strong>Facebook</strong>{" "}
                  </a>
                </p>
              ) : (
                <p>
                  <strong>Facebook </strong>
                  {artist.facebook}
                </p>
              )
            ) : null}
            {artist.twitter ? (
              artist.twitter.indexOf("http") >= 0 ? (
                <p>
                  <a
                    target="_blank"
                    href={artist.twitter}
                    rel="noopener noreferrer"
                  >
                    <strong>Twitter</strong>
                  </a>
                </p>
              ) : artist.twitter.indexOf("@") >= 0 ? (
                <p>
                  <strong>Twitter</strong>{" "}
                  <a
                    target="_blank"
                    href={`https://twitter.com/${artist.twitter.substr(1)}`}
                    rel="noopener noreferrer"
                  >
                    {artist.twitter}
                  </a>
                </p>
              ) : (
                <p>
                  <strong>Twitter</strong> {artist.twitter}
                </p>
              )
            ) : null}
            {artist.instagram ? (
              artist.instagram.indexOf("http") >= 0 ? (
                <p>
                  <a
                    href={artist.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Instagram</strong>
                  </a>
                </p>
              ) : artist.instagram.indexOf("@") >= 0 ? (
                <p>
                  <strong>Instagram</strong>{" "}
                  <a
                    href={`https://www.instagram.com/${artist.instagram.substr(
                      1
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {artist.instagram}
                  </a>
                </p>
              ) : (
                <p>
                  <strong>Instagram </strong>
                  {artist.instagram}
                </p>
              )
            ) : null}
            {artist.owner_linkedin ? (
              artist.owner_linkedin.includes("http") ? (
                <p>
                  <a
                    target="_blank"
                    href={artist.owner_linkedin}
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <strong>LinkedIn</strong>
                  </a>
                </p>
              ) : (
                <p>
                  <strong>LinkedIn </strong>
                  {artist.owner_linkedin}
                </p>
              )
            ) : null}
            {!isEmpty(artist.exclusiveMeta.customLinks)
              ? artist.exclusiveMeta.customLinks.map((customLink, i) => {
                  if (
                    customLink.text == undefined ||
                    customLink.header == undefined
                  )
                    return null;
                  else
                    return customLink.text.includes("http") ? (
                      <p key={i}>
                        <a
                          target="_blank"
                          href={customLink.text}
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <strong>{customLink.header}</strong>
                        </a>
                      </p>
                    ) : (
                      <p key={i}>
                        <strong>{customLink.header} </strong>
                        {customLink.text}
                      </p>
                    );
                })
              : null}
          </div>
          {artist.city ? (
            <div style={{ paddingTop: 6 }}>
              <p style={{ fontWeight: "bold" }}>{artist.city}</p>
            </div>
          ) : null}
        </div>
        <div
          style={{
            flex: 2,
            flexDirection: "column",
            padding: 10,
            fontSize: "1em",
            width: isThin ? "33vw" : "550px",
          }}
        >
          <img
            width="100%"
            style={{ objectFit: "cover", maxWidth: "550px" }}
            src={
              props.photo === "local"
                ? artist.file
                  ? URL.createObjectURL(artist.file)
                  : roseWeaver
                : artist.photos[0]
                ? artist.photos[0]
                : roseWeaver
            }
            alt="test"
          />
          {!isThin ? <ArtistLink /> : null}
          {artist.about ? (
            <Fragment>
              {showAbout
                ? artist.about.split("\\n").map((seg, index) => (
                    <p style={{ padding: 6, maxWidth: 550 }} key={index}>
                      {seg}{" "}
                    </p>
                  ))
                : null}
              <p
                style={{ padding: 6, fontSize: "0.9em", cursor: "pointer" }}
                onClick={() => setShowAbout(!showAbout)}
              >
                {showAbout
                  ? "^ Click to hide Information"
                  : "> Click for more information"}
              </p>
            </Fragment>
          ) : null}
          {props.adminEditAccess ? (
            <p
              style={{ padding: 6, fontSize: "0.9em", cursor: "pointer" }}
              onClick={() => props.adminEditRoute(artist._id)}
            >
              Edit Details (Admin)
            </p>
          ) : null}
          {isOwner ? (
            <p
              style={{ padding: 6, fontSize: "0.9em", cursor: "pointer" }}
              onClick={() => props.adminEditRoute(artist._id)}
            >
              Edit Details
            </p>
          ) : null}
          <HorizontalLine />
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        {isThin ? <ArtistLink /> : null}
      </div>
    </div>
  );
};

export default BlackArtist;
