import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { getFormData, fetchCities } from "../../../actions";
import { withRouter, Route, Switch, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewBAForm from "./NewBAForm";
import config from "../../../config";

const roseWeaver = "https://storage.googleapis.com/photos-blb/RoseWeaver.jpg";

const AdminEditBA = (props) => {
  const [query, setQuery] = useState({
    state: "",
    city: "",
    category: [""],
    about: "",
    subCategories: "",
    business_web: "",
    owner_first_name: "",
    owner_last_name: "",
    exclusiveMeta: {
      proName: "",
      subCategories: [],
      customLinks: [],
    },
    proName: "",
    owner_email: "",
    owner_phone: "",
    owner_linkedin: "",
    twitter: "",
    instagram: "",
    facebook: "",
    file: "",
    owner_ethn: "Black (African Decent)",
    business_type: "Self-Employed",
    location: "Physical",
    open_hour: 8,
    close_hour: 17,
  });
  const [changedKeys, setChangedKeys] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [errors, setErrors] = useState({});
  const [categories, setCategories] = useState([]);
  const { id } = useParams();
  const { auth } = props;

  const updateForm = (field, value) => {
    setQuery({ ...query, [field]: value });
    setChangedKeys({ ...changedKeys, [field]: true });
  };

  const updateExclusive = (field, value) => {
    setQuery({
      ...query,
      exclusiveMeta: {
        ...query.exclusiveMeta,
        [field]: value,
      },
    });
    setChangedKeys({ ...changedKeys, exclusiveMeta: true });
  };

  useEffect(() => {
    const _fetchFormData = async () => {
      try {
        await props.getFormData();
        const bizRes = await axios.get(
          `${config.apiRoot}/api/admin/biz/exclusives/edit/${id}`,
          { headers: { "x-blb-token": props.auth.token } }
        );
        await props.fetchCities(bizRes.data.state);
        for (var prop in bizRes.data) {
          if (bizRes.data[prop] === null || bizRes.data[prop] === undefined)
            delete bizRes.data[prop];
        }
        const catRes = await axios.post(`${config.apiRoot}/api/cat/child`, {
          parent: "Arts & Entertainment",
        });
        // console.log(bizRes.data);
        // if there are default values of exclusiveMeta that an artist does not have. This will ensure the defaults are present. Otherwise they would be overwritten
        bizRes.data.exclusiveMeta = {
          ...query.exclusiveMeta,
          ...bizRes.data.exclusiveMeta,
        };
        setQuery({ ...query, ...bizRes.data });
        setCategories(catRes.data);
        setFetching(false);
      } catch (error) {
        const errors = error.response
          ? error.response.data
            ? error.response.data
            : { form: error.message }
          : { form: error.message };
        console.log(errors);
        setErrors(errors);
        setFetching(false);
      }
    };
    if (!auth.token) return;
    setFetching(true);
    setErrors({});
    _fetchFormData();
  }, [auth]);

  const _submitForm = async () => {
    setSubmitting(true);
    try {
      const newData = {};
      for (var prop in changedKeys) {
        newData[prop] = query[prop];
      }
      const res = await axios.post(
        `${config.apiRoot}/api/admin/biz/exclusives/edit/${id}`,
        newData,
        {
          headers: {
            "x-blb-token": auth.token,
          },
        }
      );
      if (changedKeys.file) {
        _uploadImg(res.data);
        return;
      }
      setSubmitting(false);
      props.history.push("/blackArtists/adminEdit/success");
    } catch (error) {
      const errors = error.response
        ? error.response.data
          ? error.response.data
          : { form: error.message }
        : { form: error.message };
      console.log(errors);
      setErrors(errors);
      setFetching(false);
    }
  };

  const _uploadImg = async (newBiz) => {
    try {
      const data = new FormData();
      data.append("image", query.file);
      await axios.put(
        `${config.apiRoot}/api/admin/biz/artistImgUpload/${newBiz._id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-blb-token": props.auth.token,
          },
        }
      );
      setSubmitting(false);
      props.history.push("/blackArtists/adminEdit/success");
      return;
    } catch (error) {
      const errors = error.response
        ? error.response.data
          ? error.response.data
          : { form: error.message }
        : { form: error.message };
      console.log(errors);
      setErrors(errors);
      setSubmitting(false);
    }
  };

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <div
        style={{
          backgroundImage: `url(${roseWeaver})`,
          backgroundSize: "cover",
          position: "relative",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "6rem 0 4rem 0",
          zIndex: 0,
        }}
      >
        <h1
          style={{
            fontSize: "3rem",
            alignSelf: "center",
            margin: "0.85em 0",
            fontFamily: "'Exo 2', sans-serif",
            fontWeight: "900",
            color: "#f1f1f1",
            textShadow: "1px 1px black",
            textAlign: "center",
          }}
        >
          Edit Artist {query.owner_first_name} {query.owner_last_name}
        </h1>
        {query.isOwned ? (
          <p>
            NOTE: Artist Record claimed by a user. We do not recomend modifying
            the record without approval from the artist.
          </p>
        ) : null}
      </div>
      <Switch>
        <Route path={"/blackArtists/adminEdit/edit/:id"}>
          {fetching ? (
            <FontAwesomeIcon
              icon={["fas", "spinner"]}
              spin
              style={{ fontSize: 20, margin: "0 auto" }}
            />
          ) : (
            <NewBAForm
              errors={errors}
              query={query}
              onSubmit={_submitForm}
              updateForm={updateForm}
              updateExclusive={updateExclusive}
              submitting={submitting}
              fetchCities={props.fetchCities}
              st={props.st}
              cat={categories}
              adminForm
              editForm
            />
          )}
        </Route>
        <Route path="/blackArtists/adminEdit/success" exact>
          <div style={{ textAlign: "center" }}>
            <h1>Success</h1>
            <p
              style={{ cursor: "pointer" }}
              onClick={() =>
                props.history.push(`/blackArtists/adminEdit/edit/${query._id}`)
              }
            >
              {" "}
              Click to change more
            </p>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  st: state.st,
  cat: state.categories,
});

export default connect(mapStateToProps, { getFormData, fetchCities })(
  withRouter(AdminEditBA)
);
