import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { fetchNotifs } from "../../actions";
import { BrowserView, MobileView } from "react-device-detect";
import { Route, Switch, withRouter } from "react-router-dom";

import BrowserAdminNavBar from "./BrowserAdminNavBar";
import MobileAdminNavBar from "./MobileAdminNavBar";

import backSplash from "../../assets/img/searchSplash.png";
import AdminBizs from "./AdminBizs";
import AdminNewBiz from "./AdminNewBiz";
import AdminDashboard from "./AdminDashboard";
import AdminPhoto from "./AdminPhoto";
import ReviewClaims from "./ReviewClaims/ReviewClaims";
import BizChanges from "./ReviewChanges/BizChanges";
import Change from "./ReviewChanges/Change";
import NewBlackArtist from "../Exclusives/BlackArtists/NewBlackArtist";

const Admin = (props) => {
  const { auth } = props;
  useEffect(() => {
    if (auth.token) {
      props.fetchNotifs(props.auth.token);
    }
  }, [auth]);

  const renderStandardAdmin = () => {
    return (
      <Switch>
        <Route path="/fakeAdmin/dash">
          <AdminDashboard />
        </Route>
        <Route path="/fakeAdmin/review">
          <AdminBizs />
        </Route>
        <Route path="/fakeAdmin/newBiz">
          <AdminNewBiz />
        </Route>
        <Route path="/fakeAdmin/photo">
          <AdminPhoto />
        </Route>
        <Route path="/fakeAdmin/claims">
          <ReviewClaims />
        </Route>
        <Route path="/fakeAdmin/reviewChanges">
          <BizChanges />
        </Route>
        <Route path="/fakeAdmin/changes/:id">
          <Change />
        </Route>
        <Route path="/fakeAdmin/blackArtists/newBiz">
          <NewBlackArtist />
        </Route>
      </Switch>
    );
  };

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <div
        style={{
          backgroundImage: `url(${backSplash})`,
          backgroundSize: "cover",
          position: "relative",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "6rem 0 4rem 0",
          zIndex: 0,
        }}
      >
        <h2
          style={{
            fontSize: "3rem",
            alignSelf: "center",
            margin: "0.85em 0",
            fontFamily: "'Exo 2', sans-serif",
            fontWeight: "900",
            color: "#f1f1f1",
            textShadow: "1px 1px black",
            textAlign: "center",
          }}
        >
          Admin Center
        </h2>
      </div>
      <BrowserView>
        <BrowserAdminNavBar
          auth={props.auth}
          renderMain={renderStandardAdmin}
          analytics={props.analytics}
        />
      </BrowserView>
      <MobileView>
        <MobileAdminNavBar
          auth={props.auth}
          renderMain={renderStandardAdmin}
          analytics={props.analytics}
        />
      </MobileView>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  analytics: state.analytics,
});

export default connect(mapStateToProps, { fetchNotifs })(withRouter(Admin));
