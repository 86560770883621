import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import config from "../../config";
import isEmpty from "../../utils/isEmpty";
import { useLocation } from "react-router-dom";
import backSplash from "../../assets/img/searchSplash.png";
import SearchForm from "./SearchForm";
import Bizs from "../Biz/Bizs";
import ToTop from "../common/ToTop";

const SearchResults = (props) => {
  const location = useLocation();
  const params = {};
  const [state, setState] = useState({
    bizs: [],
    query: {
      state: "",
      city: "",
      name: "",
      category: "",
    },
    hasMore: false,
  });

  useEffect(() => {
    if (!isEmpty(location.state)) {
      setState({
        bizs: location.state.list,
        query: location.state.query,
        hasMore: location.state.hasMore,
      });
    }
  }, []);

  const _loadMore = async (pg) => {
    setState({ ...state, hasMore: false });
    const params = {
      ...state.query,
      pg,
    };
    try {
      const res = await axios.get(`${config.apiRoot}/api/biz/search/query`, {
        params,
      });
      setState({
        ...state,
        bizs: [...state.bizs, ...res.data.bizs],
        hasMore: res.data.hasMore,
      });
    } catch (error) {
      const msg = error.response ? error.response.message : error.message;
      console.log("Error: ", msg);
      alert(`Error: ${msg}`);
    }
  };

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <div
        style={{
          backgroundImage: `url(${backSplash})`,
          backgroundSize: "cover",
          position: "relative",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "6rem 0 4rem 0",
          zIndex: 0,
        }}
      >
        <SearchForm update={setState} />
      </div>
      <div
        style={{
          display: "flex",
          justifyItems: "center",
          flexDirection: "column",
        }}
      >
        <h2
          style={{
            fontSize: "2.75rem",
            alignSelf: "center",
            margin: "0.85em 0",
            fontFamily: "'Exo 2', sans-serif",
            fontWeight: "900",
            color: "#4286f7",
            textShadow: "1px 10px 20px rgba(91, 91, 91, 0.7)",
          }}
        >
          Search Results
        </h2>
      </div>
      <Bizs
        location="search"
        bizss={state.bizs}
        hasMore={state.hasMore}
        loadMore={_loadMore}
      />
      <ToTop />
    </div>
  );
};

const mapStateToProps = (state) => ({
  search: state.search,
});

export default connect(mapStateToProps)(SearchResults);
