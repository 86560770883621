import {
  LOGIN_CHANGE_FORM,
  LOGIN_SUBMIT,
  LOGIN_SUBMIT_FAILED,
  LOGIN_SUBMIT_SUCCESS,
  LOGIN_LOGOUT,
} from "../actions/types";
import isEmpty from "../utils/isEmpty";
import { LSAUTH } from "../utils/localStorageKeys";

import axios from "axios";
import config from "../config";

export const changeLoginForm = (field, value) => (dispatch) => {
  dispatch({
    type: LOGIN_CHANGE_FORM,
    payload: { field, value },
  });
};

export const submitLogin = (query, history) => async (dispatch) => {
  dispatch({ type: LOGIN_SUBMIT });
  const url = `${config.apiRoot}/api/users/`;

  const data = {
    email: query.email,
    password: query.password,
  };

  try {
    const res = await axios.put(url, data);
    console.log(res.data);
    window.localStorage.setItem(LSAUTH, JSON.stringify(res.data));
    dispatch({
      type: LOGIN_SUBMIT_SUCCESS,
      payload: res.data,
    });
    if (isEmpty(res.data.profile)) {
      return history.push(`/login/register?email=${data.email}`);
    }
    return history.push("/");
  } catch (error) {
    console.error(error);
    let errors = {};
    if (error.response && error.response.data.errors) {
      error.response.data.errors.forEach((err) => (errors.login = err.msg));
    } else {
      errors = error.response ? error.response.data : error;
    }

    dispatch({
      type: LOGIN_SUBMIT_FAILED,
      payload: errors,
    });
  }
};

export const localLogin = (history, token) => async (dispatch) => {
  try {
    const res = await axios.get(`${config.apiRoot}/api/users/authDetails`, {
      headers: { "x-blb-token": token },
    });
    console.log(res);
    dispatch({
      type: LOGIN_SUBMIT_SUCCESS,
      payload: { ...res.data, token },
    });
  } catch (error) {
    console.error(error);
    let errors = {};
    if (error.response && error.response.data.errors) {
      error.response.data.errors.forEach((err) => (errors.login = err.msg));
    } else {
      errors = error.response ? error.response.data : error;
    }

    history.push("/login");

    dispatch({
      type: LOGIN_SUBMIT_FAILED,
      payload: errors,
    });
  }
};

export const logout = (history) => (dispatch) => {
  dispatch({ type: LOGIN_LOGOUT });
  window.localStorage.removeItem(LSAUTH);
  if (history) {
    history.push("/");
  }
};
