import {
  STATES_FETCH,
  STATES_FETCH_FAIL,
  STATES_FETCH_SUCCESS,
  CITIES_FETCH,
  CITIES_FETCH_FAIL,
  CITIES_FETCH_SUCCESS,
} from "./types";
import axios from "axios";
import config from "../config";

export const fetchStates = () => async (dispatch) => {
  dispatch({ type: STATES_FETCH });
  const url = `${config.apiRoot}/api/state`;
  try {
    const response = await axios.get(url);
    dispatch({ type: STATES_FETCH_SUCCESS, payload: response.data });
  } catch (error) {
    console.error(error);
    dispatch({
      type: STATES_FETCH_FAIL,
      payload: error.response ? error.response.data : error.response,
    });
  }
};

export const fetchCities = (state) => async (dispatch) => {
  dispatch({ type: CITIES_FETCH });
  const url = `${config.apiRoot}/api/state/${state}`;
  try {
    const response = await axios.get(url);
    dispatch({
      type: CITIES_FETCH_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.error(error.message);
    dispatch({
      type: CITIES_FETCH_FAIL,
      payload: error.response ? error.response.data : error.response,
    });
  }
};
