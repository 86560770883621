import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import backSplash from "../../assets/img/searchSplash.png";
import axios from "axios";
import config from "../../config";

const Unsubscribe = (props) => {
  useEffect(() => {
    const asyncUnsubscribe = async () => {
      const url = `${config.apiRoot}/api/unsubscribe`;
      try {
        await axios.post(url, { email: queries.email });
      } catch (error) {
        console.log(error);
      }
    };

    const queries = queryString.parse(window.location.search);
    if (queries.email) {
      asyncUnsubscribe();
    }
  }, []);

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <div
        style={{
          backgroundImage: `url(${backSplash})`,
          backgroundSize: "cover",
          position: "relative",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "6rem 0 4rem 0",
          zIndex: 0,
          maxHeight: "20vh",
        }}
      >
        <h1
          className={"font-primary"}
          style={{
            fontSize: "3rem",
            alignSelf: "center",
            margin: "0.85em 0",
            fontWeight: "900",
            color: "#f1f1f1",
            textShadow: "1px 1px black",
            textAlign: "center",
            maxWidth: "90vw",
          }}
        >
          Unsubscribe From Email Communication
        </h1>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="font-primary"
          style={{
            margin: "1rem",
            maxWidth: "800px",
            width: "100%",
            fontSize: "1rem",
            backgroundColor: "rgba(100, 225, 100, 0.2)",
          }}
        >
          <p>
            You've been unsubscribed from BLB emails. We will no longer send you
            automated messages. Please note that if you do not have an account
            with BLB you can no longer submit new businesses.
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  button: {
    width: "auto",
    height: 42,
    paddingRight: 20,
    paddingLeft: 20,
    margin: "0px",
    borderRadius: "5px",
    fontSize: 18,
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
  },
};

export default withRouter(Unsubscribe);
