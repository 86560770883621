import React, { Component } from "react";
import { connect } from "react-redux";
import {
  newBizUpdate,
  adminUploadBiz,
  getFormData,
  fetchCities,
  AdminImgUpload,
} from "../../actions";
import { withRouter } from "react-router-dom";
import Button from "../common/Button";
import Form from "../common/Form";
import Input from "../common/Input";

const hours = [
  { value: 0, display: "12 AM" },
  { value: 1, display: "1 AM" },
  { value: 2, display: "2 AM" },
  { value: 3, display: "3 AM" },
  { value: 4, display: "4 AM" },
  { value: 5, display: "5 AM" },
  { value: 6, display: "6 AM" },
  { value: 7, display: "7 AM" },
  { value: 8, display: "8 AM" },
  { value: 9, display: "9 AM" },
  { value: 10, display: "10 AM" },
  { value: 11, display: "11 AM" },
  { value: 12, display: "12 PM" },
  { value: 13, display: "1 PM" },
  { value: 14, display: "2 PM" },
  { value: 15, display: "3 PM" },
  { value: 16, display: "4 PM" },
  { value: 17, display: "5 PM" },
  { value: 18, display: "6 PM" },
  { value: 19, display: "7 PM" },
  { value: 20, display: "8 PM" },
  { value: 21, display: "9 PM" },
  { value: 22, display: "10 PM" },
  { value: 23, display: "11 PM" },
];
const bizTypes = ["Incorporated", "Non-Profit", "Self-Employed", "Contractor"];
const ethnicities = [
  "Black (African Decent)",
  "White (European Decent)",
  "Native American or Alaskan Native",
  "Hispanic or Latino",
  "Middle Eastern",
  "Asian",
  "Mixed",
  "Other",
];

class AdminBizForm extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef;
    this.state = {
      file: null,
    };
  }

  photoChange = (e) => {
    this.setState({
      file: e.target.files[0],
    });
  };

  componentDidMount() {
    this.props.getFormData();
  }

  handleSubmit = () => {
    if (this.props.newBiz.id) {
      this.props.AdminImgUpload(
        this.props.newBiz.id,
        this.state.file,
        this.props.history
      );
    } else {
      this.props.adminUploadBiz(
        this.props.auth.token,
        this.props.newBiz.query,
        this.state.file,
        this.props.history
      );
    }
  };

  handleChange = (e) => {
    this.props.newBizUpdate(e.target.id, e.target.value);
  };

  handleStateChange = (e) => {
    this.props.newBizUpdate(e.target.id, e.target.value);
    this.props.newBizUpdate("city", "");
    if (e.target.value !== "" && e.target.value !== null)
      this.props.fetchCities(e.target.value);
  };

  renderOptions = (collection) => {
    return collection.map((item) => (
      <option value={item} key={item}>
        {item}
      </option>
    ));
  };

  renderHours = () => {
    return hours.map((hour) => (
      <option value={hour.value} key={hour.value}>
        {hour.display}
      </option>
    ));
  };

  renderStates = () => {
    return this.props.st.states.map((st) => (
      <option value={st.abbr} key={st.abbr}>
        {st.abbr}
      </option>
    ));
  };

  renderTopCities = () => {
    return this.props.st.cities === null
      ? null
      : this.props.st.cities.counted.slice(0, 20).map((city) => (
          <option value={city.name} key={city._id}>
            {city.name} ({city.count})
          </option>
        ));
  };

  renderCities = () => {
    return this.props.st.cities === null
      ? null
      : this.props.st.cities.names.map((city) => (
          <option value={city} key={city}>
            {city}
          </option>
        ));
  };

  renderCategories = () => {
    return this.props.cat.list.map((cat) => (
      <option value={cat.name} key={cat._id}>
        {cat.name}
      </option>
    ));
  };

  render() {
    const { query, errors, submitting, id } = this.props.newBiz;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className={"font-primary"}
          style={{
            margin: "1rem 1rem 0rem 1rem",
            maxWidth: "800px",
            width: "90%",
            fontSize: "1rem",
          }}
        >
          <p>
            Note to team members and leads. In order to complete the process you
            must upload an image of the business.
          </p>
        </div>
        <Form
          onSubmit={this.handleSubmit}
          style={{
            margin: "1.3rem 1rem 2rem 1rem",
            maxWidth: "800px",
            width: "100%",
          }}
        >
          <div style={styles.inlineFields}>
            <Input
              style={styles.input}
              type="text"
              id="name"
              placeholder="Business Name"
              onChange={this.handleChange}
              value={query.name}
              disabled={id !== null}
            />
          </div>
          {errors.name ? <p style={styles.error}>{errors.name}</p> : null}
          <div style={styles.inlineFields}>
            <textarea
              style={styles.textArea}
              placeholder="Short description about the business"
              id="about"
              value={query.about}
              onChange={this.handleChange}
              rows="3"
              disabled={id !== null}
            />
          </div>
          <div style={styles.inlineFields}>
            <Input
              style={styles.input}
              type="number"
              id="street_num"
              placeholder="Street Number"
              onChange={this.handleChange}
              value={query.street_num}
              disabled={id !== null}
            />
            <Input
              style={{ ...styles.input, flex: 3 }}
              id="street_name"
              placeholder="Street Name"
              onChange={this.handleChange}
              value={query.street_name}
              disabled={id !== null}
            />
          </div>
          {errors.street ? <p style={styles.error}>{errors.street}</p> : null}
          <div style={{ ...styles.inlineFields, flexWrap: "wrap" }}>
            <select
              style={{
                ...styles.selectInput,
                color: query.state === "" ? "gray" : "black",
              }}
              id="state"
              onChange={this.handleStateChange}
              value={query.state}
              disabled={id !== null}
            >
              <option value="" disabled>
                State
              </option>
              {this.renderStates()}
            </select>
            <select
              style={{
                ...styles.selectInput,
                color: query.city === "" ? "gray" : "black",
              }}
              id="city"
              onChange={this.handleChange}
              value={query.city}
              disabled={id !== null}
            >
              <option value="" disabled>
                {this.props.st.cities === null
                  ? "<<<"
                  : `${query.state} Cities`}
              </option>
              <optgroup label="Top 20 Cities">
                {this.renderTopCities()}
              </optgroup>
              <optgroup label="Alphabetical Listing">
                {this.renderCities()}
              </optgroup>
            </select>
            <Input
              style={styles.input}
              type="number"
              id="zipcode"
              placeholder="Zipcode"
              onChange={this.handleChange}
              value={query.zipcode}
              disabled={id !== null}
            />
          </div>
          {errors.address ? <p style={styles.error}>{errors.address}</p> : null}
          <div style={styles.inlineFields}>
            <select
              style={{
                ...styles.selectInput,
                color: query.category === "" ? "gray" : "black",
              }}
              id="category"
              onChange={this.handleChange}
              value={query.category}
              disabled={id !== null}
            >
              <option value="" disabled>
                Category
              </option>
              {this.renderCategories()}
            </select>
          </div>
          {errors.category ? (
            <p style={styles.error}>{errors.category}</p>
          ) : null}
          <div style={styles.inlineFields}>
            <select
              style={{
                ...styles.selectInput,
                color: query.registered === "" ? "gray" : "black",
              }}
              id="registered"
              onChange={this.handleChange}
              value={query.registered}
              disabled={id !== null}
            >
              <option value="" disabled>
                Is the business registered?
              </option>
              <option value="F">No</option>
              <option value="T">Yes</option>
            </select>
          </div>
          <div style={styles.inlineFields}>
            <select
              style={{
                ...styles.selectInput,
                color: query.business_type === "" ? "gray" : "black",
              }}
              id="business_type"
              onChange={this.handleChange}
              value={query.business_type}
              disabled={id !== null}
            >
              <option value="" disabled>
                Business Type
              </option>
              {this.renderOptions(bizTypes)}
            </select>
          </div>
          <div style={styles.inlineFields}>
            <select
              style={{
                ...styles.selectInput,
                color: query.open_hour === "" ? "gray" : "black",
              }}
              id="open_hour"
              onChange={this.handleChange}
              value={query.open_hour}
              disabled={id !== null}
            >
              <option value="" disabled>
                Opening Hour
              </option>
              {this.renderHours()}
            </select>
            <select
              style={{
                ...styles.selectInput,
                color: query.close_hour === "" ? "gray" : "black",
              }}
              id="close_hour"
              onChange={this.handleChange}
              value={query.close_hour}
              disabled={id !== null}
            >
              <option value="" disabled>
                Closing Hour
              </option>
              {this.renderHours()}
            </select>
          </div>
          {errors.hour ? <p style={styles.error}>{errors.hour}</p> : null}
          <div style={styles.inlineFields}>
            <Input
              style={{ ...styles.input, flex: 2 }}
              id="owner_phone"
              type="tel"
              placeholder="Business' Phone"
              onChange={this.handleChange}
              value={query.owner_phone}
              disabled={id !== null}
            />
            <Input
              style={styles.input}
              id="years"
              type="number"
              placeholder="How many years old?"
              onChange={this.handleChange}
              value={query.years}
              disabled={id !== null}
            />
          </div>
          {errors.phoneYears ? (
            <p style={styles.error}>{errors.phoneYears}</p>
          ) : null}
          <div style={styles.inlineFields}>
            <Input
              style={styles.input}
              type="text"
              id="business_web"
              placeholder="Business' Website"
              onChange={this.handleChange}
              value={query.business_web}
              disabled={id !== null}
            />
          </div>
          {errors.business_web ? (
            <p style={styles.error}>{errors.business_web}</p>
          ) : null}
          <div style={styles.inlineFields}>
            <Input
              style={styles.input}
              id="twitter"
              type="text"
              placeholder="Business' Twitter Account @handle"
              onChange={this.handleChange}
              value={query.twitter}
              disabled={id !== null}
            />
          </div>
          {errors.twitter ? <p style={styles.error}>{errors.twitter}</p> : null}
          <div style={styles.inlineFields}>
            <Input
              style={styles.input}
              id="instagram"
              type="text"
              placeholder="Business' Instagram Account Name"
              onChange={this.handleChange}
              value={query.instagram}
              disabled={id !== null}
            />
          </div>
          {errors.instagram ? (
            <p style={styles.error}>{errors.instagram}</p>
          ) : null}
          <div style={styles.inlineFields}>
            <Input
              style={styles.input}
              id="facebook"
              type="text"
              placeholder="Business' Facebook Url"
              onChange={this.handleChange}
              value={query.facebook}
              disabled={id !== null}
            />
          </div>
          {errors.facebook ? (
            <p style={styles.error}>{errors.facebook}</p>
          ) : null}
          <div style={styles.inlineFields}>
            <Input
              style={styles.input}
              id="owner_first_name"
              placeholder="Owner First Name"
              onChange={this.handleChange}
              error={errors.owner_first_name ? errors.owner_first_name : false}
              value={query.owner_first_name}
              disabled={id !== null}
            />
            <Input
              style={styles.input}
              id="owner_last_name"
              placeholder="Owner Last Name"
              onChange={this.handleChange}
              error={errors.owner_last_name ? errors.owner_last_name : false}
              value={query.owner_last_name}
              disabled={id !== null}
            />
          </div>
          <div style={styles.inlineFields}>
            <select
              style={{
                ...styles.selectInput,
                color: query.owner_ethn === "" ? "gray" : "black",
              }}
              id="owner_ethn"
              onChange={this.handleChange}
              value={query.owner_ethn}
              disabled={id !== null}
            >
              <option value="" disabled>
                Owners Ethnicity
              </option>
              {this.renderOptions(ethnicities)}
            </select>
          </div>
          {errors.owner_ethn ? (
            <p style={styles.error}>{errors.owner_ethn}</p>
          ) : null}
          <div style={styles.inlineFields}>
            <Input
              type="email"
              style={styles.input}
              id="owner_email"
              placeholder="Owner E-mail"
              onChange={this.handleChange}
              value={query.owner_email}
              disabled={id !== null}
            />
          </div>
          {errors.owner_email ? (
            <p style={styles.error}>{errors.owner_email}</p>
          ) : null}
          <div style={styles.inlineFields}>
            <Input
              style={styles.input}
              id="owner_linkedin"
              type="text"
              placeholder="Owner's LinkedIn Url"
              onChange={this.handleChange}
              value={query.owner_linkedin}
              disabled={id !== null}
            />
          </div>
          {errors.owner_linkedin ? (
            <p style={styles.error}>{errors.owner_linkedin}</p>
          ) : null}

          <div style={styles.inlineFields}>
            <select
              style={{
                ...styles.selectInput,
                color: query.requestedLogo === "" ? "gray" : "black",
              }}
              id="requestedLogo"
              onChange={this.handleChange}
              value={query.requestedLogo}
              disabled={id !== null}
            >
              <option value="" disabled>
                Would the business like a $50 logo created ? ($200 value)
              </option>
              <option value="F">No</option>
              <option value="T">Yes</option>
            </select>
          </div>
          <p>
            Enter url safe business name. Will appear as
            www.BlackLivesBiz.com/businessname
          </p>
          <div style={styles.inlineFields}>
            <Input
              style={styles.input}
              id="personalUrl"
              type="text"
              placeholder="Business unique url"
              onChange={this.handleChange}
              error={errors.personalUrl ? errors.personalUrl : false}
              value={query.personalUrl}
              disabled={id !== null}
            />
          </div>
          {errors.personalUrl ? (
            <p style={styles.error}>{errors.personalUrl}</p>
          ) : null}
          <p>
            Select a photo for the business. For best results select a photo:
          </p>
          <ul>
            <li>in landscape orientation</li>
            <li>with a 16:9 resolution ratio</li>
            <li>at least 500 pixels wide</li>
          </ul>
          <div
            style={styles.inlineFields}
            hidden={!this.props.newBiz.validated}
          >
            <input
              style={{ ...styles.input2, ...styles.input }}
              type="file"
              id="photo"
              accept="image/png, image/jpeg"
              ref={this.fileInput}
              onChange={this.photoChange}
              required
            />
          </div>
          {errors.photo ? (
            <p style={styles.error}>
              Only image failed rest of form locked: {errors.photo}
            </p>
          ) : null}
          <Button
            disabled={submitting}
            style={{
              backgroundColor: "#4286f7",
              color: "#f1f1f1",
              border: "none",
            }}
          >
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}

const styles = {
  inlineFields: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minWidth: 400,
    borderRadius: 5,
    overflow: "hidden",
    flexWrap: "wrap",
  },
  input: {
    border: "1px solid #d9d9d9",
    paddingRight: 5,
    paddingLeft: 10,
    width: "100%",
    minWidth: 250,
    margin: 0,
    height: 30,
    borderRadius: 5,
    flex: 1,
    fontFamily:
      '"Exo 2", -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  },
  input2: {
    color: "#000",
    backgroundColor: "#eee",
    fontSize: 18,
    padding: 5,
  },
  selectInput: {
    color: "#000",
    backgroundColor: "#eee",
    fontSize: 18,
    // lineHeight: 23,
    border: "1px solid #d9d9d9",
    padding: "5px 5px 5px 10px",
    width: "100%",
    minWidth: 250,
    height: 42,
    margin: 0,
    borderRadius: "5px",
    flex: 1,
  },
  textArea: {
    border: "1px solid #d9d9d9",
    padding: "5px 5px 5px 10px",
    fontFamily:
      '"Exo 2", -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    // paddingRight: 5,
    // paddingLeft: 10,
    width: "100%",
    minWidth: 250,
    margin: 0,
    minHeight: 30,
    borderRadius: 5,
    flex: 1,
    backgroundColor: "#eee",
    fontSize: 18,
  },
  button: {
    width: "auto",
    height: 42,
    paddingRight: 10,
    paddingLeft: 10,
    margin: "0px",
    borderRadius: "5px",
    fontSize: 18,
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
  },
  error: {
    color: "red",
    fontWeight: "bold",
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 3,
    paddingLeft: 5,
  },
};

const mapStateToProps = (state) => ({
  newBiz: state.newBiz,
  st: state.st,
  cat: state.categories,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  newBizUpdate,
  adminUploadBiz,
  getFormData,
  fetchCities,
  AdminImgUpload,
})(withRouter(AdminBizForm));
