import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { submitCode, codeUpdate, uploadImg, newBizLink } from "../../actions";
import Input from "../common/Input";
import Form from "../common/Form";
import Button from "../common/Button";
import queryString from "query-string";

class Validate extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef;
    this.state = {
      file: null,
      exampleDis: false,
    };
  }

  componentDidMount = () => {
    const queries = queryString.parse(window.location.search);
    if (queries.id && queries.code) {
      this.props.newBizLink(queries.id, queries.code);
    }
  };

  handleSubmit = () => {
    this.props.submitCode(
      this.props.newBiz.id,
      this.props.newBiz.code,
      this.props.history
    );
  };

  handleChange = (e) => {
    this.props.codeUpdate(e.target.id, e.target.value);
  };

  render() {
    const { code, errors, submitting } = this.props.newBiz;
    // console.log(this.props.newBiz.photo.name);
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className={"font-primary"}
          style={{
            margin: "1rem 1rem 0rem 1rem",
            maxWidth: "800px",
            width: "100%",
            fontSize: "1rem",
          }}
        >
          <p className={"font-primary"}>
            Thank you for validating your business. This will help to speed up
            your approval process. Simply click the submit button to finish the
            validation.
          </p>
        </div>
        <Form
          onSubmit={this.handleSubmit}
          style={{
            margin: "1.3rem 1rem 2rem 1rem",
            maxWidth: "800px",
            width: "100%",
          }}
        >
          <div style={styles.inlineFields}>
            <Input
              style={{ ...styles.input, border: "1px solid #4286f7" }}
              type="text"
              id="code"
              placeholder="Validation Code (Required)"
              onChange={this.handleChange}
              value={code}
              required
            />
          </div>
          {errors.code ? <p style={styles.error}>{errors.code}</p> : null}
          <Button disabled={submitting}>Submit</Button>
        </Form>
      </div>
    );
  }
}

const styles = {
  inlineFields: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minWidth: 400,
    borderRadius: 5,
    overflow: "hidden",
    flexWrap: "wrap",
  },
  input: {
    border: "1px solid #d9d9d9",
    paddingRight: 5,
    paddingLeft: 10,
    width: "100%",
    minWidth: 250,
    margin: 0,
    height: 30,
    borderRadius: 5,
    flex: 1,
  },
  input2: {
    color: "#000",
    backgroundColor: "#eee",
    fontSize: 18,
    padding: 5,
  },
  selectInput: {
    color: "#000",
    backgroundColor: "#eee",
    fontSize: 18,
    border: "1px solid #d9d9d9",
    padding: "5px 5px 5px 10px",
    width: "100%",
    minWidth: 250,
    height: 42,
    margin: 0,
    borderRadius: "5px",
    flex: 1,
  },
  button: {
    width: "auto",
    height: 42,
    paddingRight: 10,
    paddingLeft: 10,
    margin: "0px",
    borderRadius: "5px",
    fontSize: 18,
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
  },
  error: {
    color: "red",
    fontWeight: "bold",
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 3,
    paddingLeft: 5,
  },
  exampleDisBtn: {
    width: "auto",
    height: 42,
    paddingRight: 10,
    paddingLeft: 10,
    margin: "0px",
    borderRadius: "5px",
    border: "none",
    fontSize: 18,
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
  },
};

const mapStateToProps = (state) => ({
  newBiz: state.newBiz,
});

export default connect(mapStateToProps, {
  codeUpdate,
  submitCode,
  uploadImg,
  newBizLink,
})(withRouter(Validate));
