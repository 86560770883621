import {
  SEARCH_SUBMIT,
  SEARCH_SUBMIT_FAILED,
  SEARCH_SUBMIT_SUCCESS,
  SEARCH_UPDATE,
  SEARCH_LIVE_UPDATE,
  SEARCH_SUPPLEMENT,
  SEARCH_SUPPLEMENT_FAIL,
  SEARCH_SUPPLEMENT_SUCCESS,
  CITIES_FETCH,
  SEARCH_DEFAULT,
  SEARCH_LOAD_MORE,
  SEARCH_LOAD_MORE_FAIL,
  SEARCH_LOAD_MORE_SUCCESS,
} from "../actions/types";

const INITIAL_STATE = {
  query: {
    state: "",
    city: "",
    name: "",
    category: "",
  },
  results: [],
  filteredResults: [],
  liveSearch: "",
  loading: false,
  errors: {},
  fetchingMore: false,
  hasMore: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_UPDATE:
      return {
        ...state,
        query: {
          ...state.query,
          [action.payload.field]: action.payload.value,
        },
      };
    case SEARCH_SUBMIT:
      return {
        ...state,
        loading: true,
        errors: {},
      };
    case SEARCH_SUBMIT_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case SEARCH_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        results: action.payload.bizs,
        filteredResults: action.payload.bizs,
        liveSearch: state.query.name,
        hasMore: action.payload.hasMore,
      };
    case SEARCH_LOAD_MORE:
      return {
        ...state,
        hasMore: false,
        errors: {},
      };
    case SEARCH_LOAD_MORE_FAIL:
      return {
        ...state,
        errors: action.payload,
      };
    case SEARCH_LOAD_MORE_SUCCESS:
      return {
        ...state,
        filteredResults: [...state.filteredResults, ...action.payload.bizs],
        hasMore: action.payload.hasMore,
      };
    case SEARCH_LIVE_UPDATE:
      return {
        ...state,
        filteredResults: action.payload,
      };
    case SEARCH_SUPPLEMENT:
      return {
        ...state,
        fetchingMore: true,
        errors: {},
      };
    case SEARCH_SUPPLEMENT_FAIL:
      return {
        ...state,
        fetchingMore: false,
        errors: action.payload,
      };
    case SEARCH_SUPPLEMENT_SUCCESS:
      return {
        ...state,
        fetchingMore: false,
        results: action.payload,
      };
    case CITIES_FETCH:
      return {
        ...state,
        query: {
          ...state.query,
          city: "",
        },
      };
    case SEARCH_DEFAULT:
      return {
        ...state,
        query: {
          ...state.query,
          city: action.payload.city,
          state: action.payload.state,
        },
      };
    default:
      return state;
  }
};
