import {
  SUPPORTER_FETCH,
  SUPPORTER_FETCH_FAIL,
  SUPPORTER_FETCH_SUCCESS,
  SUPPORTER_CHANGE_FORM,
  SUPPORTER_SUBMIT,
  SUPPORTER_SUBMIT_FAIL,
  SUPPORTER_SUBMIT_SUCCESS,
} from "../actions/types";

const INITIAL_STATE = {
  query: {
    email: "",
    firstname: "",
    lastname: "",
    zipcode: "",
    state: "",
    city: "",
  },
  list: [],
  loading: false,
  submitting: false,
  errors: {},
  fetchErrors: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUPPORTER_CHANGE_FORM: {
      return {
        ...state,
        query: {
          ...state.query,
          [action.payload.field]: action.payload.value,
        },
      };
    }
    case SUPPORTER_FETCH:
      return {
        ...state,
        loading: true,
        fetchErrors: {},
      };
    case SUPPORTER_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        fetchErrors: action.payload,
      };
    case SUPPORTER_FETCH_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case SUPPORTER_SUBMIT:
      return {
        ...state,
        submitting: true,
        errors: {},
      };
    case SUPPORTER_SUBMIT_FAIL:
      return {
        ...state,
        submitting: false,
        errors: action.payload,
      };

    case SUPPORTER_SUBMIT_SUCCESS:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
