import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BlackArtist from "./BlackArtist";
import Foreward from "./Foreward";
import axios from "axios";
import config from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsThinContext } from "../../../contexts/IsThinContext";
import InlineInputContainer from "../../common/InlineInputContainer";

const roseWeaver = "https://storage.googleapis.com/photos-blb/RoseWeaver.jpg";

const categories = [
  "Actress",
  "Educator",
  "Singer",
  "Writer",
  "Music",
  "Voice",
  "Performance",
  "Storyteller",
  "Producer",
  "Theatre Performer",
  "Graphic Design",
  "Mystic",
  "Spoken Word",
  "Art History",
  "Author",
  "Literature",
  "Teacher",
  "Standup Comedian",
  "Interdisciplinary",
  "Artist",
  "Theatre",
  "Humanities",
  "Media",
  "Multi-disciplinary",
  "Historian",
  "Content Production",
  "Crafts",
  "Vocalist",
  "Communications",
  "Dance",
  "Designer",
].sort();

const BlackArtists = (props) => {
  const [showForeward, setShowForeward] = useState(false);
  const [artists, setArtists] = useState([]);
  const [filteredArtists, setFilteredArtists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const [adminEditAccess, setEA] = useState("false");
  const { auth } = props;
  const pageUrl = window.location.toString();
  const baseUrl = window.location.origin + window.location.pathname;
  const isThin = useIsThinContext();

  const styles = {
    selectInput: {
      color: "#000",
      backgroundColor: "#eee",
      fontSize: isThin ? 15 : 18,
      // lineHeight: 23,
      border: "1px solid #d9d9d9",
      padding: "5px 5px 5px 10px",
      width: "100%",
      height: 42,
      margin: 0,
      borderRadius: "5px",
      flex: 1,
    },
    inputContainer: {
      minWidth: "100%",
    },
    artistsListDiv: {
      paddingRight: isThin ? "3%" : "10%",
      paddingLeft: isThin ? "3%" : "10%",
    },
  };

  useEffect(() => {
    setEA(
      auth.isAdmin &&
        (auth.adminLevel === 4 ||
          (auth.adminType === "EXCLUSIVE" && auth.adminMeta === "riartist"))
    );
  }, [auth]);

  useEffect(() => {
    const _asyncFetchArtists = async () => {
      try {
        const res = await axios.get(`${config.apiRoot}/api/blackArtists/ri`);
        setArtists(res.data);
        setFilteredArtists(res.data);
        setLoading(false);
      } catch (err) {
        alert(`Server error: ${err.data ? err.data.message : err.message}`);
      }
    };
    setLoading(true);
    _asyncFetchArtists();
  }, [setLoading]);

  const renderFilters = () => {
    return categories.map((category) => (
      <option value={category} key={category}>
        {category}
      </option>
    ));
  };

  const renderArtists = () => {
    if (loading) {
      return (
        <FontAwesomeIcon
          icon={["fas", "spinner"]}
          spin
          style={{ fontSize: 20, margin: "0 auto" }}
        />
      );
    } else {
      return filteredArtists.map((artist) => (
        <BlackArtist
          artist={artist}
          pageUrl={pageUrl}
          baseUrl={baseUrl}
          isThin={isThin}
          key={artist._id}
          adminEditAccess={adminEditAccess}
          adminEditRoute={handleAdminEditRoute}
          isOwner={
            props.auth.profile
              ? props.auth.profile.isOwner
                ? props.auth.profile.bizIds.includes(artist._id)
                : false
              : false
          }
        />
      ));
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setFilter(value);
    if (value === "") {
      return setFilteredArtists(artists);
    }
    setFilteredArtists(
      artists.filter((artist) => {
        return artist.category.indexOf(value) >= 0;
      })
    );
  };

  const handleAdminEditRoute = (id) => {
    props.history.push(`/blackArtists/adminEdit/edit/${id}`);
  };

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${roseWeaver})`,
          backgroundSize: "cover",
          height: "65vh",
          display: "flex",
          position: "relative",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 0,
        }}
      >
        <div
          style={{
            alignItems: "center",
            maxWidth: 1000,
            width: "85%",
            justifyContent: "center",
            color: "white",
          }}
        >
          <h1
            style={{
              fontWeight: "200",
              fontSize: "3em",
              margin: 0,
            }}
          >
            <span
              style={{
                backgroundColor: "black",
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              ROSE WEAVER'S
            </span>
          </h1>
          <h1
            style={{
              fontWeight: "200",
              fontSize: "3em",
              margin: 0,
            }}
          >
            <span
              style={{
                backgroundColor: "black",
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              <strong>DIRECTORY</strong> OF <strong>BLACK ARTISTS</strong>
            </span>
          </h1>
          <h1
            style={{
              fontWeight: "200",
              fontSize: "3em",
              margin: 0,
            }}
          >
            <span
              style={{
                backgroundColor: "black",
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              IN <strong>RHODE ISLAND</strong>
            </span>
          </h1>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1",
          justifyContent: "space-evenly",
          alignItems: "center",
          backgroundColor: "#FFF",
          ...styles.artistsListDiv,
        }}
      >
        <h1 style={{ fontWeight: "bold", fontSize: "2.6em" }}>FORWARD</h1>
        {showForeward ? <Foreward /> : null}
        <p
          style={{
            padding: 6,
            fontSize: "1em",
            cursor: "pointer",
            marginBottom: 15,
          }}
          onClick={() => setShowForeward(!showForeward)}
        >
          {showForeward
            ? "^ Click to Hide Foreward"
            : "> Click to Read Foreward"}
        </p>
        <div
          style={{
            display: "flex",
            justifyItems: "center",
            flexDirection: "column",
            maxWidth: 500,
            margin: 10,
            width: "100%",
          }}
        >
          <InlineInputContainer style={{ ...styles.inputContainer }}>
            <select
              style={{
                ...styles.selectInput,
                color: filter === "" ? "gray" : "black",
              }}
              onChange={handleChange}
              value={filter}
            >
              <option value="">Filter Artists</option>
              {renderFilters()}
            </select>
          </InlineInputContainer>
        </div>
        {renderArtists()}
      </div>
    </div>
  );
};

export default connect((state) => ({ auth: state.auth }))(
  withRouter(BlackArtists)
);
