import React, { Component } from "react";
import Logo from "../common/Logo";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

class Navbar extends Component {
  state = {
    display: false,
    hover: false,
  };

  onMouseEnterNavBtn = (e) => {
    e.target.style.opacity = "70%";
    this.setState({
      hover: true,
    });
  };

  onMouseLeaveNavBtn = (e) => {
    e.target.style.opacity = "100%";
    this.setState({
      hover: false,
    });
  };

  handleClick = (e) => {
    // if (e.target.className.includes('nav-link')) {
    //   e.target.style.color = '#4286f7';
    // }
    this.setState({
      display: !this.state.display,
    });
  };

  onMouseEnterNavLink = (e) => {
    e.target.style.transform = "translate(.5em, 0)";
  };
  onMouseLeaveNavLink = (e) => {
    e.target.style.transform = "translate(0, 0)";
  };

  render() {
    const {
      navbarOn,
      loginLink,
      logoImageLink,
      adminLink,
      donateLink,
      supportLink,
    } = this.props;
    // DEBUG:
    //   console.log("Navbar On:", navbarOn);
    return navbarOn ? (
      <nav
        style={{
          ...this.props.style,
          height: "100vh",
          pointerEvents: this.state.display ? "auto" : "none",
          background: this.state.display ? "rgba(0,0,0,.9)" : "rgba(0,0,0,0)",
          transition: "background ease-in-out 1s",
        }}
      >
        <div style={{ ...styles.navBarWrapper }}>
          <div style={{ flex: 1 }}>
            <NavLink style={{ ...styles.logoNavLink }} to="/">
              <Logo link={logoImageLink} height={"5em"} />
            </NavLink>
          </div>
          <div
            style={{
              ...styles.navBtn,
              backgroundColor: this.state.display ? "#f78055" : "#4286f7",
              borderRadius: this.state.display
                ? "0px 10px 0px 10px"
                : "10px 0px 10px 0px",
              pointer: "cursor",
              pointerEvents: "auto",
            }}
            onClick={this.handleClick}
            onMouseEnter={this.onMouseEnterNavBtn}
            onMouseLeave={this.onMouseLeaveNavBtn}
          >
            <div
              className={"navBtnLine"}
              id={"navBtnLineTop"}
              style={{
                ...styles.navBtnLine,
                transform: this.state.display
                  ? "translate(0%, 500%) rotate(45deg)"
                  : "translate(0%, 150%)",
                posistion: "absolute",
              }}
            />
            <div
              className={"navBtnLine"}
              id={"navBtnLineMid"}
              style={{
                ...styles.navBtnLine,
                backgroundColor: this.state.display ? "#ffffff00" : "#ffffff",
                transform: "translate(0%, 400%)",
              }}
            />
            <div
              className={"navBtnLine"}
              id={"navBtnLineBot"}
              style={{
                ...styles.navBtnLine,
                transform: this.state.display
                  ? "translate(0%, 300%) rotate(-45deg)"
                  : "translate(0%, 650%)",
              }}
              onClick={this.handleClick}
            />
          </div>
        </div>
        <div
          style={{
            justifyContent: "flex-end",
            display: "flex",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              ...styles.navBtnDiv,
              transform: this.state.display
                ? "translate(0%,0%)"
                : "translate(100%,0%)",
              transition: this.state.display
                ? "transform 1s cubic-bezier(0.16, 0.98, 1, 1) -0.5s"
                : "transform 3s cubic-bezier(.39,-0.21,.37,.9) 0s",
            }}
          >
            <NavLink
              className={"nav-link font-primary"}
              to="/"
              style={{ ...styles.standard }}
              onMouseEnter={this.onMouseEnterNavLink}
              onMouseLeave={this.onMouseLeaveNavLink}
              onClick={this.handleClick}
            >
              Home
            </NavLink>
            {this.props.auth.isAdmin ? null : (
              <NavLink
                className={"nav-link font-primary"}
                to="/newBiz"
                style={styles.standard}
                onMouseEnter={this.onMouseEnterNavLink}
                onMouseLeave={this.onMouseLeaveNavLink}
                onClick={this.handleClick}
              >
                Add Biz
              </NavLink>
            )}
            <NavLink
              className={"nav-link font-primary"}
              to={loginLink}
              style={styles.standard}
              onMouseEnter={this.onMouseEnterNavLink}
              onMouseLeave={this.onMouseLeaveNavLink}
              onClick={this.handleClick}
            >
              Team
            </NavLink>
            {this.props.auth.token ? (
              <NavLink
                className={"nav-link font-primary"}
                to={"/"}
                onClick={() => {
                  this.handleClick();
                  this.props.onLogout();
                }}
                style={styles.standard}
                onMouseEnter={this.onMouseEnterNavLink}
                onMouseLeave={this.onMouseLeaveNavLink}
              >
                Logout
              </NavLink>
            ) : (
              <NavLink
                className={"nav-link font-primary"}
                to={loginLink}
                style={styles.standard}
                onMouseEnter={this.onMouseEnterNavLink}
                onMouseLeave={this.onMouseLeaveNavLink}
                onClick={this.handleClick}
              >
                Login
              </NavLink>
            )}
            <NavLink
              className={"nav-link font-primary"}
              to={loginLink}
              style={styles.standard}
              onMouseEnter={this.onMouseEnterNavLink}
              onMouseLeave={this.onMouseLeaveNavLink}
              onClick={this.handleClick}
            >
              News
            </NavLink>
            <NavLink
              className={"nav-link font-primary"}
              to="/blackArtists/ri"
              style={styles.standard}
              onMouseEnter={this.onMouseEnterNavLink}
              onMouseLeave={this.onMouseLeaveNavLink}
              onClick={this.handleClick}
            >
              Artists
            </NavLink>
            <NavLink
              className={"nav-link font-primary"}
              to={donateLink}
              style={styles.standard}
              onMouseEnter={this.onMouseEnterNavLink}
              onMouseLeave={this.onMouseLeaveNavLink}
              onClick={this.handleClick}
            >
              Donate
            </NavLink>
            <NavLink //Supporters
              className={"nav-link font-primary"}
              to={supportLink}
              style={styles.standard}
              onMouseEnter={this.onMouseEnterNavLink}
              onMouseLeave={this.onMouseLeaveNavLink}
              onClick={this.handleClick}
            >
              Supporters
            </NavLink>
            {this.props.auth.isAdmin ? (
              <NavLink
                className={"nav-link font-primary"}
                to={adminLink}
                style={styles.standard}
                onMouseEnter={this.onMouseEnterNavLink}
                onMouseLeave={this.onMouseLeaveNavLink}
                onClick={this.handleClick}
              >
                Admin
              </NavLink>
            ) : null}
          </div>
        </div>
      </nav>
    ) : null;
  }
}

const styles = {
  standard: {
    backgroundColor: "#00000000",
    textDecoration: "none",
    fontFamily: "'Exo 2', sans-serif",
    fontSize: "1.5em",
    color: "#f1f1f1",
    fontWeight: 600,
    width: "100rem",
    textShadow: "1px 1px black",
    borderRadius: "20px 0px 0px 20px",
    transition: "all ease-in-out .5s",
    textAlign: "right",
    paddingRight: 0,
  },
  navBtn: {
    fontSize: "3rem",
    backgroundColor: "#4286f7",
    margin: "0 5vw 0 0",
    padding: 5,
    width: "3rem",
    height: "3rem",
    cursor: "pointer",
    transition: "all ease-in-out .5s",
  },
  navBtnLine: {
    backgroundColor: "white",
    height: ".09em",
    borderRadius: 20,
    transition: "all ease-in-out 1s",
  },
  logoNavLink: {
    zIndex: "1",
    float: "left",
    flex: "9",
    margin: "0 3vw 0 10vw",
    boxShadow: "inset rgba(40, 40, 40, 0.4) 0px 0px 20px 12px",
    pointerEvents: "auto",
  },
  navBarWrapper: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#202020a2",
    padding: "1.2em 0em",
  },
  navBtnDiv: {
    padding: "0 5vw",
    marginTop: "1em",
    flexWrap: "wrap",
    display: "flex",
    flexDirection: "column",
    borderRadius: "70px 0px 0px 70px",
    background: "transparent",
  },
};

const mapStateToProps = ({ navbar, auth }) => ({ navbar, auth });

export default connect(mapStateToProps)(Navbar);
