import React from "react";
import { StatesProvider } from "../Providers/StatesProvider";
import { CategoriesProvider } from "../Providers/CategoriesProvider";
import { IsThinContextProvider } from "../../contexts/IsThinContext";

const ContextWrapper = (props) => {
  return (
    <IsThinContextProvider>
      <StatesProvider>
        <CategoriesProvider>{props.children}</CategoriesProvider>
      </StatesProvider>
    </IsThinContextProvider>
  );
};

export default ContextWrapper;
