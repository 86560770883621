import axios from "axios";
import {
  ANALYTIC_FETCH,
  ANALYTIC_FETCH_DONE,
  ANALYTIC_FETCH_FAIL,
  ANALYTIC_FETCH_PARTIAL,
} from "./types";
import config from "../config";

export const fetchAnalytics = (token) => async (dispatch) => {
  dispatch({ type: ANALYTIC_FETCH });
  const url = `${config.apiRoot}/api/admin/analytics/dashboard`;
  try {
    const res = await axios.get(url, { headers: { "x-blb-token": token } });
    dispatch({
      type: ANALYTIC_FETCH_DONE,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: ANALYTIC_FETCH_FAIL,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const fetchNotifs = (token) => async (dispatch) => {
  const url = `${config.apiRoot}/api/admin/analytics/notifs`;
  try {
    const res = await axios.get(url, { headers: { "x-blb-token": token } });
    console.log(res.data);
    dispatch({
      type: ANALYTIC_FETCH_PARTIAL,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: ANALYTIC_FETCH_FAIL,
      payload: error.response ? error.response.data : error,
    });
  }
};
