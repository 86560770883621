import { NAV_HIDE, NAV_SHOW, NAV_UPDATE } from "../actions/types";

const INITIAL_STATE = {
  navbarOn: true,
  loginLink: "/login",
  homeLink: "/home",
  logoImageLink: "/static/media/blblogo.e2add015.png"
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NAV_UPDATE:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case NAV_SHOW:
      return {
        ...state,
        navbarOn: true,
      };
    case NAV_HIDE:
      return {
        ...state,
        navbarOn: false,
      };
    default:
        return state;
  }
};
