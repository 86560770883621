import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Button from "../common/Button";
import queryString from "query-string";

const Confirmation = (props) => {
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    const queries = queryString.parse(window.location.search);
    if (queries.validated) {
      setValidated(queries.validated === "T");
    }
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className={"font-primary"}
        style={{
          margin: "1rem 1rem 1rem 1rem",
          maxWidth: "800px",
          width: "100%",
          fontSize: "1rem",
          backgroundColor: "rgba(100, 225, 100, 0.2)",
          padding: "1rem",
        }}
      >
        {validated ? (
          <p>
            Congratulations, your email has been validated we will finish our
            review of your business as soon as possible.
          </p>
        ) : (
          <p>
            Congratulations on submitting your business. We will review it
            shortly and email you if any further validation is requried. Please
            add support@blacklivesbiz.com to your contacts or business whitelist
            to ensure you get our emails. You may also contact us if you have
            any questions on the status of your review.
          </p>
        )}
      </div>
      <Button style={styles.button} onClick={() => props.history.push("/")}>
        Return Home
      </Button>
    </div>
  );
};

const styles = {
  button: {
    width: "auto",
    height: 42,
    paddingRight: 10,
    paddingLeft: 10,
    margin: "0px",
    borderRadius: "5px",
    fontSize: 18,
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
  },
};

export default withRouter(Confirmation);
