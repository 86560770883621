import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../../actions";
import NavbarDes from "../Navbar/NavbarDes";
import NavbarMob from "../Navbar/NavbarMob";
import { isMobile } from "react-device-detect";
import logo from "../../assets/img/blbLogoWT-1300.png";
import { withRouter } from "react-router-dom";

const mobileWidth = 777;

class Navbar extends Component {
  state = {
    display: true,
    width: window.innerWidth,
  };

  onLogout = () => {
    this.props.logout(this.props.history);
  };

  isThin = () => this.state.width <= mobileWidth;

  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    return isMobile || this.isThin() ? (
      <NavbarMob
        navbarOn
        loginLink="/login"
        homeLink="/"
        donateLink="/donate"
        supportLink="/supporters"
        logoImageLink={logo}
        adminLink="/fakeAdmin/review"
        style={{ ...styles.navbar }}
        onLogout={this.onLogout}
      />
    ) : (
      <NavbarDes
        navbarOn
        loginLink="/login"
        homeLink="/"
        donateLink="/donate"
        supportLink="/supporters"
        logoImageLink={logo}
        adminLink="/fakeAdmin/review"
        style={{ ...styles.navbar }}
        onLogout={this.onLogout}
      />
    );
  }
}

const styles = {
  navbar: {
    backgroundColor: "transparent",
    position: "absolute",
    width: "100%",
    zIndex: 9999,
  },
};

const mapStateToProps = (state) => ({
  navbar: state.navbar,
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(withRouter(Navbar));
