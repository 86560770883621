import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setError } from "../../../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BizChange from "./AdminBizChange";
import { withRouter } from "react-router-dom";
import axios from "axios";
import config from "../../../config";

const BizChanges = (props) => {
  const { setError, auth, history } = props;
  const [fetching, setFetching] = useState(true);
  const [bizs, setBizs] = useState([]);

  useEffect(() => {
    //fetch changes
    const _asyncFetchChanges = async () => {
      setFetching(true);
      try {
        const url = `${config.apiRoot}/api/admin/biz/changes`;
        const res = await axios.get(url, {
          headers: { "x-blb-token": auth.token },
        });
        console.log("claims", res.data);
        setBizs(res.data);
        setFetching(false);
      } catch (error) {
        console.log(error);
        setError(
          `Failed to load change requests from server, ${error.message}`,
          3,
          history
        );
      }
    };
    _asyncFetchChanges();
  }, [setError, auth, history]);

  const selectBiz = (biz) => {
    props.history.push({
      pathname: `/fakeAdmin/changes/${biz._id}`,
      state: { id: biz._id },
    });
  };

  const renderBizs = () => {
    return bizs.map((biz) => (
      <BizChange biz={biz} onClick={selectBiz} key={biz._id} />
    ));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-evenly",
          paddingRight: "1.5rem",
          paddingLeft: "1.5rem",
          alignItems: "center",
          flexDirection: "row",
          flexWrap: "wrap",
          backgroundColor: "#fff",
        }}
      >
        {fetching ? (
          <FontAwesomeIcon
            icon={["fas", "spinner"]}
            spin
            style={{ fontSize: 20, margin: "0 auto" }}
          />
        ) : (
          renderBizs()
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setError })(withRouter(BizChanges));
