import React, { useState, Fragment } from "react";
import Button from "../../common/Button";
import InlineInputContainer from "../../common/InlineInputContainer";
import Form from "../../common/Form";
import Select from "../../common/Select";
import BorderCard from "../../common/BorderCard";

const Claim = (props) => {
  const [selection, setSelection] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = () => {
    setSubmitting(true);
    props.onApproval(
      props.biz._id,
      props.claimants[selection]._id,
      handleError
    );
  };

  const handleError = (message) => {
    setSubmitting(false);
    setError(message);
  };

  const renderClaimants = () => {
    return props.claimants.map((claimant, index) => (
      <option value={index} key={claimant._id}>
        {claimant.name}
      </option>
    ));
  };

  const denyClaimant = () => {
    setSubmitting(true);
    props.onDenial(
      props.biz._id,
      props.claimants[selection]._id,
      setSubmitting,
      handleError
    );
  };

  const { biz, claimants } = props;
  return (
    <BorderCard
      className="font-primary"
      style={{
        margin: "0.5rem auto",
        padding: "1rem",
        width: "300px",
      }}
    >
      <h1 style={{ fontSize: "1.3rem", fontWeight: "bold" }}>{biz.name}</h1>
      <p>{biz.street_name}</p>
      <p>{`${biz.city}, ${biz.state} ${biz.zipcode}`}</p>
      <div style={styles.row}>
        <p style={styles.label}>Phone Number</p>
        <p style={styles.value}>
          {biz.business_phone
            ? `(${biz.business_phone.substring(
                0,
                3
              )}) ${biz.business_phone.substring(
                3,
                6
              )}-${biz.business_phone.substring(6)}`
            : null}
        </p>
      </div>
      <div style={styles.row}>
        <p style={styles.label}>Email on file</p>
        <p style={styles.value}>{biz.owner_email}</p>
      </div>
      <Form
        onSubmit={onSubmit}
        style={{
          margin: "0",
          padding: "0rem",
          width: "300px",
          textAlign: "left",
          alignItems: "flex-start",
        }}
      >
        <InlineInputContainer error={error} style={{ minWidth: "300px" }}>
          <Select
            style={{
              color: selection === "" ? "gray" : "black",
            }}
            id="selection"
            onChange={(e) => setSelection(e.target.value)}
            value={selection}
            placeholder="Select claimant for more info"
          >
            {renderClaimants()}
          </Select>
        </InlineInputContainer>
        {selection ? (
          <Fragment>
            <div style={styles.row}>
              <p style={styles.label}>Claimant Email</p>
              <p style={styles.value}>{claimants[selection].userId.email}</p>
            </div>
            <div style={styles.row}>
              <p style={styles.label}>Claimant Ethnicity</p>
              <p style={styles.value}>{claimants[selection].ethnicity}</p>
            </div>
            <div style={styles.row}>
              <p style={styles.label}>Claimant has other biz(s)</p>
              <p style={styles.value}>
                {claimants[selection].isOwner ? "True" : "False"}
              </p>
            </div>
            <p style={styles.label}>Claimant Bio</p>
            <p style={styles.value}>{claimants[selection].bio}</p>
          </Fragment>
        ) : null}
        {selection ? (
          <InlineInputContainer style={{ minWidth: 300, flexWrap: "nowrap" }}>
            <Button
              disabled={submitting}
              style={{
                backgroundColor: "#4286f7",
                color: "#f1f1f1",
                border: "none",
              }}
            >
              Approve
            </Button>
            <Button
              disabled={submitting}
              style={{
                backgroundColor: "firebrick",
                color: "#f1f1f1",
                border: "none",
              }}
              onClick={denyClaimant}
            >
              Deny
            </Button>
          </InlineInputContainer>
        ) : null}
      </Form>
    </BorderCard>
  );
};

const styles = {
  value: {
    flex: 1,
    fontSize: "0.9rem",
    textAlign: "right",
    // lineBreak: "anywhere",
  },
  label: {
    fontSize: "0.9rem",
    fontWeight: "bold",
    marginRight: "1rem",
  },
  row: {
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
};

export default Claim;
