import React, { Component } from "react";
import { connect } from "react-redux";
import {
  newBizUpdate,
  submitBiz,
  getFormData,
  fetchCities,
} from "../../actions";
import { withRouter } from "react-router-dom";
import Button from "../common/Button";
import Form from "../common/Form";
import Input from "../common/Input";
import ReCAPTCHA from "react-google-recaptcha";
import Jimp from "jimp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const hours = [
  { value: 0, display: "12 AM" },
  { value: 1, display: "1 AM" },
  { value: 2, display: "2 AM" },
  { value: 3, display: "3 AM" },
  { value: 4, display: "4 AM" },
  { value: 5, display: "5 AM" },
  { value: 6, display: "6 AM" },
  { value: 7, display: "7 AM" },
  { value: 8, display: "8 AM" },
  { value: 9, display: "9 AM" },
  { value: 10, display: "10 AM" },
  { value: 11, display: "11 AM" },
  { value: 12, display: "12 PM" },
  { value: 13, display: "1 PM" },
  { value: 14, display: "2 PM" },
  { value: 15, display: "3 PM" },
  { value: 16, display: "4 PM" },
  { value: 17, display: "5 PM" },
  { value: 18, display: "6 PM" },
  { value: 19, display: "7 PM" },
  { value: 20, display: "8 PM" },
  { value: 21, display: "9 PM" },
  { value: 22, display: "10 PM" },
  { value: 23, display: "11 PM" },
];
const bizTypes = ["Incorporated", "Non-Profit", "Self-Employed", "Contractor"];
const ethnicities = [
  "Black (African Decent)",
  "White (European Decent)",
  "Native American or Alaskan Native",
  "Hispanic or Latino",
  "Middle Eastern",
  "Asian",
  "Mixed",
  "Other",
];

class BizForm extends Component {
  state = {
    file: null,
    preview: null,
    loading: false,
    warning: false,
  };

  componentDidMount() {
    console.log("test");
    this.props.getFormData();
    this.recapthcaRef = React.createRef;
  }

  photoChange = async (e) => {
    let warning = false;
    this.setState({ ...this.state, file: e.target.files[0], loading: true });
    if (e.target.files[0] === undefined) {
      this.setState({
        file: null,
        preview: null,
        loading: false,
        warning: false,
      });
      return;
    }
    const image = await Jimp.read(URL.createObjectURL(e.target.files[0]));
    if (image.getHeight() > image.getWidth()) {
      await image.rotate(90);
      warning = true;
    }
    await image.resize(400, 225);
    await image.quality(90);
    this.setState({
      ...this.state,
      warning,
      loading: false,
      preview: await image.getBase64Async(Jimp.AUTO),
    });
  };

  handleSubmit = () => {
    window.scrollTo(0, 0);
    this.props.submitBiz(
      this.props.newBiz.query,
      this.props.history,
      this.state.file
    );
  };

  handleChange = (e) => {
    this.props.newBizUpdate(e.target.id, e.target.value);
  };

  handleStateChange = (e) => {
    this.props.newBizUpdate(e.target.id, e.target.value);
    this.props.fetchCities(e.target.value);
  };

  renderOptions = (collection) => {
    return collection.map((item) => (
      <option value={item} key={item}>
        {item}
      </option>
    ));
  };

  renderHours = () => {
    return hours.map((hour) => (
      <option value={hour.value} key={hour.value}>
        {hour.display}
      </option>
    ));
  };

  renderStates = () => {
    return this.props.st.states.map((st) => (
      <option value={st.abbr} key={st.abbr}>
        {st.abbr}
      </option>
    ));
  };

  renderTopCities = () => {
    return this.props.st.cities === null
      ? null
      : this.props.st.cities.counted.slice(0, 20).map((city) => (
          <option value={city.name} key={city._id}>
            {city.name} ({city.count})
          </option>
        ));
  };

  renderCities = () => {
    return this.props.st.cities === null
      ? null
      : this.props.st.cities.names.map((city) => (
          <option value={city} key={city}>
            {city}
          </option>
        ));
  };

  renderCategories = () => {
    return this.props.cat.list.map((cat) => (
      <option value={cat.name} key={cat._id}>
        {cat.name}
      </option>
    ));
  };

  onCapthca = (value) => {
    console.log(value);
    this.props.newBizUpdate("recaptcha", value);
  };

  render() {
    const { query, errors, submitting } = this.props.newBiz;
    const recommendedInput = { ...styles.input, ...styles.recommended };
    const recommendedSelect = { ...styles.selectInput, ...styles.recommended };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className={"font-primary"}
          style={{
            margin: "1rem 1rem 0rem 1rem",
            maxWidth: "800px",
            width: "100%",
            fontSize: "1rem",
          }}
        >
          <p>
            Thank you for registering your business on BlackLivesBiz! You are a
            few clicks away from having your business entered into the first
            online national listing for businesses with owners of African
            descent. The following will help ensure that your business entry
            gets added to Black Lives Biz:
          </p>
          <ul>
            <li style={styles.li}>
              The more information you supply (the more fields you fill in), the
              more likely your entry will be approved and listed. The more
              entries you skip, decreases the likeliness that your business will
              be approved.
            </li>
            <li style={styles.li}>
              After you fill out the following form you will be invited to
              upload a photo that represents your business.
            </li>
            <li style={styles.li}>
              Confirm your email address (An email will be sent with a
              BlackLivesBiz validation link)
            </li>
          </ul>
          <p>
            Once you complete this process, your business entry will be
            reviewed. If everything checks out, your entry will be approved.
          </p>
          <p style={{ color: "#4286f7" }}>
            Fields in BLUE are HIGHLY recommended.
          </p>
          <p>More Power To You!</p>
        </div>
        <Form
          onSubmit={this.handleSubmit}
          style={{
            margin: "1.3rem 1rem 2rem 1rem",
            maxWidth: "800px",
            width: "100%",
          }}
        >
          <div style={{ ...styles.inlineFields, marginBottom: "1rem" }}>
            <div style={{ flexDirection: "column" }}>
              {this.state.loading ? (
                <div
                  style={{
                    display: "flex",
                    width: 325,
                    height: (235 / 16) * 9,
                    border: "1px solid black",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={["fas", "spinner"]}
                    spin
                    style={{ fontSize: 20, margin: "0 auto" }}
                  />
                </div>
              ) : (
                <img
                  alt="biz preview"
                  width={325}
                  height={(325 / 16) * 9}
                  src={
                    this.state.preview
                      ? this.state.preview
                      : query.state
                      ? `https://storage.googleapis.com/photos-blb/as_BLB${query.state}.png`
                      : `https://storage.googleapis.com/photos-blb/as_BLB.png`
                  }
                  style={{ border: "1px solid black" }}
                />
              )}
            </div>
            <div
              style={{
                flex: 1,
                flexDirection: "column",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <p
                className={"font-primary"}
                style={{
                  fontSize: "1.25rem",
                  paddingLeft: "0.5rem",
                }}
              >
                Choose an image to represent your business.
              </p>
              <p
                className={"font-primary"}
                style={{
                  fontSize: "1rem",
                  paddingLeft: "0.5rem",
                }}
              >
                For best results:
              </p>
              <ul>
                <li>Landscape Orientation</li>
                <li>Take a picture with a modern phone held horizontally</li>
                <li>Use the image here to preview what guests will see</li>
              </ul>
              <input
                style={{
                  ...styles.input2,
                  ...styles.input,
                  ...recommendedInput,
                  flex: 0,
                  minWidth: 100,
                }}
                type="file"
                id="photo"
                accept="image/png, image/jpeg"
                onChange={this.photoChange}
              />
            </div>
          </div>
          {this.state.warning ? (
            <p style={styles.error}>
              You selected a portrait image note these are auto rotated. Double
              check the preview before submitting.
            </p>
          ) : null}
          <div style={styles.inlineFields}>
            <select
              style={{
                ...styles.selectInput,
                color: query.requestedLogo === "" ? "gray" : "black",
              }}
              id="requestedLogo"
              onChange={this.handleChange}
              value={query.requestedLogo}
            >
              <option value="" disabled>
                Would your business like a $50 logo created? ($1000 value)
              </option>
              <option value="F">No</option>
              <option value="T">Yes</option>
            </select>
          </div>

          <div style={styles.inlineFields}>
            <Input
              style={recommendedInput}
              id="owner_first_name"
              placeholder="Owner First Name"
              onChange={this.handleChange}
              value={query.owner_first_name}
            />
            <Input
              style={recommendedInput}
              id="owner_last_name"
              placeholder="Owner Last Name"
              onChange={this.handleChange}
              value={query.owner_last_name}
            />
          </div>
          <div style={styles.inlineFields}>
            <Input
              type="email"
              style={recommendedInput}
              id="owner_email"
              placeholder="Owner E-mail"
              onChange={this.handleChange}
              value={query.owner_email}
            />
          </div>
          {errors.owner_email ? (
            <p style={styles.error}>{errors.owner_email}</p>
          ) : null}

          <div style={styles.inlineFields}>
            <Input
              style={{
                ...recommendedInput,
                border: errors.poster_email
                  ? "1px solid red"
                  : recommendedInput.border,
              }}
              type="text"
              id="poster_email"
              placeholder="Your Email (Required even if same as owners)"
              onChange={this.handleChange}
              value={query.poster_email}
            />
          </div>
          {errors.poster_email ? (
            <p style={styles.error}>{errors.poster_email}</p>
          ) : null}
          <div style={styles.inlineFields}>
            <select
              style={{
                ...recommendedSelect,
                color: query.owner_ethn === "" ? "gray" : "black",
              }}
              id="owner_ethn"
              onChange={this.handleChange}
              value={query.owner_ethn}
            >
              <option value="" disabled>
                Owner's Ethnicity
              </option>
              {this.renderOptions(ethnicities)}
            </select>
          </div>
          {errors.owner_ethn ? (
            <p style={styles.error}>{errors.owner_ethn}</p>
          ) : null}
          <div style={styles.inlineFields}>
            <Input
              style={recommendedInput}
              type="text"
              id="name"
              placeholder="Business Name"
              onChange={this.handleChange}
              value={query.name}
            />
          </div>
          {errors.name ? <p style={styles.error}>{errors.name}</p> : null}
          <div style={styles.inlineFields}>
            <textarea
              style={{ ...styles.textArea, ...styles.recommended }}
              placeholder="Short description about the business"
              id="about"
              value={query.about}
              onChange={this.handleChange}
              rows="3"
            />
          </div>
          <div style={styles.inlineFields}>
            <Input
              style={{ ...recommendedInput, flex: 3 }}
              id="street_name"
              placeholder="Street Name or enter 'Online' for online only"
              onChange={this.handleChange}
              value={query.street_name}
            />
          </div>
          {errors.street ? <p style={styles.error}>{errors.street}</p> : null}
          <div style={{ ...styles.inlineFields, flexWrap: "wrap" }}>
            <select
              style={{
                ...recommendedSelect,
                color: query.state === "" ? "gray" : "black",
              }}
              id="state"
              onChange={this.handleStateChange}
              value={query.state}
            >
              <option value="" disabled>
                State
              </option>
              {this.renderStates()}
            </select>
            <select
              style={{
                ...recommendedSelect,
                color: query.city === "" ? "gray" : "black",
              }}
              id="city"
              onChange={this.handleChange}
              value={query.city}
            >
              <option value="" disabled>
                {this.props.st.cities === null
                  ? "<<<"
                  : `${query.state} Cities`}
              </option>
              <optgroup label="Top 20 Cities">
                {this.renderTopCities()}
              </optgroup>
              <optgroup label="Alphabetical Listing">
                {this.renderCities()}
              </optgroup>
            </select>
            <Input
              style={styles.input}
              type="number"
              id="zipcode"
              placeholder="Zipcode"
              onChange={this.handleChange}
              value={query.zipcode}
            />
          </div>
          {errors.address ? <p style={styles.error}>{errors.address}</p> : null}
          <div style={styles.inlineFields}>
            <select
              style={{
                ...recommendedSelect,
                color: query.category === "" ? "gray" : "black",
              }}
              id="category"
              onChange={this.handleChange}
              value={query.category}
            >
              <option value="" disabled>
                Category
              </option>
              {this.renderCategories()}
            </select>
          </div>
          {errors.category ? (
            <p style={styles.error}>{errors.category}</p>
          ) : null}
          <div style={styles.inlineFields}>
            <select
              style={{
                ...recommendedSelect,
                color: query.registered === "" ? "gray" : "black",
              }}
              id="registered"
              onChange={this.handleChange}
              value={query.registered}
            >
              <option value="" disabled>
                Is the business incorporated?
              </option>
              <option value="F">No</option>
              <option value="T">Yes</option>
            </select>
          </div>
          <div style={styles.inlineFields}>
            <select
              style={{
                ...recommendedSelect,
                color: query.business_type === "" ? "gray" : "black",
              }}
              id="business_type"
              onChange={this.handleChange}
              value={query.business_type}
            >
              <option value="" disabled>
                Business Type
              </option>
              {this.renderOptions(bizTypes)}
            </select>
          </div>
          <div style={styles.inlineFields}>
            <select
              style={{
                ...recommendedSelect,
                color: query.open_hour === "" ? "gray" : "black",
              }}
              id="open_hour"
              onChange={this.handleChange}
              value={query.open_hour}
            >
              <option value="" disabled>
                Operating Hour Open
              </option>
              {this.renderHours()}
            </select>
            <select
              style={{
                ...recommendedSelect,
                color: query.close_hour === "" ? "gray" : "black",
              }}
              id="close_hour"
              onChange={this.handleChange}
              value={query.close_hour}
            >
              <option value="" disabled>
                Operating Hour Close
              </option>
              {this.renderHours()}
            </select>
          </div>
          {errors.hour ? <p style={styles.error}>{errors.hour}</p> : null}
          <div style={styles.inlineFields}>
            <Input
              style={{ ...recommendedInput, flex: 2 }}
              id="owner_phone"
              type="tel"
              placeholder="Business' Phone"
              onChange={this.handleChange}
              value={query.owner_phone}
            />
            <Input
              style={recommendedInput}
              id="years"
              type="number"
              placeholder="Years in Business?"
              onChange={this.handleChange}
              value={query.years}
            />
          </div>
          {errors.phoneYears ? (
            <p style={styles.error}>{errors.phoneYears}</p>
          ) : null}
          <div style={styles.inlineFields}>
            <Input
              style={recommendedInput}
              type="text"
              id="business_web"
              placeholder="Business' Website Url"
              onChange={this.handleChange}
              value={query.business_web}
            />
          </div>
          {errors.business_web ? (
            <p style={styles.error}>{errors.business_web}</p>
          ) : null}
          <div style={styles.inlineFields}>
            <Input
              style={recommendedInput}
              id="facebook"
              type="text"
              placeholder="Business' Facebook Website Url"
              onChange={this.handleChange}
              value={query.facebook}
            />
          </div>
          {errors.facebook ? (
            <p style={styles.error}>{errors.facebook}</p>
          ) : null}
          <div style={styles.inlineFields}>
            <Input
              style={styles.input}
              id="owner_linkedin"
              type="text"
              placeholder="Owner's LinkedIn Website Url"
              onChange={this.handleChange}
              error={errors.owner_linkedin ? errors.owner_linkedin : false}
              value={query.owner_linkedin}
            />
          </div>
          {errors.owner_linkedin ? (
            <p style={styles.error}>{errors.owner_linkedin}</p>
          ) : null}
          <div style={styles.inlineFields}>
            <Input
              style={styles.input}
              id="twitter"
              type="text"
              placeholder="Business' Twitter Account @handle"
              onChange={this.handleChange}
              value={query.twitter}
            />
          </div>
          {errors.twitter ? <p style={styles.error}>{errors.twitter}</p> : null}
          <div style={styles.inlineFields}>
            <Input
              style={styles.input}
              id="instagram"
              type="text"
              placeholder="Business' Instagram Account Name"
              onChange={this.handleChange}
              value={query.instagram}
            />
          </div>
          {errors.instagram ? (
            <p style={styles.error}>{errors.instagram}</p>
          ) : null}
          <h3 style={{ marginBottom: 0 }}>
            Secure Your Business' BlackLivesBiz Personal Page
          </h3>
          <p>will appear as www.BlackLivesBiz.com/businessname</p>
          <div style={styles.inlineFields}>
            <Input
              style={styles.input}
              id="personalUrl"
              type="text"
              placeholder="Business Name (alphanumeric NO spaces or special characters)"
              onChange={this.handleChange}
              error={errors.personalUrl ? errors.personalUrl : false}
              value={query.personalUrl}
            />
          </div>
          {errors.personalUrl ? (
            <p style={styles.error}>{errors.personalUrl}</p>
          ) : null}
          <ReCAPTCHA
            style={{ margin: "1em 0 0.5em 0" }}
            ref={this.recapthcaRef}
            sitekey="6Lf_k74ZAAAAAEOGZFfU0AJNG69OJKVpK6XBoa6I"
            onChange={this.onCapthca}
          />
          <div
            className={"font-primary"}
            style={{
              margin: "0rem 1rem 0rem 1rem",
              maxWidth: "800px",
              width: "100%",
              fontSize: "1rem",
            }}
          >
            <p>
              The more information you supply (the more fields you fill in), the
              more likely your entry will be approved and listed. The more
              entries you skip, decreases the likeliness that your business will
              be approved.
            </p>
          </div>
          <Button
            disabled={submitting}
            style={{
              backgroundColor: "#4286f7",
              color: "#f1f1f1",
              border: "none",
            }}
          >
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}

const styles = {
  inlineFields: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minWidth: 400,
    borderRadius: 5,
    overflow: "hidden",
    flexWrap: "wrap",
  },
  li: {
    margin: "0.5em 0",
  },
  input: {
    border: "1px solid #d9d9d9",
    paddingRight: 5,
    paddingLeft: 10,
    width: "100%",
    minWidth: 250,
    margin: 0,
    height: 30,
    borderRadius: 5,
    flex: 1,
    fontFamily:
      '"Exo 2", -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  },
  input2: {
    color: "#000",
    backgroundColor: "#eee",
    fontSize: 18,
    padding: 5,
  },
  selectInput: {
    color: "#000",
    backgroundColor: "#eee",
    fontSize: 18,
    border: "1px solid #d9d9d9",
    padding: "5px 5px 5px 10px",
    width: "100%",
    minWidth: 250,
    height: 42,
    margin: 0,
    borderRadius: "5px",
    flex: 1,
  },
  recommended: {
    border: "1px solid #4286f7",
    backgroundColor: "#e6eaf0",
  },
  textArea: {
    border: "1px solid #d9d9d9",
    padding: "5px 5px 5px 10px",
    fontFamily:
      '"Exo 2", -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    // paddingRight: 5,
    // paddingLeft: 10,
    width: "100%",
    minWidth: 250,
    margin: 0,
    minHeight: 30,
    borderRadius: 5,
    flex: 1,
    backgroundColor: "#eee",
    fontSize: 18,
  },
  button: {
    width: "auto",
    height: 42,
    paddingRight: 10,
    paddingLeft: 10,
    margin: "0px",
    borderRadius: "5px",
    fontSize: 18,
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
  },
  error: {
    color: "red",
    fontWeight: "bold",
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 3,
    paddingLeft: 5,
  },
};

const mapStateToProps = (state) => ({
  newBiz: state.newBiz,
  st: state.st,
  cat: state.categories,
});

export default connect(mapStateToProps, {
  newBizUpdate,
  submitBiz,
  getFormData,
  fetchCities,
})(withRouter(BizForm));
