import axios from "axios";
import {
  SEARCH_SUBMIT,
  SEARCH_SUBMIT_FAILED,
  SEARCH_SUBMIT_SUCCESS,
  SEARCH_UPDATE,
  SEARCH_DEFAULT,
  SEARCH_LOAD_MORE,
  SEARCH_LOAD_MORE_FAIL,
  SEARCH_LOAD_MORE_SUCCESS,
} from "./types";
import config from "../config";
import isEmpty from "../utils/isEmpty";
import { categoriesFetch } from "./categoriesActions";
import { fetchStates, fetchCities } from "./stateActions";
import { ls_defaultLocation } from "../utils/lsKeys";

export const searchUpdate = (field, value) => (dispatch) => {
  dispatch({
    type: SEARCH_UPDATE,
    payload: { field, value },
  });
};

export const searchSubmit = (query, history) => async (dispatch) => {
  dispatch({
    type: SEARCH_SUBMIT,
  });

  const { city, state, category, name } = query;

  // state is required
  if (isEmpty(state)) {
    return dispatch({
      type: SEARCH_SUBMIT_FAILED,
      payload: { search: "State is required" },
    });
  }

  if (!(!isEmpty(city) || !isEmpty(category) || !isEmpty(name))) {
    return dispatch({
      type: SEARCH_SUBMIT_FAILED,
      payload: { search: "Must include one other search option" },
    });
  }

  const params = {
    city,
    state,
    category,
    name,
  };

  let url = `${config.apiRoot}/api/biz/search/query`;
  try {
    const res = await axios.get(url, { params });
    dispatch({
      type: SEARCH_SUBMIT_SUCCESS,
      payload: res.data,
    });
    history.push("/search");
    return;
  } catch (error) {
    console.error("error", error);
    return dispatch({
      type: SEARCH_SUBMIT_FAILED,
      payload: error.response ? error.response.data : error.response,
    });
  }
};

export const searchLoadMore = (page, query) => async (dispatch) => {
  dispatch({ type: SEARCH_LOAD_MORE });
  let url = `${config.apiRoot}/api/biz/search/query`;
  const params = {
    city: query.city,
    state: query.state,
    category: query.category,
    name: query.name,
    pg: page,
  };
  try {
    const res = await axios.get(url, { params });
    dispatch({
      type: SEARCH_LOAD_MORE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.error("error", error.message);
    return dispatch({
      type: SEARCH_LOAD_MORE_FAIL,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const getFormData = () => async (dispatch) => {
  await dispatch(fetchStates());
  await dispatch(categoriesFetch());
};

export const defaultLocation = (lat, lon) => async (dispatch) => {
  const url = `${config.apiRoot}/api/location`;
  try {
    const res = await axios.get(url, { query: { lat, lon } });
    localStorage.setItem(ls_defaultLocation, JSON.stringify(res.data));
    await dispatch(fetchCities(res.data.state));
    dispatch({
      type: SEARCH_DEFAULT,
      payload: res.data,
    });
  } catch (error) {
    console.error("error", error);
  }
};

export const setLocation = (location) => async (dispatch) => {
  await dispatch(fetchCities(location.state));
  dispatch({
    type: SEARCH_DEFAULT,
    payload: location,
  });
};
