import React, { useState, useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import backSplash from "../../assets/img/searchSplash.png";
import BizForm from "./BizFormex";
import FormErrors from "./FormErrors";
import Validate from "./Validate";
import Photo from "./Photo";
import Confirmation from "./Confirmation";

const NewBiz = (props) => {
  const [query, setQuery] = useState({
    name: "",
    poster_email: "",
    state: "",
    city: "",
    zipcode: "",
    steet_num: "",
    street_name: "",
    category: "",
    registered: "",
    business_type: "",
    about: "",
    short: "",
    years: "",
    business_web: "",
    location: "",
    owner_first_name: "",
    owner_last_name: "",
    owner_ethn: "",
    owner_phone: "",
    owner_linkedin: "",
    twitter: "",
    instagram: "",
    facebook: "",
    open_hour: "",
    close_hour: "",
    personalUrl: "",
    requestedLogo: "",
  });

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <link rel="canonical" href="https://blacklivesbiz.com/newBiz" />
      <div
        style={{
          backgroundImage: `url(${backSplash})`,
          backgroundSize: "cover",
          position: "relative",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "6rem 0 4rem 0",
          zIndex: 0,
        }}
      >
        <h2
          style={{
            fontSize: "3rem",
            alignSelf: "center",
            margin: "0.85em 0",
            fontFamily: "'Exo 2', sans-serif",
            fontWeight: "900",
            color: "#f1f1f1",
            textShadow: "1px 1px black",
            textAlign: "center",
          }}
        >
          Submit A New Biz
        </h2>
      </div>
      <Switch>
        <Route path={`/newBiz`} exact>
          <BizForm />
        </Route>
        <Route path={"/newBiz/errors"}>
          <FormErrors />
        </Route>
        <Route path={`/newBiz/validate`}>
          <Validate />
        </Route>
        <Route path={`/newBiz/photo`}>
          <Photo />
        </Route>
        <Route path={"/newBiz/confirm"}>
          <Confirmation />
        </Route>
      </Switch>
    </div>
  );
};

export default withRouter(NewBiz);
