import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { changeLoginForm, submitLogin } from "../../actions";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import Button from "../common/Button";
import Form from "../common/Form";
import Input from "../common/Input";

const messages = {
  1: "You must be logged in to do that.",
  2: "You do not have a complete profile. Please log back in and complete your profile.",
};

const LoginForm = (props) => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    const queries = queryString.parse(window.location.search);
    if (queries.message) {
      setMessage(messages[queries.message]);
    }
  }, []);

  const handleSubmit = () => {
    props.submitLogin(props.auth.query, props.history);
  };

  const handleChange = (e) => {
    props.changeLoginForm(e.target.id, e.target.value);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    props.history.push("/login/register");
  };

  const { query, errors } = props.auth;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <link rel="canonical" href="https://blacklivesbiz.com/login" />
      {message ? (
        <div
          className="font-primary"
          style={{
            margin: "1rem 1rem 0rem 1rem",
            maxWidth: "800px",
            width: "100%",
            fontSize: "1rem",
            color: "#f1f1f1",
            backgroundColor: "rgba(255, 204, 75, 0.4)",
            // opacity: 0.3,
            padding: "0.5rem",
            textAlign: "center",
          }}
        >
          <p style={{ opacity: 1 }}>{message}</p>
        </div>
      ) : null}
      <Form
        onSubmit={handleSubmit}
        style={{
          margin: "2rem 1rem",
          maxWidth: "800px",
          width: "100%",
        }}
      >
        <div style={styles.inlineFields}>
          <Input
            style={styles.input}
            id="email"
            type="email"
            placeholder="Email"
            onChange={handleChange}
            value={query.email}
            required
          />
        </div>
        <div style={styles.inlineFields}>
          <Input
            style={styles.input}
            id="password"
            type="password"
            placeholder="Password"
            onChange={handleChange}
            value={query.password}
            required
          />
        </div>
        {errors.login ? <p style={styles.error}>{errors.login}</p> : null}
        <div style={{ ...styles.inlineFields, justifyContent: "space-around" }}>
          <Button style={styles.button}>Login</Button>
          <Button style={styles.button} onClick={handleRegister}>
            Create Account
          </Button>
        </div>
        {/* disabled={submitting} */}
      </Form>
    </div>
  );
};

const styles = {
  inlineFields: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    maxWidth: "90vw",
    minWidth: 200,
    borderRadius: 5,
    overflow: "hidden",
    flexWrap: "wrap",
    alignText: "center",
    marginBottom: "1%",
  },
  input: {
    border: "1px solid #d9d9d9",
    paddingRight: 5,
    paddingLeft: 10,
    width: "100%",
    minWidth: 250,
    margin: 0,
    height: 30,
    borderRadius: 5,
    flex: 1,
  },
  button: {
    width: "auto",
    height: 42,
    paddingRight: 20,
    paddingLeft: 20,
    margin: "0px, 5px, 0, 5px",
    borderRadius: "5px",
    border: "none",
    fontSize: 18,
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
    cursor: "pointer",
  },
  error: {
    color: "red",
    fontWeight: "bold",
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 3,
    paddingLeft: 5,
  },
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  changeLoginForm,
  submitLogin,
})(withRouter(LoginForm));
