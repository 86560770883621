import {
  BIZS_FETCH,
  BIZS_FETCH_FAILED,
  BIZS_FETCH_SUCCESS,
  BIZS_LOAD_MORE,
  BIZS_LOAD_MORE_FAIL,
  BIZS_LOAD_MORE_SUCCESS,
} from "../actions/types";

const INITIAL_STATE = {
  list: { new: [], existing: [] },
  hasMore: false,
  loading: true,
  errors: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BIZS_FETCH:
      return {
        ...state,
        loading: true,
        errors: {},
      };
    case BIZS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case BIZS_FETCH_SUCCESS:
      return {
        ...state,
        list: {
          new: action.payload.new,
          existing: action.payload.existing,
        },
        loading: false,
        hasMore: action.payload.hasMore,
      };
    case BIZS_LOAD_MORE:
      return {
        ...state,
        errors: {},
        hasMore: false,
      };
    case BIZS_LOAD_MORE_FAIL:
      return {
        ...state,
        errors: action.payload,
      };
    case BIZS_LOAD_MORE_SUCCESS:
      return {
        ...state,
        hasMore: action.payload.hasMore,
        list: {
          new: [...state.list.new, ...action.payload.new],
          existing: [...state.list.existing, ...action.payload.existing],
        },
      };
    default:
      return state;
  }
};
