module.exports = [
  "Graphic Imagery",
  "Nudity",
  "Attacks Against a Gender or Orientation",
  "Attacks Against a Religion or Belief",
  "Attacks Against a Race Verbal",
  "Attacks Against A Race Imagery",
  "Violent Imagery",
  "Violent Language",
  "Spam",
  "Intentional Excessive Humor",
  "Illegal Business",
  "Repetitive Denials",
];
