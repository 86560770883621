import React from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import backSplash from "../../assets/img/searchSplash.png";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import UserConfirmed from "./UserConfirmed";
import RegistrationErrors from "./RegistrationErrors";
import UserValidate from "./UserValidate";

const LoginPage = (props) => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#333",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${backSplash})`,
          backgroundSize: "cover",
          position: "relative",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "6rem 0 4rem 0",
          zIndex: 0,
          maxHeight: "20vh",
        }}
      >
        <h2
          style={{
            fontSize: "3rem",
            alignSelf: "center",
            margin: "0.85em 0",
            fontFamily: "'Exo 2', sans-serif",
            fontWeight: "900",
            color: "#f1f1f1",
            textShadow: "1px 1px black",
            textAlign: "center",
            maxWidth: "90vw",
          }}
        >
          Login With Your Account
        </h2>
      </div>
      <Switch>
        <Route path={"/login"} exact>
          <LoginForm />
        </Route>
        <Route path={"/login/register"} exact>
          <RegisterForm />
        </Route>
        <Route path={"/login/confirmed"} exact>
          <UserConfirmed />
        </Route>
        <Route path={"/login/errors"} exact>
          <RegistrationErrors />
        </Route>
        <Route path={"/login/validate"}>
          <UserValidate />
        </Route>
      </Switch>
    </div>
  );
};

export default withRouter(LoginPage);
