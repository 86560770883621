import React from 'react'
import {useIsThinContext} from '../../contexts/IsThinContext'
import NavbarDes from "../Navbar/NewNavbarDes";
import NavbarMob from "../Navbar/NewNavbarMob";
import logo from "../../assets/img/blbLogoWT-1300.png";

const styles = {
  navbar: {
    backgroundColor: "transparent",
    position: "absolute",
    width: "100%",
    zIndex: 9999,
  },
};

export default function FunctionalNavbar() {
  const isThin = useIsThinContext()
  return (
    isThin ? (
      <NavbarMob
        navbarOn
        loginLink="/login"
        homeLink="/"
        donateLink="/donate"
        supportLink="/supporters"
        logoImageLink={logo}
        adminLink="/fakeAdmin/review"
        style={{ ...styles.navbar }}
      />
    ) : (
      <NavbarDes
        navbarOn
        loginLink="/login"
        homeLink="/"
        donateLink="/donate"
        supportLink="/supporters"
        logoImageLink={logo}
        adminLink="/fakeAdmin/review"
        style={{ ...styles.navbar }}
      />

    )
  )
}
