import { ERROR_CLEAR, ERROR_SET } from "../actions/types";

const INITIAL_STATE = {
  text: "",
  level: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ERROR_SET:
      return {
        text: action.payload.text,
        level: action.payload.level,
      };
    case ERROR_CLEAR:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
