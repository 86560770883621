import {
  BIZS_FETCH,
  BIZS_FETCH_FAILED,
  BIZS_FETCH_SUCCESS,
  BIZS_LOAD_MORE,
  BIZS_LOAD_MORE_FAIL,
  BIZS_LOAD_MORE_SUCCESS,
} from "./types";
import axios from "axios";
import config from "../config";

export const bizsFetch = () => async (dispatch) => {
  dispatch({ type: BIZS_FETCH });
  try {
    const url = `${config.apiRoot}/api/biz/all/home`;
    const res = await axios.get(url);
    dispatch({
      type: BIZS_FETCH_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: BIZS_FETCH_FAILED,
      payload: error.response ? error.response.data : error.response,
    });
  }
};

export const bizLoadMore = (page) => async (dispatch) => {
  dispatch({ type: BIZS_LOAD_MORE });
  try {
    const url = `${config.apiRoot}/api/biz/all/home?pg=${page}`;
    const res = await axios.get(url);
    dispatch({
      type: BIZS_LOAD_MORE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.error(error.message);
    dispatch({
      type: BIZS_LOAD_MORE_FAIL,
      payload: error.response ? error.response.data : error.message,
    });
  }
};
