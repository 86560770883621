import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchAnalytics } from "../../actions";
import NewBusinesses from "./Cards/NewBusinesses";
import SelfActions from "./Cards/SelfActions";
import TeamStats from "./Cards/TeamStats";

class AdminDashboard extends Component {
  componentDidMount() {
    this.props.fetchAnalytics(this.props.auth.token);
  }

  render() {
    const { selfActions, newSubmissions, loading } = this.props.analytics;
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {loading ? (
          <FontAwesomeIcon
            icon={["fas", "spinner"]}
            spin
            style={{ fontSize: 20, margin: "0 auto" }}
          />
        ) : (
          <Fragment>
            <NewBusinesses data={newSubmissions} />
            <SelfActions data={selfActions} />
            <TeamStats />
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  analytics: state.analytics,
});

export default connect(mapStateToProps, { fetchAnalytics })(AdminDashboard);
