import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { apiRoot } from "../../config";
import axios from "axios";

const UserValidate = (props) => {
  const { history, setError } = props;
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const _submitValidation = async (id, code) => {
      try {
        const url = `${apiRoot}/api/profiles/validate`;
        const res = await axios.put(url, { id, code });
        if (res.status !== 200) throw new Error("failed to validate");
        setProcessing(false);
        history.push(
          `/login/confirmed?outcome=${res.data.outcome}&bizFound=${res.data.bizFound}`
        );
      } catch (error) {
        console.log(error.message);
        setError(
          "Failed to validate email. Please try again or contact support",
          3,
          history
        );
        alert("Failed to validate email. Try again or contact support");
      }
    };
    const queries = queryString.parse(window.location.search);
    console.log(queries);
    if (queries.id && queries.code) {
      setProcessing(true);
      _submitValidation(queries.id, queries.code);
    }
  }, [setError, history]);

  return (
    <div style={{ flex: 1, display: "flex" }}>
      {processing ? (
        <FontAwesomeIcon
          icon={["fas", "spinner"]}
          spin
          style={{ fontSize: 20, margin: "0 auto" }}
        />
      ) : null}
    </div>
  );
};

export default withRouter(UserValidate);
