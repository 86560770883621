import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import AppRouter from "./components/Routers/AppRouter";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faTwitter,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import { IsThinContextProvider } from "./contexts/IsThinContext";
import ContextWrapper from "./components/Routers/ContextWrapper";

library.add(fas, faLinkedin, faTwitter, faFacebook);

function App() {
  return (
    <BrowserRouter>
      <ContextWrapper>
        <AppRouter />
      </ContextWrapper>
    </BrowserRouter>
  );
}

export default App;
