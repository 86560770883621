import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  getFormData,
  fetchCities,
  setError,
  setProfile,
} from "../../../actions";
import { Route, Switch, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import backSplash from "../../../assets/img/searchSplash.png";
import config from "../../../config";
import FormBiz from "./FormBiz";

const BizChange = (props) => {
  const [query, setQuery] = useState({
    state: "",
    city: "",
    zipcode: "",
    street_num: "",
    street_name: "",
    category: "",
    registered: "",
    business_type: "",
    about: "",
    short: "",
    years: "",
    business_web: "",
    location: "",
    owner_first_name: "",
    owner_last_name: "",
    owner_ethn: "",
    owner_email: "",
    owner_phone: "",
    owner_linkedin: "",
    twitter: "",
    instagram: "",
    facebook: "",
    open_hour: "",
    close_hour: "",
    personalUrl: "",
    requestedLogo: "",
    file: "",
  });
  const [changes, setChanges] = useState({
    state: null,
    city: null,
    zipcode: null,
    street_name: null,
    category: null,
    about: null,
    years: null,
    business_web: null,
    owner_first_name: null,
    owner_last_name: null,
    owner_phone: null,
    twitter: null,
    instagram: null,
    facebook: null,
    open_hour: null,
    close_hour: null,
    photo: null,
    // recapthca: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [errors, setErrors] = useState({});

  const updateFormData = (field, value) => {
    setChanges({ ...changes, [field]: value });
  };
  const bizId = props.location.state.bizId;

  const handleSubmit = () => {
    // submit
    console.log("submitting");
    setSubmitting(true);
    _submitForm();
  };

  const _submitForm = async () => {
    try {
      const url = `${config.apiRoot}/api/biz/change/${bizId}`;
      const data = { ...changes };
      console.log(data);
      Object.getOwnPropertyNames(data).forEach((key) => {
        if (data[key] === null || data[key] === undefined) {
          delete data[key];
        }
      });
      const res = await axios.post(url, data, {
        headers: { "x-blb-token": props.auth.token },
      });
      console.log(res.data);
      // props.setProfile(res.data);
      setSubmitting(false);
      props.setError(
        "Thank you for your submission! our team will review and any accepted changes will appear in the next few days.",
        1,
        props.history
      );
    } catch (error) {
      console.log(error);
      const errors = error.response
        ? error.response.data
          ? error.response.data.errors
          : { form: error.message }
        : { form: error.message };
      console.log(errors);
      setErrors(errors);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setFetching(true);
    _fetchBizData();
  }, []);

  const _fetchBizData = async () => {
    try {
      setErrors({});
      if (!bizId || !props.auth.token) {
        return props.history.push("/");
      }
      await props.getFormData();
      const url = `${config.apiRoot}/api/biz/change/${bizId}`;

      const res = await axios.get(url, {
        headers: { "x-blb-token": props.auth.token },
      });

      setQuery({ ...res.data });
      setFetching(false);
      // fetch data
    } catch (error) {
      const errors = error.response
        ? error.response.data
          ? error.response.data
          : { form: error.message }
        : { form: error.message };
      console.log(errors);
      setErrors(errors);
      setFetching(false);
    }
  };

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <div
        style={{
          backgroundImage: `url(${backSplash})`,
          backgroundSize: "cover",
          position: "relative",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "6rem 0 4rem 0",
          zIndex: 0,
        }}
      >
        <h1
          style={{
            fontSize: "3rem",
            alignSelf: "center",
            margin: "0.85em 0",
            fontFamily: "'Exo 2', sans-serif",
            fontWeight: "900",
            color: "#f1f1f1",
            textShadow: "1px 1px black",
            textAlign: "center",
          }}
        >
          Submit an Update
        </h1>
        <h2
          style={{
            fontSize: "2.5rem",
            alignSelf: "center",
            margin: "0.85em 0",
            fontFamily: "'Exo 2', sans-serif",
            fontWeight: "900",
            color: "#f1f1f1",
            textShadow: "1px 1px black",
            textAlign: "center",
          }}
        >
          Thank you for helping us keep acurate records!
        </h2>
      </div>
      <Switch>
        <Route path={"/changeBiz"} exact>
          {fetching ? (
            <FontAwesomeIcon
              icon={["fas", "spinner"]}
              spin
              style={{ fontSize: 20, margin: "0 auto" }}
            />
          ) : (
            <FormBiz
              query={query}
              changes={changes}
              onSubmit={handleSubmit}
              updateFormData={updateFormData}
              submitting={submitting}
              fetchCities={fetchCities}
              st={props.st}
              cat={props.cat}
            />
          )}
        </Route>
        <Route path={"/updateBiz/error"}>{/* error page */}</Route>
        <Route path={"/updateBiz/submitted"}>{/*Success*/}</Route>
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  st: state.st,
  cat: state.categories,
});

export default connect(mapStateToProps, {
  getFormData,
  fetchCities,
  setError,
  setProfile,
})(withRouter(BizChange));
