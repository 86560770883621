import React, { useState } from "react";
import Button from "../../common/Button";
import Form from "../../common/Form";
import InlineInputContainer from "../../common/InlineInputContainer";
import NewInput from "../../common/NewInput";
import TextArea from "../../common/TextArea";
import Select from "../../common/Select";
import BlackArtist from "./BlackArtist";
import TextBlock from "../../common/TextBlock";
import XButton from "../../common/XButton";
import { useIsThinContext } from "../../../contexts/IsThinContext";

const NewBAForm = (props) => {
  const { query, updateForm, updateExclusive, submitting, fetchCities } = props;
  const adminForm = props.adminForm || false;
  const editForm = props.editForm || false;
  const isThin = useIsThinContext();
  const styles = {
    formPlainText: { fontSize: 18, paddingRight: 5 },
  };

  const handlePhotoChange = (e) => {
    updateForm("file", e.target.files[0]);
  };

  const handleSubmit = () => {
    props.onSubmit();
  };

  const handleChange = (e) => {
    updateForm(e.target.id, e.target.value);
  };

  const proNameChange = (e) => {
    updateExclusive(e.target.id, e.target.value);
  };

  const subCategoryChange = (e) => {
    updateExclusive(e.target.id, e.target.value.split(","));
  };

  const handleMainCategoryChange = (e) => {
    const { value } = e.target;
    if (value === "") {
      updateForm("category", [...query.category.slice(1)]);
      return;
    }
    updateForm("category", [value, ...query.category.slice(1)]);
  };

  const handleSecondaryChange = (e) => {
    const { value } = e.target;
    const index = query.category.indexOf(value);
    if (index === -1) {
      updateForm("category", [...query.category, value]);
    } else {
      const temp = [...query.category];
      temp.splice(index, 1);
      updateForm("category", [...temp]);
    }
  };

  const handleStateChange = (e) => {
    handleChange(e);
    fetchCities(e.target.value);
  };

  const renderStates = () => {
    return props.st.states.map((st) => (
      <option value={st.abbr} key={st.abbr}>
        {st.abbr}
      </option>
    ));
  };

  const renderTopCities = () => {
    return props.st.cities === null
      ? null
      : props.st.cities.counted.slice(0, 20).map((city) => (
          <option value={city.name} key={city._id}>
            {city.name} ({city.count})
          </option>
        ));
  };

  const renderCities = () => {
    return props.st.cities === null
      ? null
      : props.st.cities.names.map((city) => (
          <option value={city} key={city}>
            {city}
          </option>
        ));
  };

  const renderCategories = (excluder) => {
    return props.cat.map((category) => {
      if (category === excluder) return null;
      return (
        <option value={category} key={category}>
          {category}
        </option>
      );
    });
  };

  const renderSecondaryCats = () => {
    if (query.category.length <= 1) {
      return <TextBlock hideX text="No Secondary Categories Selected" />;
    }
    return query.category
      .slice(1)
      .map((category) => (
        <TextBlock
          text={category}
          key={category}
          onRemove={handleSecondaryChange}
        />
      ));
  };

  const handleCustomLinkChange = (e) => {
    const updatedLinks = [...query.exclusiveMeta.customLinks];
    const key = e.target.name;
    const idx = e.target.id;
    const targetLink = query.exclusiveMeta.customLinks[idx];
    updatedLinks.splice(idx, 1, { ...targetLink, [key]: e.target.value });
    updateExclusive("customLinks", updatedLinks);
  };

  const addCustomLink = (e) => {
    e.preventDefault();
    updateExclusive("customLinks", [
      ...query.exclusiveMeta.customLinks,
      { header: "", text: "" },
    ]);
  };

  const removeCustomLink = (idx) => {
    const updatedLinks = [...query.exclusiveMeta.customLinks];
    updatedLinks.splice(idx, 1);
    updateExclusive("customLinks", updatedLinks);
  };

  const renderCustomLinks = () => {
    return (
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <strong>
          <p style={{ fontSize: "medium" }}>Additional Links</p>
        </strong>
        {query.exclusiveMeta.customLinks.map((customLink, i) => {
          return (
            <div key={i}>
              <InlineInputContainer>
                {isThin ? (
                  <p style={{ ...styles.formPlainText, margin: 0 }}>{`Link ${
                    i + 1
                  }: `}</p>
                ) : null}
                <NewInput
                  id={i}
                  name="header"
                  style={{ flex: 1, minWidth: 200 }}
                  placeholder="Site Name"
                  onChange={handleCustomLinkChange}
                  value={customLink.header}
                />
                <NewInput
                  id={i}
                  name="text"
                  style={{ flex: 2 }}
                  placeholder="Link Or Social Handle"
                  onChange={handleCustomLinkChange}
                  value={customLink.text}
                />
                <XButton
                  onClick={() => {
                    removeCustomLink(i);
                  }}
                />
              </InlineInputContainer>
            </div>
          );
        })}
        {query.exclusiveMeta.customLinks.length > 0 ? (
          <strong>
            <p>
              Must Include Full URL To Create Hyperlink ( https:// required )
            </p>
          </strong>
        ) : (
          <strong>
            <p>No Additional Links Added</p>
          </strong>
        )}
        {query.exclusiveMeta.customLinks.length < 4 ? (
          <Button
            onClick={addCustomLink}
            disabled={submitting}
            style={{
              alignSelf: "left",
              backgroundColor: "#4286f7",
              color: "#f1f1f1",
              border: "none",
              cursor: "pointer",
              width: "max-content",
            }}
          >
            Add Custom Link
          </Button>
        ) : null}
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="font-primary">
        {adminForm ? (
          <p>
            Admin level access all fields can be modified. Try to fill as many
            fields as possible.
          </p>
        ) : editForm ? (
          <p>Updating the artist record</p>
        ) : (
          <p>
            Adding a new Black Artist. Enter as much information as possible to
            create the an amazing listing.
          </p>
        )}
        <p>You may preview the posting at any time under the form.</p>
      </div>
      <Form
        onSubmit={handleSubmit}
        style={{
          margin: "1.3rem 1rem 2rem 1rem",
          maxWidth: "800px",
          width: "100%",
        }}
      >
        <InlineInputContainer>
          <p style={{ ...styles.formPlainText }}>Artist's Photo:</p>
          <NewInput
            style={{
              padding: 5,
              width: "auto",
            }}
            placeholder="Choose Photo"
            type="file"
            id="photo"
            accept="image/png, image/jpeg"
            onChange={handlePhotoChange}
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <NewInput
            id="owner_first_name"
            placeholder="Artist First Name"
            onChange={handleChange}
            value={query.owner_first_name}
          />
          <NewInput
            id="owner_last_name"
            placeholder="Artist Last Name"
            onChange={handleChange}
            value={query.owner_last_name}
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <NewInput
            id="proName"
            placeholder="Full Professional Name"
            onChange={proNameChange}
            value={query.exclusiveMeta.proName}
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <TextArea
            id="about"
            placeholder="Description about the Artist"
            onChange={handleChange}
            value={query.about}
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <Select
            style={{
              color: query.state === "" ? "gray" : "black",
            }}
            id="state"
            onChange={handleStateChange}
            value={query.state}
            placeholder="State"
          >
            {renderStates()}
          </Select>
          <Select
            style={{
              color: query.city === "" ? "gray" : "black",
            }}
            id="city"
            onChange={handleChange}
            value={query.city}
            placeholder={
              props.st.cities === null ? "<<<" : `${query.state} Cities`
            }
          >
            <optgroup label="Top 20 Cities">{renderTopCities()}</optgroup>
            <optgroup label="Alphabetical Listing">{renderCities()}</optgroup>
          </Select>
        </InlineInputContainer>
        <InlineInputContainer>
          <Select
            style={{
              color: query.category === "" ? "gray" : "black",
            }}
            id="mainCategory"
            onChange={handleMainCategoryChange}
            value={query.category[0]}
          >
            <option value="">Artist's Main Category</option>
            {renderCategories("")}
          </Select>
        </InlineInputContainer>
        <InlineInputContainer>
          <Select
            style={{
              color: query.category === "" ? "gray" : "black",
            }}
            id="secondaryCats"
            onChange={handleSecondaryChange}
            value={""}
          >
            <option value="">Secondary Categories -Select Multiple-</option>
            {renderCategories(query.category[0])}
          </Select>
        </InlineInputContainer>
        <InlineInputContainer style={{ justifyContent: "flex-start" }}>
          {renderSecondaryCats()}
        </InlineInputContainer>
        <InlineInputContainer>
          <NewInput
            id="subCategories"
            placeholder="Additional categories, comma separated eg: Jazz Singer, Book Art, Gospel Singer"
            onChange={subCategoryChange}
            value={query.exclusiveMeta.subCategories.join(",")}
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <NewInput
            id="owner_phone"
            type="tel"
            placeholder="Artist's Phone"
            onChange={handleChange}
            value={query.owner_phone}
          />
        </InlineInputContainer>
        {adminForm ? (
          <InlineInputContainer>
            <NewInput
              id="owner_email"
              type="email"
              placeholder="Artist's Email"
              onChange={handleChange}
              value={query.owner_email}
            />
          </InlineInputContainer>
        ) : null}
        <InlineInputContainer>
          <NewInput
            id="business_web"
            placeholder="Artist's Web Site"
            onChange={handleChange}
            value={query.business_web}
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <NewInput
            id="owner_linkedin"
            placeholder="Artist's Linkedin Url"
            onChange={handleChange}
            value={query.owner_linkedin}
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <NewInput
            id="facebook"
            placeholder="Artist's Facebook Url"
            onChange={handleChange}
            value={query.facebook}
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <NewInput
            id="twitter"
            placeholder="Artist's Twitter Url"
            onChange={handleChange}
            value={query.twitter}
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <NewInput
            id="instagram"
            placeholder="Artist's Instagram Url"
            onChange={handleChange}
            value={query.instagram}
          />
        </InlineInputContainer>
        {renderCustomLinks()}
        <Button
          disabled={submitting}
          style={{
            backgroundColor: "#4286f7",
            color: "#f1f1f1",
            border: "none",
            cursor: "pointer",
          }}
        >
          Submit
        </Button>
      </Form>
      <BlackArtist artist={query} photo={"local"} />
    </div>
  );
};

export default NewBAForm;
