import { combineReducers } from "redux";
import bizsReducer from "./bizsReducer";
import searchReducer from "./searchReducer";
import bizReducer from "./bizReducer";
import adminBizUpdateReducer from "./adminBizUpdateReducer";
import adminBizsReducer from "./adminBizsReducer";
import categoriesReducer from "./categoriesReducer";
import navbarReducer from "./navbarReducer";
import stateReducer from "./stateReducer";
import newBizReducer from "./newBizReducer";
import loginReducer from "./loginReducer";
import adminReducer from "./AdminReducer";
import analyticsReducer from "./analyticsReducer";
import supportersReducer from "./supportersReducer";
import newUserReducer from "./newUserReducer";
import errorReducer from "./errorReducer";

export default combineReducers({
  auth: loginReducer,
  bizs: bizsReducer,
  search: searchReducer,
  biz: bizReducer,
  adminBizUpdate: adminBizUpdateReducer,
  adminBizs: adminBizsReducer,
  categories: categoriesReducer,
  navbar: navbarReducer,
  st: stateReducer,
  newBiz: newBizReducer,
  admin: adminReducer,
  analytics: analyticsReducer,
  supporter: supportersReducer,
  newUser: newUserReducer,
  error: errorReducer,
});
