import {
  NEW_BIZ_SUBMIT,
  NEW_BIZ_SUBMIT_FAILED,
  NEW_BIZ_SUBMIT_SUCCESS,
  NEW_BIZ_UPDATE,
  NEW_BIZ_PHOTO_UPLOADED,
  NEW_BIZ_VALIDATE_SUCCESS,
  NEW_BIZ_VALIDATE_UPDATE,
  NEW_BIZ_CODE_LINK,
} from "./types";
import axios from "axios";
import config from "../config";
import isEmpty from "../utils/isEmpty";
import bizSanitizer from "../utils/bizSanitizer";

export const newBizUpdate = (field, value) => (dispatch) => {
  dispatch({
    type: NEW_BIZ_UPDATE,
    payload: { field, value },
  });
};

export const codeUpdate = (field, value) => (dispatch) => {
  dispatch({ type: NEW_BIZ_VALIDATE_UPDATE, payload: { field, value } });
};

export const submitBiz = (query, history, file) => async (dispatch) => {
  dispatch({ type: NEW_BIZ_SUBMIT });
  const url = `${config.apiRoot}/api/biz/`;
  let { data, errors } = bizSanitizer(query);

  if (!isEmpty(errors)) {
    dispatch({
      type: NEW_BIZ_SUBMIT_FAILED,
      payload: errors,
    });
  }

  try {
    const res = await axios.post(url, data);
    if (file) {
      dispatch(uploadImg(res.data.id, file, history));
      history.push("/newBiz/confirm");
    }
    dispatch({
      type: NEW_BIZ_SUBMIT_SUCCESS,
      payload: res.data.id,
    });
    history.push(`/newBiz/confirm`);
    // push to the history the validate email page.
  } catch (error) {
    console.error(error);
    console.log(error.response);
    errors = {};
    if (error.response && error.response.data.errors) {
      error.response.data.errors.forEach((err) => {
        switch (err.param) {
          case "street_num":
          case "street_name":
            errors.street = err.msg;
            break;
          case "state":
          case "city":
          case "zipcode":
            errors.address = err.msg;
            break;
          case "open_hour":
          case "close_hour":
            errors.hour = err.msg;
            break;
          case "owner_phone":
          case "years":
            errors.phoneYears = err.msg;
            break;
          default:
            errors[err.param] = err.msg;
        }
      });
    } else {
      errors = error.response ? error.response.data : error;
    }

    dispatch({
      type: NEW_BIZ_SUBMIT_FAILED,
      payload: errors,
    });
    history.push("/newBiz/errors");
  }
};

export const submitCode = (id, code, history) => async (dispatch) => {
  dispatch({ type: NEW_BIZ_SUBMIT });
  const url = `${config.apiRoot}/api/biz/validate/${id}`;
  const data = { code };
  try {
    await axios.put(url, data);
    dispatch({
      type: NEW_BIZ_VALIDATE_SUCCESS,
    });
    history.push("/newBiz/confirm?validated=T");
  } catch (error) {
    console.error(error);
    dispatch({
      type: NEW_BIZ_SUBMIT_FAILED,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const uploadImg = (id, file, history) => async (dispatch) => {
  dispatch({ type: NEW_BIZ_SUBMIT });
  const url = `${config.apiRoot}/api/biz/imgUpload/${id}`;
  const data = new FormData();
  data.append("image", file);

  try {
    await axios.put(url, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    dispatch({
      type: NEW_BIZ_SUBMIT_SUCCESS,
      payload: id,
    });
  } catch (error) {
    console.error(error);
    dispatch({
      type: NEW_BIZ_SUBMIT_FAILED,
      payload: error.response ? error.response.data : error,
    });
  }
};

export const newBizLink = (id, code) => (dispatch) => {
  return dispatch({ type: NEW_BIZ_CODE_LINK, payload: { id, code } });
};
