import React from "react";
import Button from "../../common/Button";
import Form from "../../common/Form";
import InlineInputContainer from "../../common/InlineInputContainer";
import NewInput from "../../common/NewInput";
import TextArea from "../../common/TextArea";
import Select from "../../common/Select";

const hours = [
  { value: 0, display: "12 AM" },
  { value: 1, display: "1 AM" },
  { value: 2, display: "2 AM" },
  { value: 3, display: "3 AM" },
  { value: 4, display: "4 AM" },
  { value: 5, display: "5 AM" },
  { value: 6, display: "6 AM" },
  { value: 7, display: "7 AM" },
  { value: 8, display: "8 AM" },
  { value: 9, display: "9 AM" },
  { value: 10, display: "10 AM" },
  { value: 11, display: "11 AM" },
  { value: 12, display: "12 PM" },
  { value: 13, display: "1 PM" },
  { value: 14, display: "2 PM" },
  { value: 15, display: "3 PM" },
  { value: 16, display: "4 PM" },
  { value: 17, display: "5 PM" },
  { value: 18, display: "6 PM" },
  { value: 19, display: "7 PM" },
  { value: 20, display: "8 PM" },
  { value: 21, display: "9 PM" },
  { value: 22, display: "10 PM" },
  { value: 23, display: "11 PM" },
];

const FormBiz = (props) => {
  const { query, changes, updateFormData, submitting, fetchCities } = props;

  const handlePhotoChange = (e) => {
    updateFormData("file", e.target.files[0]);
  };

  const handleSubmit = () => {
    console.log("submitclicked");
    props.onSubmit();
  };

  const handleChange = (e) => {
    updateFormData(e.target.id, e.target.value);
  };

  const handleStateChange = (e) => {
    handleChange(e);
    fetchCities(e.target.value);
  };

  // const renderOptions = (collection) => {
  //   return collection.map((item) => (
  //     <option value={item} key={item}>
  //       {item}
  //     </option>
  //   ));
  // };

  const renderHours = () => {
    return hours.map((hour) => (
      <option value={hour.value} key={hour.value}>
        {hour.display}
      </option>
    ));
  };

  const renderStates = () => {
    return props.st.states.map((st) => (
      <option value={st.abbr} key={st.abbr}>
        {st.abbr}
      </option>
    ));
  };

  const renderTopCities = () => {
    return props.st.cities === null
      ? null
      : props.st.cities.counted.slice(0, 20).map((city) => (
          <option value={city.name} key={city._id}>
            {city.name} ({city.count})
          </option>
        ));
  };

  const renderCities = () => {
    return props.st.cities === null
      ? null
      : props.st.cities.names.map((city) => (
          <option value={city} key={city}>
            {city}
          </option>
        ));
  };

  const renderCategories = () => {
    return props.cat.list.map((category) => (
      <option value={category.name} key={category._id}>
        {category.name}
      </option>
    ));
  };

  // const onCaptcha = (value) => {
  //   updateFormData("recapthca", value);
  // };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="font-primary"
        style={{
          margin: "1rem 1rem 0rem 1rem",
          maxWidth: "800px",
          width: "100%",
          fontSize: "1rem",
        }}
      >
        <p>
          Thank you for helping BlackLivesBiz keep accurate records of black
          owned businesses. Once you finish submitting the form below with the
          new information your changes will be reviewed and vetted by our team
          for accuracy.
        </p>
      </div>
      <Form
        onSubmit={handleSubmit}
        style={{
          margin: "1.3rem 1rem 2rem 1rem",
          maxWidth: "800px",
          width: "100%",
        }}
      >
        <InlineInputContainer>
          <div style={{ flexDirection: "column" }}>
            <img
              alt="biz preview"
              width={325}
              height={(325 / 16) * 9}
              src={
                changes.photo
                  ? URL.createObjectURL(changes.file)
                  : query.photos && query.photos[0]
                  ? query.photos[0]
                  : changes.state || query.state
                  ? `https://storage.googleapis.com/photos-blb/as_BLB${
                      changes.state ? changes.state : query.state
                    }.png`
                  : `https://storage.googleapis.com/photos-blb/as_BLB.png`
              }
            />
          </div>
          <div
            style={{
              flex: 1,
              flexDirection: "column",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <p
              className={"font-primary"}
              style={{
                fontSize: "1.25rem",
                paddingLeft: "0.5rem",
              }}
            >
              {query.photos[0]
                ? "Have a better photo of the Biz or a logo?"
                : "Do you have an image of the Biz or a picture of the logo?"}
            </p>
            <p
              className={"font-primary"}
              style={{
                fontSize: "1rem",
                paddingLeft: "0.5rem",
              }}
            >
              For best results:
            </p>
            <ul>
              <li>Landscape Orientation</li>
              <li>Take a picture with a modern phone held horizontally</li>
              <li>Use the image here to preview what guests will see</li>
            </ul>
            <NewInput
              style={{
                padding: 5,
                width: "auto",
              }}
              type="file"
              id="photo"
              accept="image/png, image/jpeg"
              onChange={handlePhotoChange}
            />
          </div>
        </InlineInputContainer>
        <InlineInputContainer>
          <NewInput
            id="owner_first_name"
            placeholder="Owner First Name"
            onChange={handleChange}
            value={
              changes.owner_first_name === null
                ? query.owner_first_name
                : changes.owner_first_name
            }
          />
          <NewInput
            id="owner_last_name"
            placeholder="Owner Last Name"
            onChange={handleChange}
            value={
              changes.owner_last_name === null
                ? query.owner_last_name
                : changes.owner_last_name
            }
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <TextArea
            id="about"
            placeholder="Short description about the business"
            onChange={handleChange}
            value={changes.about === null ? query.about : changes.about}
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <NewInput
            id="street_name"
            placeholder="Street address or simply enter 'Online' for online only"
            onChange={handleChange}
            value={
              changes.street_name === null
                ? query.street_name
                : changes.street_name
            }
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <Select
            style={{
              color:
                query.state === "" || changes.state === "" ? "gray" : "black",
            }}
            id="state"
            onChange={handleStateChange}
            value={changes.state === null ? query.state : changes.state}
            placeholder="State"
          >
            {renderStates()}
          </Select>
          <Select
            style={{
              color:
                query.city === "" || changes.city === "" ? "gray" : "black",
            }}
            id="city"
            onChange={handleChange}
            value={changes.city === null ? query.city : changes.city}
            placeholder={
              props.st.cities === null
                ? "<<<"
                : `${
                    changes.state === null ? query.state : changes.state
                  } Cities`
            }
          >
            <optgroup label="Top 20 Cities">{renderTopCities()}</optgroup>
            <optgroup label="Alphabetical Listing">{renderCities()}</optgroup>
          </Select>
          <NewInput
            type="number"
            id="zipcode"
            placeholder="Zipcode"
            onChange={handleChange}
            value={changes.zipcode === null ? query.zipcode : changes.zipcode}
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <Select
            style={{
              color:
                query.category === "" || changes.category === ""
                  ? "gray"
                  : "black",
            }}
            id="category"
            onChange={handleChange}
            value={
              changes.category === null ? query.category[0] : changes.category
            }
          >
            {renderCategories()}
          </Select>
        </InlineInputContainer>
        <InlineInputContainer>
          <Select
            style={{
              color:
                query.open_hour === "" || changes.open_hour === ""
                  ? "gray"
                  : "black",
            }}
            id="open_hour"
            onChange={handleChange}
            value={
              changes.open_hour === null ? query.open_hour : changes.open_hour
            }
            placeholder="Operating Hour Open"
          >
            {renderHours()}
          </Select>
          <Select
            style={{
              color:
                query.close_hour === "" || changes.close_hour === ""
                  ? "gray"
                  : "black",
            }}
            id="close_hour"
            onChange={handleChange}
            value={
              changes.close_hour === null
                ? query.close_hour
                : changes.close_hour
            }
            placeholder="Operating Hour Close"
          >
            {renderHours()}
          </Select>
        </InlineInputContainer>
        <InlineInputContainer>
          <NewInput
            style={{ flex: 2 }}
            id="owner_phone"
            type="tel"
            placeholder="Business' Phone"
            onChange={handleChange}
            value={
              changes.owner_phone === null
                ? query.owner_phone
                : changes.owner_phone
            }
          />
          <NewInput
            id="years"
            type="number"
            placeholder="Years in Business"
            onChange={handleChange}
            value={changes.years === null ? query.years : changes.years}
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <NewInput
            id="business_web"
            placeholder="Business' Website Url"
            onChange={handleChange}
            value={
              changes.business_web === null
                ? query.business_web
                : changes.business_web
            }
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <NewInput
            id="facebook"
            placeholder="Business' Facebook Page Url"
            onChange={handleChange}
            value={
              changes.facebook === null ? query.facebook : changes.facebook
            }
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <NewInput
            id="twitter"
            placeholder="Business' Twitter Account @handle"
            onChange={handleChange}
            value={changes.twitter === null ? query.twitter : changes.twitter}
          />
        </InlineInputContainer>
        <InlineInputContainer>
          <NewInput
            id="instagram"
            placeholder="Business' Instagram Account Name"
            onChange={handleChange}
            value={
              changes.instagram === null ? query.instagram : changes.instagram
            }
          />
        </InlineInputContainer>
        {/* <InlineInputContainer>
          <ReCAPTCHA
            style={{ margin: "1em 0 0.5em 0" }}
            ref={recapthcaRef}
            sitekey="6Lf_k74ZAAAAAEOGZFfU0AJNG69OJKVpK6XBoa6I"
            onChange={onCapthca}
          /> 
        </InlineInputContainer> */}
        <Button
          disabled={submitting}
          style={{
            backgroundColor: "#4286f7",
            color: "#f1f1f1",
            border: "none",
          }}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default FormBiz;
