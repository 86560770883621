import {
  ANALYTIC_FETCH,
  ANALYTIC_FETCH_DONE,
  ANALYTIC_FETCH_FAIL,
  ANALYTIC_FETCH_PARTIAL,
} from "../actions/types";

const INITIAL_STATE = {
  selfActions: null,
  newSubmissions: null,
  pendingCount: 0,
  claimsCount: 0,
  loading: true,
  errors: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ANALYTIC_FETCH:
      return {
        ...state,
        loading: true,
        errors: {},
      };
    case ANALYTIC_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case ANALYTIC_FETCH_DONE:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case ANALYTIC_FETCH_PARTIAL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
