import React from "react";
import InlineInputContainer from "../../common/InlineInputContainer";

const ChangeOption = (props) => {
  const handleChange = () => {
    props.onSelect(props.field, props.value, props.requesterId);
  };

  return (
    <div style={{ ...style, ...props.style }}>
      <p className="font-primary">{props.displayValue}</p>
      {props.isEmpty ? null : (
        <InlineInputContainer style={{ Width: "25em" }}>
          <input
            type="radio"
            name={props.field}
            value={props.value}
            checked={props.formValue === props.value}
            onChange={handleChange}
          />
        </InlineInputContainer>
      )}
    </div>
  );
};

const style = {
  border: "1px solid black",
  padding: "0.5rem",
  width: "25em",
  minHeight: "100px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export default ChangeOption;
