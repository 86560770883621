import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { localLogin } from "../../actions";
import Home from "../Home/Home";
import SearchResults from "../Search/SearchResults";
import Navbar from "../Navbar/Navbar";
import NewBiz from "../Biz/NewBiz";
import LoginPage from "../User/LoginPage";
import Donate from "../Donate/Donate";
import Admin from "../Admin/Admin";
import Supporters from "../Supporters/SupportersPage";
// import codeGenerator from "../../utils/codeGenerator";
import Unsubscribe from "../Unsubscribe/Unsubscribe";
import Message from "../Error/Message";
import BizChange from "../Biz/BizChange/BizChange";
import BlackArtists from "../Exclusives/BlackArtists/BlackArtists";
import AdminEditBA from "../Exclusives/BlackArtists/AdminEditBA";
import { LSAUTH } from "../../utils/localStorageKeys";
import FunctionalNavbar from "../Navbar/FunctionalNavbar";

class AppRouter extends Component {
  componentDidMount = () => {
    const localAuth = JSON.parse(window.localStorage.getItem(LSAUTH));
    if (localAuth) {
      console.log(localAuth);
      this.props.localLogin(this.props.history, localAuth.token);
    }
  };

  _generateVisitorCode = async () => {
    // if (!guestId) {
    //   const code = codeGenerator(20);
    //   localStorage.setItem(ls_guestId, code);
    // }
  };

  render() {
    return (
      <div style={{ width: "100%" }}>
        <FunctionalNavbar />
        <Switch>
          <Route path="/" exact>
            {/* <StatesProvider> */}
            <Home image={1} />
            {/* </StatesProvider> */}
          </Route>
          <Route path="/search" exact>
            <SearchResults />
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/newBiz">
            {/* <StatesProvider> */}
            <NewBiz />
            {/* </StatesProvider> */}
          </Route>
          <Route path="/changeBiz" exact>
            <BizChange />
          </Route>
          <Route path="/donate" exact>
            <Donate />
          </Route>
          <Route path="/fakeAdmin">
            <Admin />
          </Route>
          <Route path="/supporters">
            <Supporters />
          </Route>
          <Route path="/unsubscribe">
            <Unsubscribe />
          </Route>
          <Route path="/alert/info">
            <Message />
          </Route>
          <Route path="/blackArtists/adminEdit/edit/:id">
            <AdminEditBA />
          </Route>
          <Route path="/blackArtists/adminEdit/success">
            <AdminEditBA />
          </Route>
          <Route path="/blackArtists/:id">
            <BlackArtists />
          </Route>
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({});

export default connect(null, { localLogin })(withRouter(AppRouter));
