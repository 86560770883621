// Auth types
export const AUTH_UPDATE = "AUTH_UPDATE";
export const AUTH_LOGIN_SUBMIT = "AUTH_LOGIN_SUBMIT";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAILED = "AUTH_LOGIN_FAILED";
// Search types
// used to update a field (refer to auth action authUpdate)
export const SEARCH_UPDATE = "SEARCH_UPDATE";
// used to change the loading flag to true and reset the error object to empty
export const SEARCH_SUBMIT = "SEARCH_SUBMIT";
// HTTP error response loading false and error object filled
export const SEARCH_SUBMIT_FAILED = "SEARCH_SUBMIT_FAILED";
// HTTP valid response update corresponding reducers with response data.
export const SEARCH_SUBMIT_SUCCESS = "SEARCH_SUBMIT_SUCCESS";
export const SEARCH_LIVE_UPDATE = "SEARCH_LIVE_UPDATE";
export const SEARCH_SUPPLEMENT = "SEARCH_SUPPLEMENT";
export const SEARCH_SUPPLEMENT_FAIL = "SEARCH_SUPPLEMENT_FAIL";
export const SEARCH_SUPPLEMENT_SUCCESS = "SEARCH_SUPPLEMENT_SUCCESS";
export const SEARCH_DEFAULT = "SEARCH_DEFAULT";
export const SEARCH_LOAD_MORE = "SEARCH_LOAD_MORE";
export const SEARCH_LOAD_MORE_FAIL = "SEARCH_LOAD_MORE_FAIL";
export const SEARCH_LOAD_MORE_SUCCESS = "SEARCH_LOAD_MORE_SUCCESS";

// states types
export const STATES_FETCH = "STATES_FETCH";
export const STATES_FETCH_FAIL = "STATES_FETCH_FAIL";
export const STATES_FETCH_SUCCESS = "STATES_FETCH_SUCCESS";
export const CITIES_FETCH = "CITIES_FETCH";
export const CITIES_FETCH_FAIL = "CITIES_FETCH_FAIL";
export const CITIES_FETCH_SUCCESS = "CITIES_FETCH_SUCCESS";

// Biz Types for biz form
export const NEW_BIZ_UPDATE = "NEW_BIZ_UPDATE";
export const NEW_BIZ_SUBMIT = "NEW_BIZ_SUBMIT";
export const NEW_BIZ_SUBMIT_FAILED = "NEW_BIZ_SUBMIT_FAILED";
export const NEW_BIZ_SUBMIT_SUCCESS = "NEW_BIZ_SUBMIT_SUCCESS";
export const NEW_BIZ_VALIDATE_UPDATE = "NEW_BIZ_VALIDATE_UPDATE";
export const NEW_BIZ_VALIDATE_SUCCESS = "NEW_BIZ_VALIDATE_SUCCESS";
export const NEW_BIZ_PHOTO_UPLOADED = "NEW_BIZ_PHOTO_UPLOADED";
export const NEW_BIZ_CODE_LINK = "NEW_BIZ_CODE_LINK";

// Bizs types
export const BIZS_FETCH = "BIZS_FETCH"; // used to set loading when fetching bizs
export const BIZS_FETCH_FAILED = "BIZS_FETCH_FAILED";
export const BIZS_FETCH_SUCCESS = "BIZS_FETCH_SUCCESS";
export const BIZS_SELECT = "BIZS_SELECT"; // used to select a single biz
export const BIZS_LOAD_MORE = "BIZS_LOAD_MORE";
export const BIZS_LOAD_MORE_SUCCESS = "BIZS_LOAD_MORE_SUCCESS";
export const BIZS_LOAD_MORE_FAIL = "BIZS_LOAD_MORE_FAIL";

// Admin Bizs Types
export const ADMIN_BIZS_FETCH = "ADMIN_BIZS_FETCH";
export const ADMIN_BIZS_FETCH_FAILED = "ADMIN_BIZS_FETCH_FAILED";
export const ADMIN_BIZS_FETCH_SUCCESS = "ADMIN_BIZS_FETCH_SUCCESS";
export const ADMIN_DROPDOWN_FETCH = "ADMIN_DROPDOWN_FETCH";
export const ADMIN_DROPDOWN_FETCH_SUCCESS = "ADMIN_DROPDOWN_FETCH_SUCCESS";
export const ADMIN_DROPDOWN_FETCH_FAILED = "ADMIN_DROPDOWN_FETCH_FAILED";
export const ADMIN_BIZ_STATUS_CHANGE = "ADMIN_BIZ_STATUS_CHANGE";
export const ADMIN_BIZ_DENIED = "ADMIN_BIZ_DENIED";
export const ADMIN_BIZ_APPROVED = "ADMIN_BIZ_APPROVED";
export const ADMIN_BIZ_FLAGGED = "ADMIN_BIZ_FLAGGED";
export const ADMIN_BIZ_STATUS_FAILED = "ADMIN_BIZ_STATUS_FAILED";

// Admin general types
export const ADMIN_STARTED_ADD = "ADMIN_STARTED_ADD";
export const ADMIN_FINISHED_ADD = "ADMIN_FINISHED_ADD";
export const ADMIN_CANCELED_ADD = "ADMIN_CANCELED_ADD";

//BizForm Types
export const CHANGE_FORM = "CHANGE_FORM";
export const SUBMIT_FORM = "SUBMIT_FORM";
export const BIZ_SUBMIT_FAILED = "BIZ_SUBMIT_FAILED";
export const BIZ_SUBMIT_SUCCESS = "BIZ_SUBMIT_SUCCESS";

//Admin Biz Update Types
export const ADMIN_BIZ_UPDATE = "ADMIN_BIZ_UPDATE";

// bizView types
// export const BIZ_VIEW_FETCH = "BIZ_VIEW_FETCH";
export const BIZ_VIEW_FETCH_FAIL = "BIZ_VIEW_FETCH_FAIL";
export const BIZ_VIEW_FETCH_SUCCESS = "BIZ_VIEW_FETCH_SUCCESS";

// category types
export const CAT_FETCH = "CAT_FETCH";
export const CAT_FETCH_FAIL = "CAT_FETCH_FAIL";
export const CAT_FETCH_SUCCESS = "CAT_FETCH_SUCCESS";
export const CAT_SELECT = "CAT_SELECT";

// Navbar Types
export const NAV_SHOW = "NAV_SHOW";
export const NAV_HIDE = "NAV_HIDE";
export const NAV_UPDATE = "NAV_UPDATE";

// Login Types for login form
export const LOGIN_CHANGE_FORM = "LOGIN_CHANGE_FORM";
export const LOGIN_SUBMIT = "LOGIN_SUBMIT";
export const LOGIN_SUBMIT_FAILED = "LOGIN_SUBMIT_FAILED";
export const LOGIN_SUBMIT_SUCCESS = "LOGIN_SUBMIT_SUCCESS";
export const LOGIN_LOGOUT = "LOGIN_LOGOUT";
export const LOGIN_SET_PROFILE = "LOGIN_SET_PROFILE";

// analytics types;
export const ANALYTIC_FETCH = "ANALYTIC_FETCH";
export const ANALYTIC_FETCH_DONE = "ANALYTIC_FETCH_DONE";
export const ANALYTIC_FETCH_FAIL = "ANALYTIC_FETCH_FAIL";
export const ANALYTIC_FETCH_PARTIAL = "ANALYTIC_FETCH_PARTIAL";

//supporter
export const SUPPORTER_CHANGE_FORM = "SUPPORTER_CHANGE_FORM";
export const SUPPORTER_SUBMIT = "SUPPORTER_SUBMIT";
export const SUPPORTER_SUBMIT_FAIL = "SUPPORTER_SUBMIT_FAIL";
export const SUPPORTER_SUBMIT_SUCCESS = "SUPPORTER_SUBMIT_SUCCESS";

export const SUPPORTER_FETCH = "SUPPORTER_FETCH";
export const SUPPORTER_FETCH_FAIL = "SUPPORTER_FETCH_FAIL";
export const SUPPORTER_FETCH_SUCCESS = "SUPPORTER_FETCH_SUCCESS";

// newuser
export const NEWUSER_SUBMIT = "NEWUSER_SUBMIT";
export const NEWUSER_SUBMIT_FAIL = "NEWUSER_SUBMIT_FAIL";
export const NEWUSER_SUBMIT_SUCCESS = "NEWUSER_SUBMIT_SUCCESS";

// error
export const ERROR_SET = "ERROR_SET";
export const ERROR_CLEAR = "ERROR_CLEAR";
