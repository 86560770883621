// supporter form
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Form from "../common/Form";
import Input from "../common/Input";
import Button from "../common/Button";
import {
  supporterSubmit,
  supporterUpdate,
  fetchStates,
  fetchCities,
} from "../../actions";

class supporterForm extends Component {
  componentDidMount() {
    this.props.fetchStates();
  }

  handleSubmit = () => {
    this.props.supporterSubmit(this.props.supporter.query, this.props.history);
  };

  handleChange = (e) => {
    this.props.supporterUpdate(e.target.id, e.target.value);
  };

  renderStates = () => {
    return this.props.st.states.map((st) => (
      <option value={st.abbr} key={st.abbr}>
        {st.abbr}
      </option>
    ));
  };

  handleStateChange = (e) => {
    this.props.supporterUpdate(e.target.id, e.target.value);
    this.props.fetchCities(e.target.value);
  };

  renderTopCities = () => {
    return this.props.st.cities === null
      ? null
      : this.props.st.cities.counted.slice(0, 20).map((city) => (
          <option value={city.name} key={city._id}>
            {city.name} ({city.count})
          </option>
        ));
  };

  renderCities = () => {
    return this.props.st.cities === null
      ? null
      : this.props.st.cities.names.map((city) => (
          <option value={city} key={city}>
            {city}
          </option>
        ));
  };

  renderCategories = () => {
    return this.props.cat.list.map((cat) => (
      <option value={cat.name} key={cat._id}>
        {cat.name}
      </option>
    ));
  };

  render() {
    const { query, errors } = this.props.supporter;
    return (
      <Form
        onSubmit={this.handleSubmit}
        style={{
          ...styles.form,
        }}
      >
        <div style={{ ...styles.formInnerDiv }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Input
              style={{
                ...styles.textInput,
                border: errors.email ? "1px solid red" : "1px solid black",
              }}
              id="email"
              placeholder="Enter Your Email (Required)"
              onChange={this.handleChange}
              value={query.name}
            />
          </div>
          {errors.email ? <p style={styles.error}>{errors.email}</p> : null}
        </div>
        <div style={{ ...styles.inputDiv }}>
          <Input
            style={{ ...styles.textInput }}
            id="firstname"
            placeholder="First Name"
            onChange={this.handleChange}
            value={query.name}
          />
          <Input
            style={{
              ...styles.input,
              borderRadius: 5,
              flex: 1,
              maxWidth: 700,
            }}
            id="lastname"
            placeholder="Last Name"
            onChange={this.handleChange}
            value={query.name}
          />
        </div>
        <div style={{ ...styles.inputDiv }}>
          {/* <div style={{ width: "20%" }}></div> */}
          <select
            style={{
              ...styles.selectInput,
              color: query.state === "" ? "gray" : "black",
              minWidth: 100,
              maxWidth: 300,
            }}
            id="state"
            onChange={this.handleStateChange}
            value={query.state}
          >
            <option value="" disabled>
              State
            </option>
            {this.renderStates()}
          </select>
          <select
            style={{
              ...styles.selectInput,
              color: query.city === "" ? "gray" : "black",
            }}
            id="city"
            onChange={this.handleChange}
            value={query.city}
          >
            <option value="">
              {this.props.st.cities === null ? "<<<" : `${query.state} Cities`}
            </option>
            <optgroup label="Top 20 Cities">{this.renderTopCities()}</optgroup>
            <optgroup label="Alphabetical Listing">
              {this.renderCities()}
            </optgroup>
          </select>
          <Input
            style={{
              ...styles.textInput,
            }}
            type="number"
            id="zipcode"
            placeholder="Zipcode"
            onChange={this.handleChange}
            value={query.name}
          />

          {/* <div style={{ width: "18%" }}></div> */}
        </div>
        <Button style={styles.button}>Become A Supporter</Button>
        {/* {errors.supporter ? <p style={styles.error}>{errors.supporter}</p> : null} */}
      </Form>
    );
  }
}

const styles = {
  input: {
    border: "1px solid #d9d9d9",
    paddingRight: 5,
    paddingLeft: 10,
    width: "100%",
    margin: 0,
    height: 20,
    fontSize: 15,
    fontFamily:
      '"Exo 2", -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif ',
  },
  selectInput: {
    color: "#000",
    backgroundColor: "#eee",
    fontSize: 15,
    wordBreak: "break-word",
    // lineHeight: 23,
    border: "1px solid #d9d9d9",
    padding: "5px 5px 5px 10px",
    width: "100%",
    height: 32,
    margin: "0",
    borderRadius: "5px",
    flex: 1,
    cursor: "pointer",
    minWidth: 250,
  },
  button: {
    width: "auto",
    height: 32,
    paddingRight: "1rem",
    paddingLeft: "1rem",
    borderRadius: "5px",
    fontSize: 15,
    backgroundColor: "#4286f7",
    color: "#f1f1f1",
    border: "none",
    cursor: "pointer",
    margin: "0.5em 0 0 0",
  },
  error: {
    color: "red",
    fontWeight: "bold",
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 3,
    paddingLeft: 5,
  },
  form: {
    marginRight: "0",
    marginLeft: "0",
    maxWidth: 700,
    padding: "0.5rem",
    backgroundColor: "rgb(77,77,77)",
    borderRadius: 10,
  },
  inputDiv: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    borderRadius: 5,
    overflow: "none",
    justifyContent: "center",
    alignItems: "center",
  },
  formInnerDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    // marginRight: "25%",
    // marginLeft: "25%",
    minWidth: 400,
    marginBottom: "0.5rem",
  },

  textInput: {
    border: "1px solid #d9d9d9",
    paddingRight: 5,
    paddingLeft: 10,
    width: "100%",
    margin: 0,
    height: 20,
    fontSize: 15,
    fontFamily:
      '"Exo 2", -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif ',
    borderRadius: 5,
    flex: 1,
    maxWidth: 700,
  },
};

const mapStateToProps = (state) => ({
  supporter: state.supporter,
  st: state.st,
});

export default connect(mapStateToProps, {
  supporterSubmit,
  supporterUpdate,
  fetchStates,
  fetchCities,
})(withRouter(supporterForm));
