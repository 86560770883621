import React, { Component } from "react";
import Button from "../common/Button";
import BorderCard from "../common/BorderCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";

class AdminBizMini extends Component {
  state = {
    dropdown: false,
    viewImage: false,
  };
  // Approve
  handleApprove = () => this.props.adminApprove(this.props.biz._id);

  // Delete
  handleDeny = () => this.props.adminDeny(this.props.biz._id);

  // Dropdown
  toggleDropdown = () => {
    this.setState({
      ...this.state,
      dropdown: !this.state.dropdown,
    });
  };

  toggleImage = () => {
    this.setState({
      ...this.state,
      viewImage: !this.state.viewImage,
    });
  };

  handleFlag = () => this.props.flag(this.props.biz._id);

  handlePhoto = () => {
    this.props.history.push(`/fakeAdmin/photo?id=${this.props.biz._id}`);
  };

  renderDropdown = () => {
    const { biz } = this.props;
    return (
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        {biz.photos.length > 0 ? (
          <img
            width="300px"
            height={(300 / 16) * 9}
            src={biz.photos[0]}
            style={{
              filter: this.state.viewImage ? "blur(0px)" : "blur(7px)",
              objectFit: "cover",
            }}
            onClick={this.toggleImage}
            alt={biz.name}
          />
        ) : null}
        <Button
          style={{
            flex: 1,
            backgroundColor: "darkcyan",
            color: "#f1f1f1",
            borderWidth: 0,
          }}
          onClick={this.handlePhoto}
        >
          {biz.photos.length > 0 ? "Change Photo" : "Add Photo"}
        </Button>
        {biz.emailValidated ? (
          !biz.codeCorrect ? (
            <div style={styles.row}>
              <p
                className={"font-primary"}
                style={{ ...styles.label, color: "firebrick" }}
              >
                Email validation was entered incorrectly!
              </p>
              <p className={"font-primary"} style={styles.value}>
                code required: {biz.validationCode}
                <br /> code entered: {biz.codeEntered}
              </p>
            </div>
          ) : null
        ) : (
          <div style={styles.row}>
            <p
              className={"font-primary"}
              style={{ ...styles.label, color: "firebrick" }}
            >
              Email not Validated
            </p>
          </div>
        )}

        <div style={styles.row}>
          <p className={"font-primary"} style={styles.label}>
            Poster Email
          </p>
          <p className={"font-primary"} style={styles.value}>
            {biz.poster_email}
          </p>
        </div>
        <p className={"font-primary"} style={styles.label}>
          About
        </p>
        <p className={"font-primary"} style={styles.value}>
          {biz.about}
        </p>
        <div style={styles.row}>
          <p className={"font-primary"} style={styles.label}>
            logo requested?
          </p>
          <p className={"font-primary"} style={styles.value}>
            {`${biz.requestedLogo.requested}`}
          </p>
        </div>
        <div style={styles.row}>
          <p className={"font-primary"} style={styles.label}>
            category
          </p>
          <p className={"font-primary"} style={styles.value}>
            {biz.category}
          </p>
        </div>
        <div style={styles.row}>
          <p className={"font-primary"} style={styles.label}>
            street
          </p>
          <p className={"font-primary"} style={styles.value}>
            {biz.street_name}
          </p>
        </div>
        <div style={styles.row}>
          <p className={"font-primary"} style={styles.label}>
            hours
          </p>
          <p className={"font-primary"} style={styles.value}>
            {biz.open_hour} {biz.close_hour}
          </p>
        </div>
        <div style={styles.row}>
          <p className={"font-primary"} style={styles.label}>
            Business Phone
          </p>
          <p className={"font-primary"} style={styles.value}>
            {biz.business_phone
              ? `(${biz.business_phone.substring(
                  0,
                  3
                )}) ${biz.business_phone.substring(
                  3,
                  6
                )}-${biz.business_phone.substring(6)}`
              : null}
          </p>
        </div>
        <div style={styles.row}>
          <p className={"font-primary"} style={styles.label}>
            Owner Phone
          </p>
          <p className={"font-primary"} style={styles.value}>
            {biz.owner_phone
              ? `(${biz.owner_phone.substring(
                  0,
                  3
                )}) ${biz.owner_phone.substring(
                  3,
                  6
                )}-${biz.owner_phone.substring(6)}`
              : null}
          </p>
        </div>
        <div style={styles.row}>
          <p className={"font-primary"} style={styles.label}>
            location
          </p>
          <p className={"font-primary"} style={styles.value}>
            {biz.location}
          </p>
        </div>
        <div style={styles.row}>
          <p className={"font-primary"} style={styles.label}>
            owner
          </p>
          <p className={"font-primary"} style={styles.value}>
            {biz.owner_first_name} {biz.owner_last_name}
          </p>
        </div>
        <div style={styles.row}>
          <p className={"font-primary"} style={styles.label}>
            Ethnicity
          </p>
          <p className={"font-primary"} style={styles.value}>
            {biz.owner_ethn}
          </p>
        </div>
        <div style={styles.row}>
          <p className={"font-primary"} style={styles.label}>
            owner_email
          </p>
          <p className={"font-primary"} style={styles.value}>
            {biz.owner_email}
          </p>
        </div>
        <div style={styles.row}>
          <p className={"font-primary"} style={styles.label}>
            Website
          </p>
          <p className={"font-primary"} style={styles.value}>
            {biz.business_web}
          </p>
        </div>
        <div style={styles.row}>
          <p className={"font-primary"} style={styles.label}>
            LinkedIn
          </p>
          <p className={"font-primary"} style={styles.value}>
            {biz.owner_linkedin}
          </p>
        </div>
        <div style={styles.row}>
          <p className={"font-primary"} style={styles.label}>
            twitter
          </p>
          <p className={"font-primary"} style={styles.value}>
            {biz.twitter}
          </p>
        </div>
        <div style={styles.row}>
          <p className={"font-primary"} style={styles.label}>
            facebook
          </p>
          <p className={"font-primary"} style={styles.value}>
            {biz.facebook}
          </p>
        </div>
        <div style={styles.row}>
          <p className={"font-primary"} style={styles.label}>
            Instagram
          </p>
          <p className={"font-primary"} style={styles.value}>
            {biz.instagram}
          </p>
        </div>
      </div>
    );
  };

  render() {
    const { biz } = this.props;
    return (
      <BorderCard
        style={{
          margin: "0.5rem auto",
          padding: "1rem",
          width: "300px",
          border: biz.emailValidated ? "1px solid #171717" : "1px solid red",
        }}
      >
        <h3>{biz.name}</h3>
        <p>{`${biz.city}, ${biz.state} ${biz.zipcode}`}</p>
        <p>{biz.owner_ethn}</p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <Button
            style={{
              flex: 1,
              background: "forestgreen",
              color: "#f1f1f1",
              borderWidth: 0,
            }}
            disabled={biz.approved.status}
            onClick={this.handleApprove}
          >
            {biz.approved.active ? "Approved" : "Approve"}
          </Button>
          <Button
            style={{
              flex: 1,
              backgroundColor: "gold",
              borderWidth: 0,
            }}
            onClick={this.handleFlag}
          >
            Flag/Escalate
          </Button>
          <Button
            style={{
              flex: 1,
              backgroundColor: "firebrick",
              color: "#f1f1f1",
              borderWidth: 0,
            }}
            onClick={this.handleDeny}
          >
            Deny
          </Button>
          <Button
            style={{
              flex: 1,
              backgroundColor: "#4286f7",
              color: "#f1f1f1",
              borderWidth: 0,
            }}
            onClick={this.toggleDropdown}
          >
            Details{" "}
            {this.state.dropdown ? (
              <FontAwesomeIcon
                icon={["fas", "chevron-up"]}
                style={{ fontSize: 16 }}
                color="#f1f1f1"
              />
            ) : (
              <FontAwesomeIcon
                icon={["fas", "chevron-down"]}
                style={{ fontSize: 16 }}
                color="#f1f1f1"
              />
            )}
          </Button>
        </div>
        {this.state.dropdown ? this.renderDropdown() : null}
      </BorderCard>
    );
  }
}

const styles = {
  value: {
    flex: 1,
    fontSize: "0.9rem",
    textAlign: "right",
    // lineBreak: "anywhere",
  },
  label: {
    fontSize: "0.9rem",
    fontWeight: "bold",
    marginRight: "1rem",
  },
  row: { flexDirection: "row", display: "flex", flexWrap: "wrap" },
};

export default withRouter(AdminBizMini);
